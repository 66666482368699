import { AnimatedHeight } from '@/atoms-react/animated-height/AnimatedHeight.react'
import { Button } from '@/atoms-react/button/Button.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import RadioButton from '@/atoms-react/radio-button/index.react'
import { Info } from '@/atoms-react/text/Text.react'
import useCheckEmailExistsMutation from '@/features/flow/hooks/use-check-email-exists'
import { useUser } from '@/features/user/hooks/use-user'
import { FAVOURITE_COUNTRY_CODES } from '@/features/utils/constants'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export type FormRCSData = {
  number: string | null
  email: string | null
  registrationCountry: number | null
}

export type FormRCSProps = {
  data: FormRCSData
  onChange?: (data: FormRCSData) => void
  onValidated?: (data: FormRCSData) => void
}

export const FormRCS = ({ data, onChange = () => {}, onValidated = () => {} }: FormRCSProps) => {
  const { t } = useTranslation(['pass'])
  const { mutateAsync: checkEmailExists } = useCheckEmailExistsMutation()

  const { locale } = useLocale()
  const { useCountries, useUserProfile } = useUser()
  const { data: profile } = useUserProfile()
  const { data: countries } = useCountries(locale?.language)

  const [hasRCS, setHasRCS] = useState<boolean>(true)

  return (
    <div className="w-full max-w-lg">
      <AnimatedHeight>
        <div className="mb-8">
          <Dropdown
            className={'mb-6 w-full'}
            value={data?.registrationCountry ? data.registrationCountry.toString() : null}
            autoComplete="country"
            autoFocus
            label={t('business.input.rcs_country')}
            placeholder={t('business.input.rcs_country')}
            groups={[{ key: 'default' }]}
            items={(countries?.countries ?? []).map((country) => ({
              key: country.id.toString(),
              value: country.defaultName,
              groupKey: FAVOURITE_COUNTRY_CODES.includes(country.alpha2.toLowerCase())
                ? 'default'
                : undefined
            }))}
            search={true}
            onChange={({ key }) => onChange({ ...data, registrationCountry: parseInt(key) })}
          />

          <div className={'border-b border-slate-100 w-full h-0 mb-6'}></div>

          <RadioButton
            className={'mb-6 w-full'}
            value={hasRCS === true ? 'true' : 'false'}
            onChange={(output) => {
              setHasRCS(output === 'true' ? true : false)
              if (output === 'false') onChange({ ...data, number: null })
            }}
            label={t('business.input.has_rcs')}
            listOfChoices={[
              { id: 'true', title: t('olkypass.general.yes') },
              { id: 'false', title: t('olkypass.general.no') }
            ]}
            choicesInRow
          />

          {hasRCS && (
            <>
              <div className="mt-6" />
              <InputLabel
                className={'w-full'}
                placeholder={t('business.input.company_rcs_number')}
                value={data.number ?? ''}
                onChange={(value: string) => onChange({ ...data, number: value })}
                label={t('business.input.company_rcs_label')}
              />
            </>
          )}
          <div className="mt-2" />
          <Info>{t('business.new.info.rcs_info')}</Info>
        </div>

        <div className="shrink-0 pb-6 md:pb-0 flex flex-col items-center w-full">
          <DotsNavigation total={2} current={0} className={'mb-4'} />
          <Button
            disabled={(hasRCS && !data.registrationCountry) || (hasRCS && !data.number)}
            className={'md:w-1/2'}
            onClick={async () => {
              if (!data.registrationCountry) {
                toast.error(t('business.new.error.rcs_registration_unfilled'))
                return
              }

              if (hasRCS && !data.number) {
                toast.error(t('business.new.error.rcs_unfilled'))
                return
              }

              const safeRCS = (data.number ?? '').replace(/ /gm, '')

              const userEmail = profile?.naturalPerson?.AliasEmail[0]?.Mail ?? 'no-email@olky.eu'
              data.email = `${userEmail.split('@')[0]}+${safeRCS}+${data.registrationCountry}+${Date.now()}@${userEmail.split('@')[1]}`
              onChange(data)

              const emailExists = await checkEmailExists(data.email ?? '')
              if (emailExists.existStatus === true) {
                toast.error(t('business.new.error.email_exists'))
                return
              }

              onValidated(data)
            }}
            shortcut={'enter'}
          >
            {t('business.new.button.continue')}
          </Button>
          <br />
        </div>
      </AnimatedHeight>
    </div>
  )
}
