<template>
  <v-col cols="12" sm="12" md="6" lg="4">
    <v-card class="mx-auto elementCard">
      <template #prepend>
        <v-icon color="white">mdi-home-map-marker</v-icon>
      </template>
      <template #title>
        {{ address.Tag }}
      </template>
      <v-card-text class="elementData">
        <p v-if="address.Address1">{{ address.Address1 }}</p>
        <p v-if="address.Address2">{{ address.Address2 }}</p>
        <p v-if="address.Address3">{{ address.Address3 }}</p>
        <p v-if="address.ZipCode && address.City">{{ address.ZipCode }} {{ address.City }}</p>
        <p v-if="countryName">{{ countryName }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          density="comfortable"
          size="small"
          color="surface-variant"
          variant="tonal"
          icon="mdi-home-edit"
          @click="updateCurrent"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <AddressFormDialog ref="addressFormDialog"></AddressFormDialog>
  </v-col>
</template>

<script>
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import AddressFormDialog from '../FormsDialog/AddressFormDialog.vue'

export default {
  name: 'AddressChild',
  components: {
    AddressFormDialog
  },
  mixins: [ToolsMixins],
  props: ['address', 'countries', 'countryKey', 'index', 'inMemoryAddress'],
  methods: {
    updateCurrent() {
      this.$refs.addressFormDialog.open(this.address)
    },
    getCountryName(address, jsonArray) {
      const key = address.Country

      const findCountryFiltred = jsonArray.find(function (element) {
        return element.id === parseInt(key)
      })
      
      return findCountryFiltred ? findCountryFiltred.name : null
    }
  },
  computed: {
    ...mapGetters(['naturalPerson']),
    countryName() {
      return this.getCountryName(this.address, this.countries)
    }
  }
}
</script>

<style></style>
