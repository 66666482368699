import Column from '@/atoms-react/column/Column.react'
import InputPhone, { PhoneNumber } from '@/atoms-react/input/InputPhone.react'

import { FRENCH_CODE, FRENCH_CODE_SHORT } from '@/features/utils/constants'
import _ from 'lodash'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type AddMobileFormProps = {
  onChange: (mobile: PhoneNumber, valid: boolean) => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>

export const FormAddMobile = ({
  onChange: _onChange = () => { },
  ...props
}: AddMobileFormProps) => {
  const [phone, setPhone] = useState<PhoneNumber>({
    phone: '',
    valid: false,
    international: '',
    countryCode: FRENCH_CODE,
    countryPrefix: '+33'
  })

  const onChange = (phone: PhoneNumber) => {
    _onChange(phone, phone?.valid)
  }

  return (
    <Column
      className={twMerge('w-full', props.className)}
      {..._.omit(props, 'onChange', 'className')}
    >
      <InputPhone
        autoFocus
        value={phone}
        autoComplete="tel"
        onChange={(value) => {
          setPhone(value)
          onChange(value)
        }}
        initialCountry={FRENCH_CODE_SHORT}
      />
    </Column>
  )
}