import _ from 'lodash'
import React, { useEffect } from 'react'

import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useDebounceCallback } from 'usehooks-ts'
import { defaultInputClassName } from './Input.react'

interface CodeInputProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
  label?: string
  inputComponent?: React.ReactNode
  inputClassName?: string
  className?: string
  codeNumberOfCharacters?: number
  onChange?: (code: string) => void
  focus?: boolean
  loading?: boolean
}

export default function CodeInput(props: CodeInputProps) {
  const [inputContent, setInputContent] = useState<string[]>(
    Array.from({ length: props.codeNumberOfCharacters || 1 }, () => '')
  )

  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const debouncedOnChange = useDebounceCallback((code: string) => {
    props?.onChange && props?.onChange(code)
  }, 100)

  // Calculate output value
  const calculateRealValue = (inputContent: string[]) => {
    return inputContent.join('')
  }

  useEffect(() => {
    if (props.focus && inputRefs.current) {
      inputRefs.current[0]?.focus()
    }
  }, [focus])

  const handleInputChange = (index: number, value: string) => {
    setInputContent((inputContent) => {
      const newInputContent = [...inputContent]
      newInputContent[index] = value

      // Send callback to atom user
      if (
        debouncedOnChange &&
        calculateRealValue(newInputContent).length === (props?.codeNumberOfCharacters || 1)
      ) {
        const realValue = calculateRealValue(newInputContent)
        debouncedOnChange(realValue)
      }

      return newInputContent
    })

    if (value !== '' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && inputContent[index] === '' && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }

    if (event.key === 'ArrowLeft' && index > 0) {
      event.preventDefault()
      const input = inputRefs.current[index - 1]

      // Set cursor at the end of input
      if (input) {
        input.focus()
        input.setSelectionRange(input.value.length, input.value.length)
      }
    }

    if (event.key === 'ArrowRight' && index < inputContent.length) {
      event.preventDefault()
      const input = inputRefs.current[index + 1]

      // Set cursor at the end of input
      if (input) {
        input.focus()
        input.setSelectionRange(input.value.length, input.value.length)
      }
    }

    // Handle Ctr + V
    if ((event.ctrlKey || event.metaKey) && event.code === 'KeyV') {
      event.preventDefault()

      // Read text from clipboard
      navigator.clipboard.readText().then((clipText) => {
        clipText
          .split('')
          .filter((char, index) => index < inputContent.length)
          .filter((char, index) => index < inputContent.length)
          .forEach((char, index) => {
            handleInputChange(index, char)
            inputRefs?.current[inputRefs.current.length - 1]?.focus()
          })
      })
    }
  }
  return (
    <>
      <div
        className={twMerge(
          'flex space-x-0 lg:space-x-2 items-center' + ' ' + props.className,
          props.loading ? 'opacity-50' : ''
        )}
      >
        {props.codeNumberOfCharacters &&
          inputContent.map((value, index) => (
            <input
              key={index}
              type="number"
              pattern="\d*"
              value={value}
              required
              maxLength={1}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(index, event.target.value)
              }
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown(index, event)
              }
              placeholder="•"
              className={twMerge(
                'text-center w-10 max-w-11 ',
                defaultInputClassName,
                props.inputClassName,
                'lg:rounded-sm rounded-none first:rounded-l-sm last:rounded-r-sm',
                'px-0'
              )}
              ref={(ref: HTMLInputElement | null) => {
                inputRefs.current[index] = ref
              }}
              {..._.omit(
                props,
                'label',
                'inputClassName',
                'className',
                'codeNumberOfCharacters',
                'onChange',
                'loading',
                'focus'
              )}
            />
          ))}
      </div>
    </>
  )
}
