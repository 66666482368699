import { Button } from '@/atoms-react/button/Button.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { useUser } from '@/features/user/hooks/use-user'
import { AddressCreate, AddressFullType } from '@/features/user/types'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { FormAddAddress } from '@/molecules/form-add-address/index.react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type ModalAddAddressProps = {
  address?: AddressFullType | null
  onValidated?: (address: AddressCreate) => Promise<void>
} & ModalProps

const getDefaultAddressData = () => {
  return {
    address: '',
    zip: '',
    country: null,
    city: '',
    tag: ''
  }
}

export const ModalAddAddress = ({
  address,
  onValidated = async () => { },
  ...props
}: ModalAddAddressProps) => {
  const { t } = useTranslation(['atoms'], { useSuspense: false })
  const { useUserProfile } = useUser()
  const { data: user, isLoading: isLoadingUser } = useUserProfile()
  const { locale } = useLocale()
  const isFrench = locale.language === 'FR'

  const [createdAddress, setCreatedAddress] = useState<AddressCreate>(getDefaultAddressData())

  useEffect(() => {
    if (!address) {
      setCreatedAddress(getDefaultAddressData())
    } else {
      setCreatedAddress({
        address: address.Address1,
        zip: address.ZipCode,
        country: parseInt(address.Country),
        city: address.City,
        tag: address.Tag || ""
      })
    }
  }, [address])

  useEffect(() => {
    if (isLoadingUser) return

    setCreatedAddress((data) => ({
      ...data,
      UUID: user?.naturalPerson?.UUID,
      language: isFrench ? 'fr' : 'en'
    }))
  }, [user])

  const [addressValid, setAddressValid] = useState(false)

  const isValid = (): boolean => {
    return (
      !!createdAddress.address &&
      !!createdAddress.country &&
      !!createdAddress.city &&
      !!createdAddress.tag &&
      addressValid
    )
  }

  return (
    <Modal {...props} maxWidthClassName='max-w-2xl'>
      <ModalContent
        title={
          address
            ? t('molecules.modal_add_address.title_modify')
            : t('molecules.modal_add_address.title')
        }
      >
        <div className={'mt-4 opacity-1 items-end flex flex-col'}>
          <div className={'w-full mt-4 mb-6'}>
            <FormAddAddress
              autoFocus
              address={createdAddress}
              onChange={(address, valid) => {
                setAddressValid(valid)
                setCreatedAddress(address)
              }}
            />
          </div>

          <Button
            disabled={!isValid()}
            shortcut="enter"
            className="shrink-0 w-max"
            onClick={async () => {
              await onValidated(createdAddress)
              setCreatedAddress(getDefaultAddressData())
            }}
            icon={ChevronRightIcon}
          >
            {t('molecules.modal_add_address.confirm')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
