import { fetchServer } from '@/features/utils/fetch'
import { ChangePasswordResponse, SendEmailCodeResponse, TryConnectionResponse } from '../types'

export class SecurityApiClient {
  static async is2faActive() {
    const res = await fetchServer('/two_factory/check_activation')
    return (await res.json()).data.isTwoFaActivated as boolean
  }

  static async setup2fa() {
    const res = await fetchServer('/two_factory/setup')
    return (await res.json()).data as {
      qrContent: string
      secret: string
    }
  }

  static async activate2fa(otp: string, secret: string) {
    const res = await fetchServer('/two_factory/activate', {
      body: {
        otp,
        secret
      }
    })
    return res.status === 200
  }

  static async updatePassword(
    password: string
  ) {
    const response = await fetchServer('/user/settings/update_password', {
      body: {
        password
      }
    })
    const success = response.status === 200 ? true : false
    if (!success) {
      const res = await response.json()
      return { success, errorCode: res.code } as ChangePasswordResponse
    } else {
      return { success } as ChangePasswordResponse
    }
  }

  static async sendEmailCode(email: string, language: string) {
    const response = await fetchServer('/user/settings/pre_update_pwd/send_email_code', {
      method: 'POST',
      reloadOn401: false,
      body: {
        email,
        language
      }
    })
    const res = await response.json()
    return res as SendEmailCodeResponse
  }

  /**
   *
   * @param encryptedData
   * @param code
   */
  static async checkEmailCode(encryptedData: string, code: string) {
    const response = await fetchServer('/user/settings/pre_update_pwd/check_email_code', {
      method: 'POST',
      reloadOn401: false,
      body: {
        encryptedData,
        code
      }
    })
    const res = await response.json()
    console.log('checkEmailCode', res)
    try {
      return res.data as { success: boolean; data: ''; message: '' }
    } catch (error) {
      return res.data as { success: false; data: ''; message: string }
    }
  }

  static async tryConnectViaOTP(
    email: string,
    password: string,
    code = ''
  ) {
    const response = await fetchServer('/user/settings/pre_update_pwd/check_otp_code', {
      body: {
        email,
        password,
        code
      }
    })
    const success = response.status === 200 ? true : false
    if (!success) {
      const res = await response.json()
      return { success, data: res.data } as TryConnectionResponse
    } else {
      return { success } as TryConnectionResponse
    }
  }

  static async reset2Fa() {
    const response = await fetchServer('/two_factory/reset')
    const res = await response.json()
    return res.data
  }


}
