import { SecurityCheck } from '@/app/olkypass/views/account/security/components/security-check'
import {
  NewWebauthnDevice,
  UserDevices
} from '@/app/olkypass/views/account/security/components/user-devices'
import { Button } from '@/atoms/buttons/Button'
import { Option, Select } from '@/atoms/inputs/Select'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import { Link } from '@/atoms/link/Link2'
import { Loader } from '@/atoms/loader/Loader'
import _ModalVue from '@/atoms/modals/Modal.vue'
import _ModalContentVue from '@/atoms/modals/ModalContent.vue'
import { Notice } from '@/atoms/notice'
import { Info, SectionTitle, SectionTitleSmall, Subtitle } from '@/atoms/text/Text.tsx'
import { Section } from '@/components/layout/Section'
import { Routes } from '@/features/router'
import { useSecurity } from '@/features/security/hooks/use-security'
import { getLanguage, setLanguage } from '@/features/utils/i18n'
import { defineJsxComponent } from '@/jsx-utils'
import { twMerge } from 'tailwind-merge'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Security2faConfig } from './components/2fa-config'
import { ChangePasswordModal } from '../../identity/components/security/change-password-modal.react'
import { SolidAuthnModal } from '../../identity/components/security/solid-authn-modal.react'
import { applyPureReactInVue } from 'veaury'
import { useToast } from 'vue-toast-notification'

const SolidAuthnModalReact = applyPureReactInVue(SolidAuthnModal)
const ChangePasswordModalReact = applyPureReactInVue(ChangePasswordModal)
const ModalContentVue = _ModalContentVue as any
const ModalVue = _ModalVue as any

export const SecurityView = defineJsxComponent((_) => {
  const t = useI18n().t
  const toast = useToast()

  const { store, refresh, reset2Fa } = useSecurity()
  const userLanguage = ref(getLanguage())
  const passwordModal = ref(false)
  const resetOtpAction = ref(false)
  const updatePasswordAction = ref(false)
  const addNewWebAuthnDeviceAction = ref(false)
  const loaderResetOtp = ref(false)
  const otpModal = ref(false)
  const keyModal = ref(false)

  return () => (
    <div class="grow w-full flex flex-col h-full">
      <Subtitle class="mb-6">{t('olkypass.securityPage.subtitle')}</Subtitle>

      {resetOtpAction.value && (
        <SolidAuthnModalReact 
          onClose={() => resetOtpAction.value = false} 
          onValidated={async () => {
            resetOtpAction.value = false; 
            loaderResetOtp.value = true; 
            await reset2Fa(); 
            toast.success(
              t('olkypass.securityPage.passwordSection.2fa.modal.notifications.otp.reset_success')
            )
            loaderResetOtp.value = false; 
          }} 
        />
      )}

      {updatePasswordAction.value && (
        <SolidAuthnModalReact 
          onClose={() => updatePasswordAction.value = false} 
          onValidated={async () => {
            updatePasswordAction.value = false; 
            passwordModal.value = true
          }} 
        />
      )}
      {passwordModal.value && (
        <ChangePasswordModalReact 
          onClose={() => passwordModal.value = false} 
          onValidated={() => {
            passwordModal.value = false
            toast.success(
              t('olkypass.forgetPasswordPage.notifications.success.reset')
            )
          }} />
      )}
      
      {addNewWebAuthnDeviceAction.value && (
        <SolidAuthnModalReact 
          onClose={() => addNewWebAuthnDeviceAction.value = false} 
          onValidated={async () => {
            addNewWebAuthnDeviceAction.value = false
            keyModal.value = true
          }}
        />
      )}
      <ModalVue open={otpModal.value} onClose={() => (otpModal.value = false)}>
        <ModalContentVue
          title={t('olkypass.securityPage.passwordSection.2fa.modal.header_card.authentication')}
        >
          <Security2faConfig onComplete={() => (otpModal.value = false)} />
        </ModalContentVue>
      </ModalVue>
      <ModalVue open={keyModal.value} onClose={() => (keyModal.value = false)}>
        <ModalContentVue
          title={t('olkypass.securityPage.webauthnSection.devices.table.actions.add')}
        >
          <NewWebauthnDevice
            onComplete={async () => {
              await refresh()
              keyModal.value = false
              toast.success(
                t('olkypass.securityPage.webauthnSection.devices.notifications.add.success')
              )
            }}
          />
        </ModalContentVue>
      </ModalVue>

      {!store.initialized && <Loader center />}
      {store.initialized && (
        <>
          <Section class="mb-4 border-blue-400 border border-solid">
            <SectionTitle>
              {t('olkypass.securityPage.webauthnSection.header_card.recommended')}
            </SectionTitle>
            <Subtitle class="block mt-3">
              {t('olkypass.securityPage.webauthnSection.body_card.message_details')}
            </Subtitle>

            {store.isWebAuthnActive && (
              <div>
                <Notice key="success" type="success" class="mt-4">
                  {t('olkypass.securityPage.webauthnSection.body_card.is_activated')}
                </Notice>
              </div>
            )}
            {!store.isWebAuthnActive && (
              <Button class="mt-4" theme="primary" onClick={() => (keyModal.value = true)}>
                {t('olkypass.securityPage.webauthnSection.button.use_key')}
              </Button>
            )}

            {store.webAuthnUserDevices.length > 0 && <UserDevices />}
          </Section>

          <Section class="mb-4">
            <SectionTitle>{t('olkypass.securityPage.passwordSection.title')}</SectionTitle>
            <Subtitle class="block mt-3">
              {t('olkypass.securityPage.passwordSection.subtitle')}
            </Subtitle>

            <SectionTitleSmall class="mt-6">
              {t('olkypass.securityPage.passwordSection.password.title')}
            </SectionTitleSmall>
            <Info class="block mt-2">
              {t('olkypass.securityPage.passwordSection.password.description')}
            </Info>
            {/* <Button
              theme="default"
              class="mt-4"
              onClick={() => {
                updatePasswordAction.value = true
              }}
            >
              {t('olkypass.securityPage.passwordSection.password.button')}
            </Button> */}
            <div>
              <SectionTitleSmall class="mt-6">
                {t('olkypass.securityPage.passwordSection.2fa.title')}
              </SectionTitleSmall>
              {store.is2faActive && !loaderResetOtp.value &&  (
                <Notice key="success" type="success" class="mt-4">
                    <div class="h-7 flex items-center">
                        <span class="h-min">
                            {t('olkypass.securityPage.passwordSection.2fa.notice_success')}
                        </span>
                    </div>
                    {/* <Button theme="default" class="mt-2" onClick={() => (resetOtpAction.value = true)}>
                        {t('olkypass.securityPage.passwordSection.2fa.reset')}
                    </Button> */}
                </Notice>
              )}
              {loaderResetOtp.value && <Loader />}
              {!store.is2faActive && !loaderResetOtp.value && (
                <Notice key="error" type="error" class="mt-4">
                  <div class="h-7 flex items-center">
                    <span class="h-min">
                      {t('olkypass.securityPage.passwordSection.2fa.notice_error')}
                    </span>
                  </div>
                  <Button theme="danger" class="mt-2" onClick={() => (otpModal.value = true)}>
                    {t('olkypass.securityPage.passwordSection.2fa.button')}
                  </Button>
                </Notice>
              )}
            </div>
          </Section>

          <Section class="mb-4">
            <SectionTitle>{t('olkypass.securityPage.settingsSection.title')}</SectionTitle>
            <LabelDecoration
              class="my-4"
              label={t('olkypass.securityPage.settingsSection.language_label')}
            >
              {{
                input: (input: any) => (
                  <Select
                    class={twMerge(input.class, 'w-max')}
                    size="lg"
                    required
                    v-model={userLanguage.value}
                    onChange={(e: any) => {
                      userLanguage.value = e.target.value
                      setLanguage(e.target.value)
                    }}
                  >
                    {[
                      { value: 'fr', label: 'Français' },
                      { value: 'en', label: 'English' }
                    ].map((opt) => (
                      <Option value={opt.value} key={opt.value}>
                        {opt.label}
                      </Option>
                    ))}
                  </Select>
                )
              }}
            </LabelDecoration>
          </Section>

          <Notice type="primary" class="mb-4">
            {/*<div class="h-7 flex items-center">*/}
            {/*  <span class="h-min">{t('olkypass.securityPage.notice_title')}</span>*/}
            {/*</div>*/}
            {t('olkypass.securityPage.notice')}{' '}
            <Link target="_blank" href={Routes.support} class="font-bold">
              {t('olkypass.securityPage.notice_link')}
            </Link>
          </Notice>
        </>
      )}

      <br />
    </div>
  )
})
