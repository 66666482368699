import Column from '@/atoms-react/column/Column.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Skeletton } from '@/atoms-react/skeletton/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import Flag from '@/atoms/flag/Flag.react'
import { useUser } from '@/features/user/hooks/use-user'
import { AddressCreate } from '@/features/user/types'
import { FAVOURITE_COUNTRY_CODES } from '@/features/utils/constants'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { FormFieldGroup, getRealFieldGroup } from '@/features/utils/types'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

export type AddAddressFormProps = {
  address: AddressCreate
  fields?: FormFieldGroup<AddressCreate>
  countryFirst?: boolean
  onChange: (address: AddressCreate, valid: boolean) => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>

export const FormAddAddress = ({
  address,
  fields = {},
  onChange: _onChange = () => {},
  ...props
}: AddAddressFormProps) => {
  const { t } = useTranslation(['atoms'])

  const { locale } = useLocale()
  const { useCountries } = useUser()
  const { data: countries, isLoading: isLoadingCountries } = useCountries(locale.language)

  const realFields = getRealFieldGroup(Object.keys(address), fields)

  const updateSpecificRule = (address: AddressCreate) => {
    if (realFields.zip) {
      realFields.zip.required = countries?.countries.some(
        (a) => a.id === address.country && a.alpha2 === 'FR'
      )
    }
  }

  const checkValidity = (address: AddressCreate): boolean => {
    updateSpecificRule(address)
    const valid = !Object.keys(realFields).some((key) => {
      const field = realFields[key as keyof AddressCreate]
      return field?.visible && field?.required && !address[key as keyof AddressCreate]
    })
    return valid
  }

  updateSpecificRule(address)

  const onChange = (address: AddressCreate) => {
    const valid = checkValidity(address)
    _onChange(address, valid)
  }

  if (isLoadingCountries || !countries) return <Skeletton />

  return (
    <Column
      className={twMerge('w-full', props.className)}
      {..._.omit(props, 'onChange', 'className')}
    >
      {(realFields?.tag ? !!realFields.tag.visible : true) && (
        <InputLabel
          autoFocus={!realFields.tag?.disabled && props.autoFocus}
          value={address.tag}
          onChange={(value) => onChange({ ...address, tag: value })}
          label={realFields?.tag?.label ?? t('molecules.add_address_form.tag_label')}
          placeholder={realFields?.tag?.placeholder ?? t('molecules.add_address_form.tag_label')}
          required={realFields.tag?.required}
          disabled={realFields.tag?.disabled}
          className={'mb-3'}
        />
      )}
      {(realFields?.address ? !!realFields?.address.visible : true) && (
        <InputLabel
          autoFocus={!realFields.tag?.disabled && props.autoFocus}
          autoComplete="address-line1"
          value={address.address}
          onChange={(value) => onChange({ ...address, address: value })}
          label={realFields?.address?.label ?? t('molecules.add_address_form.address_line_label')}
          placeholder={
            realFields?.address?.placeholder ?? t('molecules.add_address_form.address_line_label')
          }
          required={realFields.address?.required}
          disabled={realFields.address?.disabled}
        />
      )}
      {(realFields?.country ? !!realFields?.country.visible : true) && !!props.countryFirst && (
        <CountryInput address={address} fields={fields} onChange={onChange} />
      )}
      <div className={'flex mt-6'}>
        {(realFields?.city ? !!realFields?.city.visible : true) && (
          <InputLabel
            value={address.city}
            autoComplete="address-level2"
            className={'grow mr-4'}
            onChange={(value) => onChange({ ...address, city: value })}
            label={realFields?.city?.label ?? t('molecules.add_address_form.city_line_label')}
            placeholder={
              realFields?.city?.placeholder ?? t('molecules.add_address_form.city_line_label')
            }
            required={realFields.city?.required}
            disabled={realFields.city?.disabled}
          />
        )}
        {(realFields?.zip ? !!realFields?.zip.visible : true) && (
          <InputLabel
            value={address.zip}
            autoComplete="postal-code"
            className={'grow'}
            onChange={(value) => onChange({ ...address, zip: value })}
            label={realFields?.zip?.label ?? t('molecules.add_address_form.zip_code_label')}
            placeholder={
              realFields?.zip?.placeholder ?? t('molecules.add_address_form.zip_code_label')
            }
            maxLength={5}
            required={realFields.zip.required}
            disabled={realFields.zip?.disabled}
          />
        )}
      </div>
      {(realFields?.country ? !!realFields?.country.visible : true) && !props.countryFirst && (
        <CountryInput address={address} fields={fields} onChange={onChange} />
      )}
    </Column>
  )
}

export const CountryInput = ({
  address,
  fields = {},
  onChange = () => {}
}: Pick<AddAddressFormProps, 'address' | 'fields' | 'onChange'>) => {
  const { t } = useTranslation(['atoms'])

  const { locale } = useLocale()
  const { useCountries } = useUser()
  const { data: countries } = useCountries(locale.language)
  const realFields = getRealFieldGroup(Object.keys(address), fields)

  return (
    <Dropdown
      label={realFields?.country?.label ?? t('molecules.add_address_form.country_label')}
      placeholder={
        realFields?.country?.placeholder ?? t('molecules.add_address_form.country_placeholder')
      }
      autoComplete="country"
      handleAutoComplete={(autoComplete, items, change) => {
        const country = (countries?.countries ?? []).find(
          (country) => country.alpha2.toLowerCase() === autoComplete.toLowerCase()
        )
        const autocompletedValue =
          items.find((item) => item?.key === country?.id.toString()) ?? null

        if (autocompletedValue) change(autocompletedValue)
      }}
      onChange={(value) => onChange({ ...address, country: parseInt(value.key) }, true)}
      value={address.country?.toString()}
      className={'mt-6'}
      //value={address.country?.toString()}
      groups={[{ key: 'default' }]}
      items={
        (countries?.countries ?? []).map((country) => ({
          key: country.id.toString(),
          value: country.defaultName,
          icon: <Flag countryCode={country.alpha2} />,
          render: ({ key }) => {
            return (
              <div className={'w-full flex'} key={key}>
                <Flag countryCode={country.alpha2} className="!w-6 !h-6 rounded-sm" />
                <Base className={'ml-2'}>{country.defaultName}</Base>
              </div>
            )
          },
          groupKey: FAVOURITE_COUNTRY_CODES.includes(country.alpha2.toLowerCase())
            ? 'default'
            : undefined
        })) ?? []
      }
      search={true}
      required={realFields.country?.required}
      disabled={realFields.country?.disabled}
    />
  )
}
