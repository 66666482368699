import config from '@/config'
import { WebAuthnApi } from '@/features/auth/api-client/webauthn-api-client'
import { Routes } from '@/features/router'
import { useUser } from '@/features/user/hooks/use-user-vue'
import { createLoaderStore } from '@/features/utils/loader'
import { StorageService } from '@/features/utils/storage'
import { useI18n } from 'vue-i18n'
import { useReCaptcha, type IReCaptchaComposition } from 'vue-recaptcha-v3'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import { AuthApiClient } from '../api-client/api-client'
import type { ResetPasswordRequest } from '../types'
import { setJwt } from '../utils'
import { useAuth } from './use-auth-vue'

const Loader = createLoaderStore('user')
let captcha_token: any
let language: any

export const useLogin = () => {
  const { user } = useUser()
  const { initialLoading, profile, logout } = useAuth()
  const toast = useToast()

  const { locale, t } = useI18n()
  const loader = Loader()
  const router = useRouter()
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() as IReCaptchaComposition

  // Verify 2 fa Identity
  const verifyIdentityTwoFa = async (
    two_fa_required: boolean,
    email?: string,
    password?: string,
    challenge?: string
  ) => {
    if (two_fa_required) {
      const path = router.currentRoute.value.fullPath
      await router.push(path.includes(Routes.two_fa) ? path : Routes.two_fa)
    } else if (email && password) {
      loader.set(true)
      try {
        const { token } = await AuthApiClient.login(email, password, challenge!)
        if (token) {
          setJwt({ token, refresh: token })
          if (config.OLKYPASS_MODE) {
            await profile()
          } else {
            await router.push('/pay/suppliers')
          }
          loader.set(false)
          return true
        } else {
          logout()
        }
      } catch (e) {
        logout(false)
      }
      loader.set(false)
      return false
    }
  }

  // Send forget password link
  const sendForgetPasswordLink = async (email: string) => {
    loader.set(true)
    try {
      await recaptchaLoaded()
      captcha_token = await executeRecaptcha('login')
      language = locale.value
      const { message } = await AuthApiClient.forgetPasswordLink(email, captcha_token, language)
      if (!message) {
        await router.push(Routes.login)
        loader.set(false)
        return true
      }
    } catch (e: any) {
      toast.error(e.message)
    }
    loader.set(false)
    return false
  }

  // Reset password
  const resetPassword = async (credentials: ResetPasswordRequest) => {
    loader.set(true)
    await recaptchaLoaded()
    credentials.captcha_token = await executeRecaptcha('login')
    const { message } = await AuthApiClient.resetPassword(credentials)
    if (!message) {
      await router.push(Routes.login)
      loader.set(false)
      return true
    } else {
      await router.push(Routes.forget_password)
    }
    loader.set(false)
    return false
  }

  // Login the user using email + password
  const login = async (email: string, password: string, securityAccess?: boolean) => {
    loader.set(true)
    try {
      const { two_fa_required, token } = await AuthApiClient.checkLogin(email, password)
      StorageService.setItem('username', email)
      console.log("Set username when login with email+password")
      if (token) {
        // i need to store username
        setJwt({ token, refresh: token })
        if (config.OLKYPASS_MODE) {
          if (securityAccess) {
            await profile()
          } else {
            await profile()
          }
        } else {
          await router.push('/pay/suppliers')
        }
        loader.set(false)
        return true
      } else if (two_fa_required) {
        user.setCredential({ username: email, password })
        await verifyIdentityTwoFa(true)
        loader.set(false)
        return true
      } else {
        toast.error(t('login.loginPage.errors.invalidCredentials'))
        logout()
      }
    } catch (e) {
      logout(false)
    }
    loader.set(false)
    return false
  }

  // Login the user using email + password
  const webAuthnLogin = async (email: string) => {
    loader.set(true)
    try {
      const { existStatus } = await AuthApiClient.checkExistingEmail(email)
      if (existStatus) {
        StorageService.setItem('username', email)
        console.log("Set username when login with webAuthn")
        // check credentials id
        const token = await WebAuthnApi.authenticateWebAuthnUser(email)
        if (token) {
          setJwt({ token, refresh: token })
          if (config.OLKYPASS_MODE) {
            await profile()
          } else {
            await router.push('/pay/suppliers')
          }
          loader.set(false)
          return true
        } else {
          logout()
        }
      } else {
        // toast.error(t('login.loginPage.errors.inexistingMail'))
        logout()
      }
    } catch (e) {
      logout(false)
    }
    loader.set(false)
    return false
  }

  return {
    loader,
    login,
    verifyIdentityTwoFa,
    sendForgetPasswordLink,
    resetPassword,
    webAuthnLogin,
    profile,
    logout,
    initialLoading,
    user
  }
}
