import { Subtitle, Title } from '@/atoms/text/Text.tsx'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'
import { FooterView } from './components/FooterView'
import { FormView } from './components/FormView'

export const SignupView = defineVueComponent(() => {
  const { t } = useI18n()
  return () => (
    <div class="text-center">
      <Title>{t('login.registerPage.label.join_us')}</Title>
      <Subtitle class="mt-3">{t('login.registerPage.label.join_guide')}</Subtitle>
      <FormView />
      <FooterView />
    </div>
  )
})
