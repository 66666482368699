import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AliasDelete } from '../types'
import { ConfirmationResponse } from '@/features/register/types'
import { AuthApiClient } from '../api-client/api-client'

export const useDeleteAlias = (options?: UseMutationOptions<ConfirmationResponse, Error, AliasDelete>) => {
    return useMutation<ConfirmationResponse, Error, AliasDelete>({
        mutationFn: async (deleteParams) => {
            return AuthApiClient.deleteAlias(deleteParams)
        },
        ...options
    })
}
