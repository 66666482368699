import { useTranslation } from 'react-i18next'
import { KYCSessionIFrame } from '@/molecules/kyc-session-iframe/index.react'
import { CreateKYCDocument, KYCDocumentType, KYCSession, KYCSessionScenario, KYCSessionType } from '@/features/kyc/types'
import { useKYC } from '@/features/kyc/hooks/use-kyc'
import { UserType } from '@/features/user/types'
import { useCallback, useEffect, useState } from 'react'
import Loader from '@/atoms-react/loader/Loader.react'

type IdentitySessionProps = {
    onValidated: (kycDocument: CreateKYCDocument) => void
    documentType?: KYCDocumentType
    user?: UserType
}

export const IdentitySession = ({ documentType, user, onValidated }: IdentitySessionProps) => {
    const { i18n } = useTranslation(['atoms'])

    const { createKYCSessionMutation } = useKYC()
    const { mutateAsync: createKYCSession } = createKYCSessionMutation()
    const executeCreateKYCSession = useCallback(async () => {
        if (!user) return
        if (!documentType) return
        let senario = KYCSessionScenario.KYC
        if (documentType?.need_manual_validation) {
            senario = KYCSessionScenario.ManualKYC
        }
        const response = await createKYCSession({
            UUID: user.naturalPerson.UUID ?? '',
            language: i18n.language,
            sessionSenario: senario,
            sessionType: KYCSessionType.Info,
            // temp fix for user naturalPerson because DateOfBirth is not the same format as intended
            naturalPerson: {...user.naturalPerson, DateOfBirth: {...user.naturalPerson.DateOfBirth, date: user.naturalPerson.DateOfBirth.date.substring(0, 10)}}
        })

        setKYCSessionState(response)
    }, [user, i18n.language, documentType])

    useEffect(() => {
        if (!KYCSessionState) {
            executeCreateKYCSession()
        }
    }, [executeCreateKYCSession])

    const [KYCSessionState, setKYCSessionState] = useState<KYCSession | null>(null)
    return (
        <>
            {!KYCSessionState &&
                <div className="flex flex-1 items-center justify-center">
                    <Loader className="text-gray-500" />
                </div>
            }
            {KYCSessionState &&
                <KYCSessionIFrame
                    className={'h-[89vh]'}
                    naturalPersonUUID={user?.naturalPerson.UUID}
                    KYCSessionState={KYCSessionState}
                    documentType={documentType}
                    params={{ powered_by: false, show_consent: false, show_home: true, show_final: false }}
                    onValidated={onValidated}
                />
            }

        </>
    )
}