import { Button } from '@/atoms-react/button/Button.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import Image from '@/atoms-react/image/Image.react'
import { Modal } from '@/atoms-react/modal/index.react'
import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import { AnimatedEnvelopePass } from '@/components/animations/envelope-olkypass/index.react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { Themes } from '@/types/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ModalOlkyPassProps = {
  isOpen: boolean
  imageUrl: string
  title: string
  description: string
  totalSteps: number
  currentStep: number
  onStepChange: () => void
  onClose: () => void
  onUnderstandButtonClick: () => void
  onVerify: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const ModalOlkyPass = ({
  isOpen,
  imageUrl,
  title,
  description,
  totalSteps,
  currentStep,
  onStepChange,
  onClose,
  onUnderstandButtonClick,
  onVerify
}: ModalOlkyPassProps) => {
  const { t } = useTranslation(['pass'])
  const { status } = useKYCContext()

  return (
    <Modal open={isOpen} closable onClose={onClose} className="sm:w-full w-full max-w-lg">
      <div className="flex flex-col h-[650px]">
        <div className="bg-slate-50 h-2/5 md:h-1/2 w-full rounded-sm mb-6 p-6 overflow-hidden flex items-center justify-center">
          {currentStep !== 0 ? <Image src={imageUrl} alt="alt" /> : <AnimatedEnvelopePass />}
        </div>
        <CardTitle className="text-center mb-4">{title}</CardTitle>
        <Base className="text-center text-slate-400 dark:text-slate-400">{description}</Base>
        <div className="flex flex-col items-center mt-auto">
          <DotsNavigation total={totalSteps} current={currentStep} className="mb-4" />
          {currentStep < totalSteps - 1 ? (
            <Button onClick={onStepChange}>{t('olkypass.general.continue')}</Button>
          ) : status === KYCStatusType.NEUTRAL || status === KYCStatusType.AUTHENTICATED ? (
            <Button onClick={onUnderstandButtonClick} theme={Themes.primary}>
              {t('olkypass.general.understand')}
            </Button>
          ) : (
            <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
              <Button onClick={onUnderstandButtonClick} theme={Themes.white}>
                {t('olkypass.general.understand')}
              </Button>
              {status === KYCStatusType.DECLARATIVE && (
                <Button onClick={onVerify}>
                  {t('olkypass.mainPage.button.completePass')}
                </Button>
              )}
              {status === KYCStatusType.VERIFIED && (
                <div data-tooltip={t('olkypass.general.coming_soon')}>
                  <Button disabled={true} onClick={onVerify}>
                    {t('olkypass.mainPage.button.verify_address')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
