<template>
  <ion-page>
    <ion-content id="twoFaPage" :fullscreen="true">
      <login-header :title="$t('olkypay.labels.twoFaPage.two_fa_code')"></login-header>
      <div class="v-container--fluid">
        <ion-grid class="">
          <div class="card card-plain">
            <div class="card-body px-lg-5 py-lg-5 text-center">
              <ion-row class="mt-3">
                <ion-col style="margin-inline: 1rem;">
                  <h2>
                    {{ $t('olkypay.labels.twoFaPage.message.introduce') }}
                    <span>{{ $t('olkypay.labels.twoFaPage.message.code_otp') }}</span>
                    {{ $t('olkypay.labels.twoFaPage.message.shown') }}
                  </h2>
                </ion-col>
              </ion-row>
              <div class="mt-3">
                <div style="display: flex; flex-direction: row">
                  <v-otp-input
                      ref="otpInput"
                      v-model:value="challenge"
                      input-classes="otp-input"
                      separator="-"
                      :num-inputs="6"
                      :should-auto-focus="true"
                      input-type="letter-numeric"
                      :placeholder="['*', '*', '*', '*','*','*']"
                      @on-complete="completeWriteOTP"
                  />
                </div>
              </div>

              <ion-row class="mt-4">
                <ion-col>
                  <ion-button
                    class="continue-button large-button"
                    :disabled="!challenge"
                    @click="handleTwoFactory()"
                  >
                    {{ $t('olkypay.buttons.twoFaPage.standard.continue') }}
                  </ion-button>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </ion-grid>
      </div>
      <loader></loader>
      <info-modal ref="infoModal"></info-modal>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  IonInput,
  IonRouterOutlet,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
  alertController,
  IonLoading
} from '@ionic/vue'
import { logoIonic } from 'ionicons/icons'
import olkyLogoBlue from '../../../../public/images/olkypay/olky-logo-blue.png'
import LanguageSwitcher from '../components/LanguageSwitcher.vue'
import { mapActions, mapGetters } from 'vuex'
import Loader from '../components/Loader.vue'
import LoginHeader from '../components/security/loginHeader.vue'
import InfoModal from '../components/InfoModal.vue'
import VOtpInput from "vue3-otp-input";
export default {
  name: 'TwoFaPage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonRadio,
    IonRadioGroup,
    IonInput,
    IonImg,
    IonButton,
    IonRouterOutlet,
    alertController,
    IonLoading,
    VOtpInput,
    LanguageSwitcher,
    Loader,
    LoginHeader,
    InfoModal
  },
  data() {
    return {
      logoIonic,
      challenge: ""
    }
  },
  methods: {
    ...mapActions('auth', ['twoFaCodeCheck']),
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    async completeWriteOTP(value) {
      this.challenge = value
      await this.handleTwoFactory()
    },
    async handleTwoFactory() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.twoFaPage.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      const twoFaData = { username: this.username, challenge: this.challenge }
      this.twoFaCodeCheck(twoFaData)
        .then((res) => {
          this.challenge = "";
          this.$router.push({ name: 'SuppliersRouter' })
        })
        .catch(async (err: any) => {
          this.stopLoader()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t('olkypay.notifications.error.twoFaPage.header'),
            content: this.$i18n.t('olkypay.notifications.error.twoFaPage.message'),
            button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
          }
          await this.$refs.infoModal.openModal(failedDialog)
        })
        .finally(() => {
          this.stopLoader()
        })
    }
  },
  mounted() {
    this.challenge = "";
  },
  computed: {
    ...mapGetters('user', ['username']),
    olkyLogoBlue() {
      return olkyLogoBlue
    }
  }
}
</script>

<style scoped></style>
