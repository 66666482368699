import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../flow-header.react'
import { PasswordInput } from '@/atoms-react/input/password-input.react'

export type AccountSecurityPasswordOutput = {
  password: string
}

type AccountSecurityPasswordProps = {
  onValidated: (output: AccountSecurityPasswordOutput) => void
} & React.ComponentPropsWithoutRef<'div'>

export const AccountSecurityPassword = ({
  onValidated = () => {},
  ...props
}: AccountSecurityPasswordProps) => {
  const { t } = useTranslation(['pass'])
  const [password, setPassword] = useState<string>('')
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)

  const execute = () => {
    if (!isPasswordValid) {
      toast.error(t('flow.id_control.error.password_not_correct'))
      return
    }

    onValidated({ password })
  }

  return (
    <Column {..._.omit(props, 'key')}>
      <div className="grow mb-12">
        <FlowHeader
          title={t('flow.id_control.signup.label.account_security_title_password')}
          description={t('flow.id_control.signup.label.account_security_description_password')}
        />
        <PasswordInput
          onChange={({ password, valid }) => {
            setPassword(password)
            setIsPasswordValid(valid)
          }}
        />
      </div>
      <Button
        shortcut="enter"
        className="shrink-0"
        onClick={() => execute()}
        icon={ChevronRightIcon}
        loading={false}
      >
        {t('flow.id_control.input.continue')}
      </Button>
    </Column>
  )
}
