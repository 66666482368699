import { Button } from '@/atoms-react/button/Button.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import { Themes } from '@/types/theme';
import { useTranslation } from 'react-i18next';

type FormFooterProps = {
    currentStep: number;
    onNext?: () => void
    onPrevious?: () => void
    nextDisabled?: boolean
}

export const FormFooter = ({ currentStep, onNext, onPrevious, nextDisabled = false }: FormFooterProps) => {
    const { t } = useTranslation(["atoms"]);
    return (
        <div className={'flex pb-4 px-4 justify-between'}>
            <div className={'flex-1 flex justify-start'}>
                {onPrevious && (
                    <Button theme={Themes.white} className={'w-fit h-10 text-blue-500 border-blue-500 font-medium'} onClick={onPrevious}>
                        {t('molecules.modal_kyc_identity.previous')}
                    </Button>
                )}
            </div>
            <div className={'self-end'}>
                <DotsNavigation total={3} current={currentStep} />
            </div>
            <div className={'flex-1 flex justify-end'}>
                {onNext && (
                    <Button className={'w-fit h-10'} disabled={nextDisabled} onClick={onNext}>
                        {t('molecules.modal_kyc_identity.next')}
                    </Button>
                )}
            </div>
        </div>
    )
}

