import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { useUser } from '@/features/user/hooks/use-user'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { IbanCard } from '@/molecules/iban-card/index.react'
import { NaturalEntityDataType } from '@/features/utils/types'
import { ModalAddIban } from '@/molecules/modal-add-iban/index.react'
import useCreateIban from '@/features/user/iban/hooks/use-create-iban'
import { AliasIBAN } from '@/features/user/types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Routes } from '@/features/router'
import { useDeleteAlias } from '@/features/user/hooks/use-delete-alias'
import { ModalDeleteAlias } from '@/molecules/modal-delete-alias/index.react'
import { Base } from '@/atoms-react/text/Text.react'

type AliasesAddressProps = React.ComponentPropsWithoutRef<'div'>

export const AliasesPaymentApp = ({ ...props }: AliasesAddressProps) => {
  const { t } = useTranslation(['pass'])
  const { useUserProfile } = useUser()
  const { data: user, refetch } = useUserProfile()
  const navigate = useNavigate();

  const ibans = user?.naturalPerson.AliasIBAN

  const [searchParams] = useSearchParams()

  const [modalAddIban, setModalAddIban] = useState<boolean>(!!searchParams.get("add"))
  const [deletedIBAN, setDeletedIBAN] = useState<AliasIBAN | null>(null)
  const { mutateAsync: createIban } = useCreateIban()
  const { mutateAsync: deleteAlias } = useDeleteAlias()

  const onDelete = async () => {
    if (!deletedIBAN) {
      return
    }
    await deleteAlias({ ItemType: NaturalEntityDataType.AliasIBANDto, ItemUUID: deletedIBAN.UUID || "", UUID: user?.naturalPerson?.UUID || "" })
    await refetch()
    setDeletedIBAN(null)
  }

  return (
    <Column {...props}>
      <LayoutTitle
        action={
          <Button
            onClick={() => {
              setModalAddIban(true)
            }}
            icon={PlusCircleIcon}
          >
            {t('aliasPaymentsPage.button.add_iban')}
          </Button>
        }
      >
        {t('aliasPaymentsPage.title')}
      </LayoutTitle>

      <ModalAddIban
        open={modalAddIban}
        onClose={() => setModalAddIban(false)}
        onValidated={async (iban) => {
          try {
            await createIban({ UUID: user?.naturalPerson?.UUID || '', iban })
            toast.success(t('success.create'))
            setModalAddIban(false)
            refetch()
          } catch (_) {
            toast.error(t('error.create'))
          }

        }}
      />
      <ModalDeleteAlias isOpen={!!deletedIBAN} onClose={() => setDeletedIBAN(null)} onValidated={onDelete}>
        <Base>{t('aliasPaymentsPage.confirm_delete', { name: deletedIBAN?.Iban })}</Base>
      </ModalDeleteAlias>
      <div className={'flex flex-col max-w-3xl'}>
        {ibans && ibans?.map((iban) => <IbanCard key={iban.UUID} iban={iban} onDelete={() => setDeletedIBAN(iban)} onPublicClick={() => navigate(Routes.account_alias_public)} />)}
      </div>
    </Column>
  )
}
