import { Subtitle, Title } from '@/atoms/text/Text.tsx'
import { ref } from 'vue'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'
import { FooterView } from './components/FooterView'
import { FormView } from './components/FormView'
import { LayoutView } from './components/LayoutView'

export const LoginView = defineVueComponent(() => {
  const { t } = useI18n()
  const isStandardAuthn = ref(false)
  const switchAuthnMode = () => {
    isStandardAuthn.value = !isStandardAuthn.value
  }
  return () => (
    <LayoutView>
      <Title>{t('login.loginPage.label.title')}</Title>
      {isStandardAuthn.value && <Subtitle class="mt-3">{t('login.loginPage.label.info')}</Subtitle>}
      {!isStandardAuthn.value && (
        <Subtitle class="mt-3">{t('login.loginPage.label.web_authn.info')}</Subtitle>
      )}

      <FormView onSwitchAuthMode={() => switchAuthnMode()} />
      <FooterView />
    </LayoutView>
  )
})
