import { Modal, ModalContent } from "@/atoms-react/modal/index.react"
import { SolidAuthnForm } from "./sollid-authn-process.react"
import { useEffect } from "react"

type SolidAuthnModalProps = {
    onClose: () => void
    onValidated: () => void
}

export const SolidAuthnModal = ({ onClose, onValidated }: SolidAuthnModalProps) => {
    useEffect(() => {
        onValidated();
    }, [onValidated]);

    return null;  // Ne rend rien
    /* return (
        <Modal className={'sm:m-0 m-0 sm:w-full md:w-9/12'} maxWidthClassName={'max-w-5xl'} open={true} onClose={onClose}>
            <ModalContent title={''} >
                <SolidAuthnForm onValidated={onValidated} />
            </ModalContent>
        </Modal>
    ) */
}