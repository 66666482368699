<template>
  <ion-page>
    <ion-content id="cardsPage" :fullscreen="true">
      <div class="header-app height-261 absolute" @click="gotoRouter('SuppliersRouter')">
        <ion-grid>
          <ion-row class="mt-3 mb-2">
            <ion-col size="1" class="mt-3">
              <div class="circleIconMenu">
                <ion-icon
                  :icon="menu"
                  color="light"
                  size="large"
                  @click="gotoRouter('SuppliersRouter')"
                ></ion-icon>
              </div>
            </ion-col>
            <ion-col class="ion-margin-horizontal mt-4">
              <h2>{{ $t('olkypay.labels.cardsPage.single_card.header') }}</h2>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div class="text-center">
        <ion-row class="d-flex justify-content-center align-items-center">
          <ion-col class="text-center mt-7">
            <img :src="particularCard" class="item-on-header-app" />
            <h5 class="text-center text-gray mt-0 mb-0">{{ currentCard.fullName }}</h5>
          </ion-col>
        </ion-row>
      </div>
      <ion-grid>
        <ion-row v-if="!currentCard.cardBlocked">
          <ion-col>
            <button class="btn manager-buttons" @click="gotoRouter('CardBlockSubTabRouter')">
              <ion-icon slot="start" :icon="lockClosed" color="blue_olky" size="small"></ion-icon>
              {{ $t('olkypay.buttons.cardManagerPage.lock') }}
            </button>
          </ion-col>
        </ion-row>
        <div v-else>
          <ion-row class="alert alert-warning border-radius-lg mx-3">
            <ion-col size="2">
              <ion-icon :icon="alertCircle" size="large" color="light"></ion-icon>
            </ion-col>
            <ion-col>
              <p class="text-white mb-0 mt-1">
                {{ $t('olkypay.labels.cardsPage.single_card.alert_message') }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="text-center">
              <button class="btn bg-gradient-info mb-0" @click="proceedToUnBlockCard()">
                <ion-icon slot="start" :icon="lockOpenSharp" size="small"></ion-icon>
                {{ $t('olkypay.buttons.cardManagerPage.unlock') }}
              </button>
            </ion-col>
          </ion-row>
        </div>
        <ion-row>
          <ion-col>
            <h2 class="text-lg">{{ $t('olkypay.labels.cardManagerPage.manage_card') }}</h2>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="9">
            <label class="text-gray">
              <ion-icon slot="start" :icon="checkmarkCircle" color="medium" size="small"></ion-icon
              >&nbsp;{{ $t('olkypay.labels.cardManagerPage.card_status.label') }}</label
            >
          </ion-col>
          <ion-col>
            <ion-chip v-if="currentCard.active" :outline="true" color="success">
              {{ $t('olkypay.labels.cardManagerPage.card_status.status.active') }}
            </ion-chip>
            <ion-chip
              v-if="!currentCard.active && !currentCard.cardBlocked"
              :outline="true"
              color="medium"
            >
              {{ $t('olkypay.labels.cardManagerPage.card_status.status.inactive') }}
            </ion-chip>
            <ion-chip v-if="currentCard.cardBlocked" :outline="true" color="danger">
              {{ $t('olkypay.labels.cardManagerPage.card_status.status.blocked') }}
            </ion-chip>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <span class="text-normal">{{ $t('olkypay.labels.cardManagerPage.card_balance') }}</span
            ><span class="font-weight-bold">
              €
              {{
                new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2,maximumFractionDigits: 2}).format(currentCard.balanceOperation / 100)
              }}</span
            >
          </ion-col>
        </ion-row>
      </ion-grid>
      <transactions-list></transactions-list>
    </ion-content>
    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>
<script lang="ts">
import businessCard from '../../../../../../public/images/olkypay/cards/business-card.png'
import particularCard from '../../../../../../public/images/olkypay/cards/particular-card.png'
import TransactionsList from '../../../components/transactions/transactionsList.vue'
import AuthenticationProcessPopup from '../../../components/security/authenticationProcessPopup.vue'
import InfoModal from '../../../components/InfoModal.vue'
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonListHeader,
  IonProgressBar,
  IonList,
  IonButton,
  alertController,
  IonChip
} from '@ionic/vue'
import { mapActions, mapGetters } from 'vuex'
import {
  menu,
  lockClosed,
  alertCircle,
  cash,
  card,
  cardOutline,
  checkmarkCircle,
  lockOpenSharp
} from 'ionicons/icons'
import { CardService } from '../../../services/card.service'
export default {
  name: 'CardDetailPage',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonListHeader,
    IonProgressBar,
    IonList,
    IonButton,
    IonChip,
    TransactionsList,
    AuthenticationProcessPopup,
    InfoModal
  },
  data() {
    return {
      menu,
      lockClosed,
      alertCircle,
      cash,
      card,
      cardOutline,
      checkmarkCircle,
      lockOpenSharp,
      businessCard,
      particularCard
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    gotoRouter(router_name) {
      this.$router.push({ name: router_name })
    },
    async proceedToUnBlockCard() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      }
    },
    async onSuccessAuthenticateUser() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.unBlockingCard.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      const inputData = { id: this.currentCard.id, active: 1, treatedEntity: this.treatedEntity }
      await CardService.blockCard(inputData)
        .then(async (res) => {
          this.stopLoader()
          const successDialog = {
            type: 'success',
            title: this.$i18n.t(
              'olkypay.notifications.success.blockCardPage.un_blocking_card.header'
            ),
            content: this.$i18n.t(
              'olkypay.notifications.success.blockCardPage.un_blocking_card.message'
            ),
            button: this.$i18n.t('olkypay.buttons.blockCardPage.close')
          }
          await this.$refs.infoModal.openModal(successDialog)
        })
        .catch(async (err: any) => {
          this.stopLoader()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t(
              'olkypay.notifications.error.blockCardPage.un_blocking_card.header'
            ),
            content: this.$i18n.t(
              'olkypay.notifications.error.blockCardPage.un_blocking_card.message'
            ),
            button: this.$i18n.t('olkypay.buttons.blockCardPage.close')
          }
          await this.$refs.infoModal.openModal(failedDialog)
        })
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('card', ['currentCard']),
    ...mapGetters('supplier', ['treatedEntity'])
  }
}
</script>
<style></style>
