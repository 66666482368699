import { NaturalEntityModify } from '../utils/types'
import { NaturalPerson, NaturalPersonDTO } from './types'

export const convert_naturalPerson_DTO = (naturalPerson: NaturalPerson): NaturalPersonDTO => ({
  ...naturalPerson,
  countryOfBirth: naturalPerson?.countryOfBirth?.toString() ?? null,
  CountryOfCitizenship: naturalPerson?.CountryOfCitizenship?.toString() ?? null,
  CountryOfCitizenship2: naturalPerson?.CountryOfCitizenship2?.toString() ?? null
})

export const convert_naturalPerson_naturalEntityModify = (naturalPerson: NaturalPerson): NaturalEntityModify => ({
  ...naturalPerson,
  countryOfBirth: naturalPerson?.countryOfBirth?.toString() ?? null,
  CountryOfCitizenship: naturalPerson?.CountryOfCitizenship?.toString() ?? null,
  CountryOfCitizenship2: naturalPerson?.CountryOfCitizenship2?.toString() ?? null
})