<template>
  <div class="transition-all sm:px-1 sm:-mx-1">
    <div ref="animatedHeight" :style="{ boxSizing: 'border-box' }">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

export default {
  setup() {
    const animatedHeight = ref<HTMLElement | null>(null)
    let interval: any = null

    const updateSize = () => {
      if (animatedHeight.value) {
        const contentHeight = animatedHeight.value.scrollHeight
        const parent = animatedHeight.value.parentNode
        ;(parent! as HTMLElement).style.height = `${contentHeight}px`
        ;(parent! as HTMLElement).style.overflow = 'hidden'
      }
    }

    onMounted(() => {
      interval = setInterval(() => {
        updateSize()
      }, 200)
    })

    onUnmounted(() => {
      clearInterval(interval)
    })

    return { animatedHeight }
  }
}
</script>
