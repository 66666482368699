import Column from '@/atoms-react/column/Column.react'
import { Base } from '@/atoms-react/text/Text.react'
import { KYCDocument } from '@/features/kyc/types'
import { Document } from '@/features/user/document/types'
import { useLocale } from '@/features/utils/hooks/use-locale'
import React from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { twMerge } from 'tailwind-merge'

type DocumentRowProps = {
  document: Document
  clickable?: boolean
  className?: string
  onClick?: () => void
  isSelected?: boolean
} & React.ComponentPropsWithoutRef<'div'>

export const DocumentRow = ({
  document,
  clickable = true,
  className,
  onClick,
  isSelected,
  ...props
}: DocumentRowProps) => {
  const { locale } = useLocale()
  const extension: "pdf" | "png" | "jpeg" | null = (() => {
    const split = document.Document?.file_name.split(".")[1];
    if (split && ["pdf", "png", "jpeg"].includes(split)) {
      return split as "pdf" | "png" | "jpeg"
    }
    return null
  })()
  return (
    <div
      className={twMerge(
        'flex items-center py-2 px-2',
        !clickable ? '' : 'md:px-6 cursor-pointer',
        isSelected && clickable ? 'bg-blue-50' : '',
        clickable ? 'hover:bg-blue-50' : '',
        className
      )}
      onClick={onClick}
      {...props}
    >

      <div className={'w-6 mr-4 min-w-6'}>
        <FileIcon extension={extension || undefined} {...(extension ? defaultStyles[extension] : {})} />
      </div>
      <Column>
        <Base className="font-semibold">
          {locale.language === 'FR' ? document.typePerLanguage.fr : document.typePerLanguage.en}
        </Base>
        <Base className="text-slate-500 text-sm">{document.Number}</Base>
      </Column>
    </div>
  )
}
