<template>
  <v-dialog v-model="dialog" persistent width="auto">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">{{
        $t('olkypass.accountPage.documentsSection.dialog.header.chooseType')
      }}</v-card-title>
      <v-card-text>
        <DynamicBlobAnimation src="/public/images/olkypass/olkypass-entry.gif"/>
        <p class="mt-6 font-semibold text-blue-500">{{ $t('olkypass.accountPage.documentsSection.dialog.description') }}</p>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <label>{{
              $t('olkypass.accountPage.documentsSection.autocomplete.documentType')
            }}</label>
            <v-combobox
              v-model="newDocument.pieceType"
              clearable
              :loading="!documentsType.length || loader"
              :items="documentsType"
              :item-title="documentTypeLabel"
              item-value="id"
              autocomplete="off"
              density="compact"
              variant="outlined"
              class="selectInput"
            ></v-combobox>
          </v-col>
          <v-col cols="12">
            <label>{{ $t('olkypass.accountPage.documentsSection.input.documentNumber') }}</label>
            <v-text-field
              v-model="newDocument.number"
              density="comfortable"
              type="text"
              clearable
              variant="plain"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="cancel"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.cancel') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :loading="loader"
              :disabled="!newDocument.pieceType"
              size="medium"
              @click="openSessionDocumentDialog"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <KYCDocumentDialogChild ref="kycDocumentDialog"></KYCDocumentDialogChild>
</template>

<script>
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import KYCDocumentDialogChild from './KYCDocumentDialogChild.vue'
import { DynamicBlobAnimation } from "@/components/animations/dynamic-blob/index-vue.tsx"

export default {
  name: 'DocumentTypeDialog',
  components: { KYCDocumentDialogChild, DynamicBlobAnimation },
  mixins: [NotificationsMixins],
  data: () => ({
    dialog: false,
    newDocument: {},
    loader: false
  }),
  async mounted() {
    this.loader = true
    await store.dispatch('loadDocumentsType')
    this.loader = false
  },
  methods: {
    open() {
      this.dialog = true
      this.newDocument = {
        pieceType: null,
        number: null
      }
    },
    cancel() {
      this.dialog = false
    },
    async openSessionDocumentDialog() {
      this.loader = true
      store.dispatch('setNewDocument', this.newDocument)
      this.cancel()
      await this.$refs.kycDocumentDialog.open()
      this.loader = false
    }
  },
  computed: {
    ...mapGetters(['documentsType', 'documentTypeLabel'])
  }
}
</script>

<style scoped></style>
