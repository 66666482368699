import ApiService from './api.service'
import { type AxiosRequestConfig } from 'axios'

class ResponseError extends Error {
  errorCode: any
  errorMessage: any

  constructor(errorCode: any, message: string | undefined) {
    super(message)
    this.name = this.constructor.name
    if (message != null) {
      this.message = message
    }
    this.errorCode = errorCode
  }
}

const SupplierService = {
  getSuppliers: async function () {
    try {
      const response = await ApiService.getRequest('/suppliers/active/light', {})
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.response.status, error.message)
    }
  },
  ribInfo: async function () {
    try {
      const response = await ApiService.getRequest('/supplier/iban', {})
      return response.data[0]
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  downloadPdfIban: async function () {
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      responseType: 'blob'
    }
    try {
      const response = await ApiService.getRequest('/supplier/pdf/iban', requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  setCurrentSupplier: async function (supplier_id: any) {
    const url = '/me/supplier/' + supplier_id
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      params: {
        supplier_id: supplier_id
      }
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  }
}

export { SupplierService, ResponseError }
