import { Base } from '@/atoms-react/text/Text.react'
import { AliasEmail } from '@/features/user/types'
import { EnvelopeIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { AliasCard } from '../alias-card/index.react'
import { useTranslation } from 'react-i18next'

type EmailCardProps = {
  email: AliasEmail
  isLoading?: boolean
  onClick?: () => void
  onPublicClick?: () => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'>

export const EmailCard = ({
  email,
  onPublicClick,
  ...props
}: EmailCardProps) => {
  const { t } = useTranslation(["pass"])
  return (
    <AliasCard tooltipDesignation={t('olkypass.aliasPublicPage.this_email')} icon={EnvelopeIcon} isPublic={email.Public} onPublicClick={onPublicClick} {...props}>
      <Base
        className={twMerge(
          'md:mr-3 font-semibold text-ellipsis overflow-hidden break-normal whitespace-nowrap'
        )}
        title={email?.Mail}
      >
        {email?.Mail}
      </Base>
    </AliasCard>
  )
}
