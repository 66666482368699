import { fetchServer } from '@/features/utils/fetch'
import { Kycoin, KycoinDTOServer } from '../types'
import { KycoinConverters } from '../converters'

export class KycoinApiClient {
  static async getKycoin(): Promise<Kycoin> {
    const response = await fetchServer('/user/kycoin', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    const data = (await response.json()) as KycoinDTOServer
    return KycoinConverters.DTOServer_entity(data)
  }
}
