export const KypayIcon = (props: {
  size?: number
  color?: string
  style?: any
  className?: string
}) => {
  return (
    <div className={props.className} style={props.style}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1324_4808)">
          <g clipPath="url(#clip1_1324_4808)">
            <path
              d="M7.67165 2.82843V1.30961C7.67165 1.09768 7.49975 0.925781 7.28782 0.925781H1.8389C1.62698 0.925781 1.45508 1.09768 1.45508 1.30961V7.18238L7.3773 3.03565L7.67165 2.83078V2.82843Z"
              fill={props.color || '#10101E'}
            />
            <path
              d="M15.9322 14.5509L10.8812 7.33594L7.67165 9.58238L1.45508 13.7833V14.7723C1.45508 14.9842 1.62698 15.1561 1.8389 15.1561H7.28547C7.4974 15.1561 7.6693 14.9842 7.6693 14.7723V11.7605L9.93222 14.9936C10.0052 15.0972 10.123 15.1585 10.2478 15.1585H15.6143C15.9251 15.1585 16.1088 14.8076 15.9298 14.5533L15.9322 14.5509Z"
              fill={props.color || '#10101E'}
            />
            <path
              d="M15.7816 2.83759V0.374512C15.7816 0.162583 15.6097 -0.00931458 15.3978 0.0825211H12.8146C12.7346 -0.00931458 12.6592 0.014233 12.5933 0.0589735L8.18751 3.14371L7.93084 3.32267L7.67417 3.50163L1.4576 7.85794L1.20093 8.03691L0.94426 8.21587L0.164833 8.76217C0.0612238 8.83517 0 8.95291 0 9.07771V13.3492C0 13.5753 0.186026 13.7354 0.386181 13.7354C0.461533 13.7354 0.536886 13.7142 0.605174 13.6648L15.6191 3.15313C15.7227 3.08013 15.784 2.96239 15.784 2.83759H15.7816Z"
              fill={props.color || '#2EA5DE'}
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1324_4808">
            <rect width="16" height="16" fill="white" />
          </clipPath>
          <clipPath id="clip1_1324_4808">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
