import { Routes } from '@/features/router'
import { Navigate, Routes as ReactRoutes, Route, useLocation } from 'react-router-dom'
import FlowRoot from './flow/index.react'
import { PassRoot } from './views/index.react'
import { useExposeReactRouter } from '@/features/utils/hooks/use-expose-react-router'
import { useEffect } from 'react'

export const InitialRouter = () => {
  useExposeReactRouter()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <ReactRoutes>
      <Route path={Routes.flow}>{FlowRoot()}</Route>
      <Route path={Routes.pass}>{PassRoot()}</Route>
      <Route path="*" element={<Navigate to={Routes.pass} />} />
      <Route path="" element={<Navigate to={Routes.pass} />} />
    </ReactRoutes>
  )
}
