import ApiService from './api.service'
import { type AxiosRequestConfig } from 'axios'

class ResponseError extends Error {
  errorCode: any
  errorMessage: any

  constructor(errorCode: any, message: string | undefined) {
    super(message)
    this.name = this.constructor.name
    if (message != null) {
      this.message = message
    }
    this.errorCode = errorCode
  }
}

const CardService = {
  blockCard: async function (inputData: any) {
    let url = ''
    if (inputData.treatedEntity === 'card') {
      url = '/supplier/card/active/' + inputData.active
    } else {
      url = '/supplier/id/' + inputData.id + '/card/active/' + inputData.active
    }
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      data: inputData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.message)
    }
  },

  autoLeveling: async function (inputData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: '/supplier/card/id/' + inputData.id + '/leveling/' + inputData.period,
      data: inputData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.message)
    }
  },
  credit: async function (inputData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: '/supplier/card/id/' + inputData.id + '/transfer',
      data: inputData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.message)
    }
  }
}

export { CardService, ResponseError }
