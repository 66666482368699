<template>
  <ion-content :fullscreen="true" style="background-color: #f0f2f5!important;">
    <div class="card mt-4">
      <div class="card-body border card-plain border-radius-lg">
        <ion-row>
          <ion-col size="1">
            <button
                class="btn btn-icon-only btn-rounded btn-outline-light mb-0 btn-sm d-flex align-items-center justify-content-center">
              <ion-thumbnail slot="start">
                <ion-skeleton-text :animated="true"></ion-skeleton-text>
              </ion-thumbnail>
            </button>
          </ion-col>
          <ion-col size="7">
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-dark text-sm">
                <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
              </h6>
              <span class="text-xs"> <ion-skeleton-text
:animated="true"
                                                        style="width: 80%;"></ion-skeleton-text></span>
            </div>
          </ion-col>
          <ion-col size="3">
            <div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
              <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
            </div>

          </ion-col>
          <ion-col size="1">
            <ion-thumbnail slot="start">
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
            </ion-thumbnail>
          </ion-col>
        </ion-row>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div class="card border-1">
          <div class="card-header p-3">
            <h5 class="mb-2">
              <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-3 col-6 text-center">
                <div class="border border-light border-1 border-radius-md py-3">
                  <h6 class="text-primary text-gradient mb-0">
                    <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
                  </h6>
                  <h4 class="font-weight-light mb-0"><span class="small"> </span><span id="state1"><ion-skeleton-text
                      :animated="true" style="width: 80%;"></ion-skeleton-text></span>
                  </h4>
                </div>
              </div>
              <div class="col-lg-3 col-6 text-center">
                <div class="border border-light border-1 border-radius-md py-3">
                  <h6 class="text-primary text-gradient mb-0">
                    <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
                  </h6>
                  <h4 class="font-weight-light mb-0"><span class="small"> </span><span id="state2"><ion-skeleton-text
                      :animated="true" style="width: 80%;"></ion-skeleton-text></span>
                  </h4>
                </div>
              </div>

            </div>
            <div class="row mt-4">
              <div class="col-lg-5 col-12">
                <h6 class="mb-0">
                  <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
                </h6>
                <div class="border border-light border-1 border-radius-md p-3">
                  <div class="d-flex align-items-center mb-2">
                    <div class="form-group w-100">
                      <div class="input-group bg-gray-200 border-radius-md is-filled">
                        <input class="form-control form-control-sm border-radius-md" type="text" disabled="">
                        <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
                      </div>
                    </div>
                    <a href="javascript:;" class="btn btn-sm ms-2 px-3 mb-0">
                      <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <hr class="horizontal dark">
    <div class="card mb-4 border">
      <div class="card-header pb-0 px-3">
        <ion-row>
          <ion-col size="6">
            <h6 class="mb-0">
              <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
            </h6>
          </ion-col>
          <ion-col size="6" class=" d-flex justify-content-start justify-content-md-end align-items-center">
            <ion-icon class="material-icons me-2 text-lg"></ion-icon>
            <small>
              <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
            </small>
          </ion-col>
        </ion-row>
      </div>
      <div class="card-body pt-4 p-3">
        <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
          <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
        </h6>
        <ul class="list-group">
          <ion-item>
            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div class="d-flex align-items-center">
                <ion-col size="2">
                  <button
                      class="btn btn-icon-only btn-rounded mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                    <i class="material-icons text-center">
                      <ion-icon class="material-icons text-lg"></ion-icon>
                    </i>
                  </button>
                </ion-col>
                <ion-col>
                  <div class="d-flex flex-column">
                    <h6 class="mb-1 text-dark text-sm">
                      <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
                    </h6>
                    <span class="text-xs"><ion-skeleton-text
:animated="true"
                                                             style="width: 80%;"></ion-skeleton-text></span>
                  </div>
                </ion-col>

                <ion-col size="3">
                  <div class="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">
                    <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
                  </div>
                </ion-col>
              </div>
            </li>
          </ion-item>
        </ul>
      </div>
    </div>
  </ion-content>
</template>

<script lang="ts">
import {
  IonRow, IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonTabBar,
  IonTabs,
  IonContent,
  IonPage,
  IonFooter
} from '@ionic/vue';

export default {
  name: "InternalSkeleton",
  components: {
    IonRow, IonCol,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonSkeletonText,
    IonThumbnail,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonTabBar,
    IonTabs,
    IonContent,
    IonPage,
    IonFooter
  },
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>