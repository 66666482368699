import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { Kycoin } from '../types'
import { KycoinApiClient } from '../api-client/api-client'

export const getKycoin = (options?: Omit<UseQueryOptions<Kycoin>, 'queryKey'>) =>
  useQuery<Kycoin>({
    queryKey: ['kycoin'],
    queryFn: async () => {
      return await KycoinApiClient.getKycoin()
    },
    ...options
  })
