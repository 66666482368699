import { ConfirmationResponse } from '@/features/register/types'
import { Iban } from '@/features/user/types'
import { fetchServer } from '@/features/utils/fetch'

export class IbanApiClient {
  static async create(iban: Iban): Promise<ConfirmationResponse> {
    const response = await fetchServer("/user/iban/create_new", {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: iban
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return (res.data || { existStatus: false })
  }
}
