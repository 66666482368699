import { A } from '@/atoms-react/a/A.react'
import { Routes } from '@/features/router'
import React from 'react'
import Loader from '@/atoms-react/loader/Loader.react'
import { useGateways } from '@/features/flow/hooks/use-gateways'
import { GatewayVote } from '@/features/flow/types'
import { OlkypassCard } from '@/app/olkypass/views/dashboard/components/olkypass-cards/index.react'
import { KYCStatusType } from '@/features/kyc/types'

type KYCAlertProps = { status: KYCStatusType, onClick?: () => void } & Omit<React.ComponentPropsWithoutRef<'a'>, 'onClick'>

export const KYCAlert = ({ status, ...props }: KYCAlertProps) => {
  const { useDocumentsGatewayVoter } = useGateways()
  const documentsVote = useDocumentsGatewayVoter()

  if (documentsVote === GatewayVote.Pending || documentsVote === GatewayVote.Inactive)
    return <Loader />

  return (
    <A noColor to={Routes.account_identity} className="block mb-4 fade-in" {...props}>
      <OlkypassCard status={status} small={true} />
    </A>
  )
}
