import { Routes } from '@/features/router'
import { Link } from '@/atoms/link/Link2'
import { Info } from '@/atoms/text/Text.tsx'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'

export const FooterView = defineVueComponent(() => {
  const { t } = useI18n()
  return () => (
    <>
      <hr class="-mx-10 my-7 dark:border-slate-800" />
      <Info>
        {t('login.registerPage.label.have_account')} ?{' '}
        <Link to={Routes.login}>{t('login.registerPage.button.sign_in')}</Link>
      </Info>
    </>
  )
})
