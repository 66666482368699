import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import i18n from '@/features/utils/i18n.ts'
import _ from 'lodash'
const uris = {
  _post: {
    list_collaborators: '/collaborator/list',
    search_collaborator: '/collaborator/search',
    get_alias_mail: '/collaborator/getValidEmails',
    add_contact_person: 'collaborator/addContactPerson'
  },
  _put: {
    edit_collaborator: '/collaborator/edit'
  }
}
const state = {
  collaborators: [],
  new_collaborator: {},
  collaborator_aliasMail: []
}

const getters = {
  collaborators: (state) => state.collaborators,
  new_collaborator: (state) => state.new_collaborator,
  collaborator_aliasMail: (state) => state.collaborator_aliasMail
}

const mutations = {
  LOAD_COMPANY_COLLABORATORS(state, response) {
    state.collaborators = response.data
  },
  SEARCH_COLLABORATOR(state, response) {
    state.new_collaborator = response.data
  },
  RESET_SEARCH_COLLABORATOR(state, response) {
    state.new_collaborator = {}
  },
  RESET_LOADED_COMPANY_COLLABORATORS(state) {
    state.collaborators = []
  },
  SET_COLLABORATOR_INFOS() {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  },
  LOAD_COLLABORATOR_ALIAS_MAIL(state, response) {
    state.collaborator_aliasMail = response.data
  },
  ADD_CONTACT_PERSON() {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  }
}

const actions = {
  async loadCompanyCollaborators({ commit }, data) {
    try {
      commit('RESET_LOADED_COMPANY_COLLABORATORS')
      const response = await fetchServer(uris._post.list_collaborators, { body: data })
      commit('LOAD_COMPANY_COLLABORATORS', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async searchCollaborator({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.search_collaborator, { body: data })
      commit('SEARCH_COLLABORATOR', response)
    } catch (error) {}
  },
  resetSearchCollaborator({ commit }) {
    commit('RESET_SEARCH_COLLABORATOR')
  },
  async setCollaboratorInfos({ commit }, data) {
    try {
      await fetchServer(uris._put.edit_collaborator, { method: 'PUT', body: data })
      commit('SET_COLLABORATOR_INFOS', data)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async loadCollaboratorAliasMail({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.get_alias_mail, { body: data })
      commit('LOAD_COLLABORATOR_ALIAS_MAIL', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async addContactPerson({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.add_contact_person, { body: data })
      commit('ADD_CONTACT_PERSON', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
