import {fetchServer} from "@/features/utils/fetch";
import i18n from '@/features/utils/i18n.ts'

const uris = {
    _post: {
        fund_kypay_account:  '/kypay/fund_account',
    }
}
const state = {
    kypay_amount : ""
};

const getters = {
    kypay_amount: state => state.kypay_amount,
};

const mutations = {

    async FUND_ACCOUNT(state , response) {
        await this.dispatch("addNotification", {
            type: 'success',
            message: i18n().global.t('olkypass.KypayPage.notifications.success.fund_account')

        })
    },
    async SET_KYPAY_AMOUNT(state, value) {
        state.kypay_amount = value;
    }

};

const actions = {
    async fund_account({commit}, data) {
        try {
            const response = await fetchServer(uris._post.fund_kypay_account, { body: data });
            // commit('FUND_ACCOUNT', response);
            if (response.status === 200) {
                const res = await response.json()
                return res.data;
            }  else {
                throw new Error(i18n().global.t('olkypass.KypayPage.notifications.error.fund_account'))
            }
        } catch (error) {
            if (error.response.data.message !== undefined) {
                this.dispatch("addNotification", {type: 'error', message: error.response.data.message})
            } else {
                this.dispatch("addNotification", {
                    type: 'error',
                    message: i18n().global.t('olkypass.KypayPage.notifications.error.fund_account')
                })
            }
            return null;
        }
    },
    set_kypay_amount({commit}, data) {
        commit('SET_KYPAY_AMOUNT', data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};