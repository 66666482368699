<template>
  <v-form ref="addressForm">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <label>{{ $t('olkypass.accountPage.addresses_section.input.tag') }}<sup>*</sup></label>
          <v-text-field
            v-model="address.Tag"
            :aria-disabled="disableLabel"
            :disabled="disableLabel"
            density="comfortable"
            variant="plain"
            :input-options="inputOptions"
            :rules="[requiredField('accountPage.addresses_section.input.tag')]"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <label
            >{{ $t('olkypass.accountPage.addresses_section.input.address_one') }}<sup>*</sup></label
          >
          <v-text-field
            v-model="address.Address1"
            density="comfortable"
            variant="plain"
            color="primary"
            :input-options="inputOptions"
            :rules="[requiredField('accountPage.addresses_section.input.address_one')]"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <label>{{ $t('olkypass.accountPage.addresses_section.input.address_two') }}</label>
          <v-text-field v-model="address.Address2" density="comfortable" variant="plain">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <label>{{ $t('olkypass.accountPage.addresses_section.input.city') }}<sup>*</sup></label>
          <v-text-field
            v-model="address.City"
            density="comfortable"
            variant="plain"
            :input-options="inputOptions"
            :rules="[requiredField('accountPage.addresses_section.input.city')]"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <label
            >{{ $t('olkypass.accountPage.addresses_section.input.zip_code') }}<sup>*</sup></label
          >
          <v-text-field
            v-model="address.ZipCode"
            density="comfortable"
            variant="plain"
            :input-options="inputOptions"
            :rules="[requiredField('accountPage.addresses_section.input.zip_code')]"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <label
            >{{ $t('olkypass.accountPage.addresses_section.input.country') }}<sup>*</sup></label
          >
          <v-autocomplete
            :model-value="address.Country ? parseInt(address.Country) : null"
            name="addressCountry"
            :items="
            countries.map(country => ({ value: country.id, title: country[countryLabel] }))
                  // Sort Alphabetically according to app language
                .sort((a, b) => new Intl.Collator(languageModule.state.currentLanguage).compare(a.title, b.title))
            "
            :menu-props="{ width: '250px' }"
            variant="outlined"
            autocomplete="off"
            class="selectInput"
            density="compact"
            :rules="[requiredField('accountPage.addresses_section.input.country')]"
            @update:model-value="address.Country = $event.toString()"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { mapGetters } from '../../store'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import languageModule from '@/app/olkypass/views/_deprecated/vue/store/modules/languageModule.js'
import _ from 'lodash'

export default {
  name: 'AddressFormDialog',
  components: {},
  props: ['address', 'disableLabel'],
  data: () => ({
    inputOptions: {
      required: true
    },
  }),
  methods: {},
  computed: {
    ...mapGetters(['countries', 'countryLabel']),
    languageModule: function() {
      return languageModule
    },
    requiredField: function () {
      const $this = this
      return function (fieldName) {
        return (v) =>
          !!v ||
          $this.$i18n.t('olkypass.' + fieldName) +
            ' ' +
            $this.$i18n.t('login.registerPage.validation.required_field')
      }
    }
  }
}
</script>

<style scoped></style>
