<template>
  <div class="card-manager">
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <button class="btn manager-buttons" @click="gotoRouter('CardBlockSubTabRouter')">
            <ion-icon slot="start" :icon="lockClosed" color="blue_olky" size="small"></ion-icon>
            {{ $t('olkypay.buttons.cardManagerPage.lock') }}
          </button>
        </ion-col>
        <ion-col size="6">
          <button class="btn manager-buttons" @click="gotoRouter('CardOppositionSubTabRouter')">
            <ion-icon slot="start" :icon="alertCircle" color="medium" size="small"></ion-icon>
            {{ $t('olkypay.buttons.cardManagerPage.opposition') }}
          </button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <h2>{{ $t('olkypay.labels.cardManagerPage.manage_card') }}</h2>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8">
          <label>
            <ion-icon slot="start" :icon="checkmarkCircle" color="medium" size="small"></ion-icon
            >&nbsp;{{ $t('olkypay.labels.cardManagerPage.card_status.label') }}</label
          >
        </ion-col>
        <ion-col class="text-end">
          <ion-chip v-if="currentCard.active" :outline="true" color="success">
            {{ $t('olkypay.labels.cardManagerPage.card_status.status.active') }}
          </ion-chip>
          <ion-chip
            v-if="!currentCard.active && !currentCard.cardBlocked"
            :outline="true"
            color="medium"
          >
            {{ $t('olkypay.labels.cardManagerPage.card_status.status.inactive') }}
          </ion-chip>
          <ion-chip v-if="currentCard.cardBlocked" :outline="true" color="danger">
            {{ $t('olkypay.labels.cardManagerPage.card_status.status.blocked') }}
          </ion-chip>
        </ion-col>
      </ion-row>
      <ion-row @click="openInfoModal('credit')">
        <ion-col size="9">
          <label>
            <ion-icon slot="start" :icon="cash" color="medium" size="small"></ion-icon>&nbsp;{{
              $t('olkypay.labels.cardManagerPage.credit_card')
            }}</label
          >
        </ion-col>
        <ion-col class="text-end">
          <ion-icon :icon="alertCircle" color="medium" size="large"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <span class="text-normal">{{ $t('olkypay.labels.cardManagerPage.card_balance') }}</span
          ><span class="font-weight-bold">
            €
            {{
              new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2,maximumFractionDigits: 2}).format(currentCard.balanceOperation / 100)
            }}</span
          >
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <button class="btn manager-buttons" @click="gotoRouter('CardCreditSubTabRouter')">
            <ion-icon slot="start" :icon="cash" color="blue_olky" size="small"></ion-icon>
            {{ $t('olkypay.buttons.cardManagerPage.credit_card') }}
          </button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <label>
            <ion-icon slot="start" :icon="lockClosed" color="medium" size="small"></ion-icon
            >&nbsp;{{ $t('olkypay.labels.cardManagerPage.display_code') }}</label
          >
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <button class="btn manager-buttons" @click="gotoRouter('CardDisplayCodeSubTabRouter')">
            <ion-icon slot="start" :icon="eye" color="blue_olky" size="small"></ion-icon>
            {{ $t('olkypay.buttons.cardManagerPage.display_code') }}
          </button>
        </ion-col>
      </ion-row>
      <ion-row class="mt-2" @click="openInfoModal('auto_leveling')">
        <ion-col size="9">
          <label>
            <ion-icon slot="start" :icon="cardOutline" color="medium" size="small"></ion-icon>
            {{ $t('olkypay.labels.cardManagerPage.auto_leveling') }}
          </label>
        </ion-col>
        <ion-col class="text-end">
          <ion-icon :icon="alertCircle" color="medium" size="large"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-list>
            <ion-list-header>
              <label>
                <span class="text-normal">
                  {{ $t('olkypay.labels.cardManagerPage.card_balance_used') }}
                </span>
                <span class="text-bold"
                  >€
                  {{
                    new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2,maximumFractionDigits: 2}).format(
                      (currentCard.balanceOperation - currentCard.balance) / 100
                    )
                  }}</span
                >
              </label>
            </ion-list-header>
            <ion-progress-bar
              :buffer="(currentCard.balanceOperation / currentCard.balance) * 100"
            ></ion-progress-bar>
            <ion-list-header>
              <label>
                <span class="text-normal">{{
                  $t('olkypay.labels.cardManagerPage.card_balance_remaining')
                }}</span>
                <span class="text-bold">
                  € {{ new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2,maximumFractionDigits: 2}).format(currentCard.balance / 100) }}</span
                >
              </label>
            </ion-list-header>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <button class="btn manager-buttons" @click="gotoRouter('CardAutoLevelingSubTabRouter')">
            <ion-icon slot="start" :icon="cardOutline" color="blue_olky" size="small"></ion-icon>
            {{ $t('olkypay.buttons.cardManagerPage.auto_leveling') }}
          </button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <info-modal ref="infoModal"></info-modal>
</template>

<script lang="ts">
import InfoModal from '../../components/InfoModal.vue'
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonListHeader,
  IonProgressBar,
  IonList,
  IonChip
} from '@ionic/vue'
import {
  lockClosed,
  alertCircle,
  cash,
  card,
  cardOutline,
  checkmarkCircle,
  eye
} from 'ionicons/icons'
import { mapGetters } from 'vuex'

export default {
  name: 'CardManager',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonListHeader,
    IonProgressBar,
    IonList,
    IonChip,
    InfoModal
  },
  data() {
    return {
      lockClosed,
      alertCircle,
      cash,
      card,
      cardOutline,
      checkmarkCircle,
      eye
    }
  },
  mounted() {},
  methods: {
    async openInfoModal(infoType) {
      let infoDialog = {}
      switch (infoType) {
        case 'credit':
          infoDialog = {
            type: 'info',
            title: this.$i18n.t('olkypay.notifications.info.cardManagerPage.credit_card.header'),
            content: this.$i18n.t('olkypay.notifications.info.cardManagerPage.credit_card.content'),
            button: this.$i18n.t('olkypay.buttons.cardManagerPage.infos_understand')
          }
          break
        case 'auto_leveling':
          infoDialog = {
            type: 'info',
            title: this.$i18n.t('olkypay.notifications.info.cardManagerPage.auto_leveling.header'),
            content: this.$i18n.t(
              'olkypay.notifications.info.cardManagerPage.auto_leveling.content'
            ),
            button: this.$i18n.t('olkypay.buttons.cardManagerPage.infos_understand')
          }
          break
      }
      await this.$refs.infoModal.openModal(infoDialog)
    },
    gotoRouter(router_name) {
      this.$router.push({ name: router_name })
    }
  },
  computed: {
    ...mapGetters('card', ['currentCard'])
  }
}
</script>

<style scoped></style>
