import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useTranslation } from "react-i18next";
import { EmailApiClient } from '../api-client/api-client';
import { EmailCodeResponse } from '../../types';

export const useCheckEmailMutation = (options?: UseMutationOptions<EmailCodeResponse, Error, string>) => {
    const { t, i18n } = useTranslation(["pass"])

    return useMutation<EmailCodeResponse, Error, string>({
        mutationFn: async (email: string) => {
            try {
                return await EmailApiClient.sendEmailCode(email, i18n.language);
            } catch (e: any) {
                throw new Error(t("olkypass.general.mail_already_exist"))
            }
        }, ...options
    })
}