import ApiService from './api.service'
import { type AxiosRequestConfig } from 'axios'

class ResponseError extends Error {
  errorCode: any
  errorMessage: any

  constructor(errorCode: any, message: string | undefined) {
    super(message)
    this.name = this.constructor.name
    if (message != null) {
      this.message = message
    }
    this.errorCode = errorCode
  }
}

const UserService = {
  saveCodePin: async function (inputData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: '/save_pin_code',
      data: inputData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  getSecurityInfos: async function () {
    const url = '/me/security_infos'
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url: url
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.message)
    }
  },
  saveFingerPrint: async function (inputData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: '/fingerprint',
      data: inputData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.message)
    }
  }
}

export { UserService, ResponseError }
