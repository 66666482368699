<template>
  <div class="header-app height-111">
    <ion-grid>
      <ion-row class="mt-4">
        <ion-col size="1" class="mt-2">
          <div class="circleIconMenu">
          <ion-icon :icon="menu" color="light" size="large" @click="gotoRouter('SuppliersRouter')" ></ion-icon>
          </div>
        </ion-col>
        <ion-col class="ion-margin-horizontal mt-1">
          <h1 class="font-weight-bold">{{ title }}</h1>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script lang="ts">
import {menu}  from 'ionicons/icons';
import {
  IonTitle,
  IonContent,
  IonPage,
  IonIcon,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  IonFab,
  IonFabButton,
  IonBackButton,
  IonToolbar,

} from '@ionic/vue';
export default {
  name: "HeaderAppWithLabel",
  components: {
    IonTitle,
    IonContent,
    IonPage,
    IonIcon,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonFab,
    IonFabButton,
    IonBackButton,
    IonToolbar,
  },
  props: [
    "title"
  ],
  data() {
    return {
      menu,
    };
  },
  methods: {
    gotoRouter(router_name) {
      this.$router.push({name: router_name});
    }
  }
}
</script>

<style scoped>

</style>