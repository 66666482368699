import config from '@/config'
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { KypayApiClient } from '../api-client/api-client'
import { balanceOfABI } from '../constants'
import {
  CreateWalletResponse,
  FeedKypayBalanceProps,
  FeedKypayBalanceResponse,
  GetUserWalletBalance as GetUserWalletBalanceResponse,
  GetWalletPolygon2LausanneResponse,
  GetWalletResponse,
  Kypay,
  SendKypayToUserWalletProps,
  SendKypayToUserWalletReponse,
  Wallet,
  WithdrawKypayBalanceProps,
  WithdrawKypayBalanceResponse
} from '../types'
import { getWeb3Client } from './use-web3'

export type UseKypayResponse = {
  kypayQuery: UseQueryResult<Kypay>
  createUserWallet: UseMutationResult<CreateWalletResponse, Error, string, unknown>
}

export function useKypay() {
  const { t } = useTranslation(['wallet'])
  const queryClient = useQueryClient()

  const kypayQuery = (options?: Omit<UseQueryOptions<Kypay>, 'queryKey'>) =>
    useQuery<Kypay>({
      queryKey: ['kypay'],
      queryFn: async () => {
        return await KypayApiClient.getKypay()
      },
      ...options
    })

  const userWalletQuery = (options?: UseQueryOptions<GetWalletResponse, null, Wallet>) =>
    useQuery<GetWalletResponse, null, Wallet>({
      queryKey: ['wallet'],
      queryFn: async () => {
        return await KypayApiClient.getUserWallet()
      },
      select: options?.select
        ? options?.select
        : (data) => {
            return data.data
          },
      ...options
    })

  const walletPolygon2LausanneQuery = (
    options?: UseQueryOptions<GetWalletPolygon2LausanneResponse, null>
  ) =>
    useQuery<GetWalletPolygon2LausanneResponse, null>({
      queryKey: ['wallet', 'polygon2Lausanne'],
      queryFn: async () => {
        return await KypayApiClient.getWalletPolygon2Lausanne()
      },
      ...options
    })

  const createUserWalletMutation = (
    options?: UseMutationOptions<CreateWalletResponse, Error, string>
  ) =>
    useMutation<CreateWalletResponse, Error, string>({
      mutationFn: async (wallet: string) => {
        return KypayApiClient.createUserWallet(wallet)
      },
      ...options
    })

  const sendKypayToUserWalletMutation = (
    options?: UseMutationOptions<SendKypayToUserWalletReponse, Error, SendKypayToUserWalletProps>
  ) =>
    useMutation<SendKypayToUserWalletReponse, Error, SendKypayToUserWalletProps>({
      mutationFn: async (sendData) => {
        return KypayApiClient.sendKypayToUserWallet(sendData)
      },
      ...options
    })

  const feedKypayBalanceMutation = (
    options?: UseMutationOptions<FeedKypayBalanceResponse, Error, FeedKypayBalanceProps>
  ) =>
    useMutation<FeedKypayBalanceResponse, Error, FeedKypayBalanceProps>({
      mutationFn: async (balanceData) => {
        const response = await KypayApiClient.feedKypayBalance(balanceData)
        if (!response || !response?.url) {
          toast.error(t('kypay.error.feed_kypay_balance'))
          throw new Error(t('kypay.error.feed_kypay_balance'))
        }

        const success = window.open(response.url, '_blank')
        if (!success) document.location = response.url

        return response
      },
      ...options
    })

  const withdrawKypayBalanceMutation = (
    options?: UseMutationOptions<WithdrawKypayBalanceResponse, Error, WithdrawKypayBalanceProps>
  ) =>
    useMutation<WithdrawKypayBalanceResponse, Error, WithdrawKypayBalanceProps>({
      mutationFn: async (balanceData) => {
        const response = await KypayApiClient.withdrawKypayBalance(balanceData)

        queryClient.invalidateQueries({
          queryKey: ['kypay']
        })

        return response
      },
      ...options
    })

  const getUserWalletBalanceQuery = (
    walletAddress: string,
    options?: Omit<UseQueryOptions<GetUserWalletBalanceResponse>, 'queryKey'>
  ) =>
    useQuery<GetUserWalletBalanceResponse>({
      queryKey: ['wallet', 'user', 'balance', walletAddress],
      queryFn: async () => {
        const web3Client = await getWeb3Client()

        const contract = new web3Client.eth.Contract(balanceOfABI, config.TOKEN_ADDRESS)
        const resultInWei = (await contract.methods.balanceOf(walletAddress).call()) as number
        const resultInEther = parseFloat(web3Client.utils.fromWei(resultInWei, 'ether'))

        return { balanceWei: resultInWei, balanceEther: resultInEther }
      },
      ...options
    })

  return {
    kypayQuery,
    userWalletQuery,
    getUserWalletBalanceQuery,
    createUserWalletMutation,
    sendKypayToUserWalletMutation,
    feedKypayBalanceMutation,
    withdrawKypayBalanceMutation,
    walletPolygon2LausanneQuery
  }
}
