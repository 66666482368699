import { A } from '@/atoms-react/a/A.react'
import Loader from '@/atoms-react/loader/Loader.react'
import { Base } from '@/atoms-react/text/Text.react'
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid'
import React, { useRef } from 'react'
import { twMerge } from 'tailwind-merge'

export type DragAndDropProps = {
  onValidated?: (files: FileList | null) => void
  isLoading?: boolean
} & React.ComponentPropsWithoutRef<'div'>

export const DragAndDrop = ({ className, isLoading, onValidated = () => { }, ...props }: DragAndDropProps) => {
  // const [files, setFiles] = useState<FileList | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    if (!event?.dataTransfer?.files) return

    // setFiles(event.dataTransfer.files)
    onValidated(event.dataTransfer.files)
  }

  return (
    <div
      className={twMerge(
        'flex flex-col border-2 border-slate-100 rounded-sm border-dashed h-auto items-center justify-center p-6',
        className
      )}
      onDragOver={(event) => handleDragOver(event)}
      onDrop={handleDrop}
      {...props}
    >
      {isLoading && <Loader />}
      {!isLoading && <>
        <DocumentArrowDownIcon className="h-12 w-12 min-w-12 text-slate-300 md:mb-3" />
        <Base className="text-center">
          <A
            onClick={() => {
              if (!inputRef.current) return
              inputRef.current.click()
            }}
          >
            Cliquez ici
          </A>
          <span> pour ajouter un document ou glisser-déposer</span>
        </Base>
        <input
          type="file"
          onChange={(event) => {
            // setFiles(event.target.files)
            onValidated(event.target.files)
          }}
          hidden
          ref={inputRef}
        ></input>
      </>}
    </div>
  )
}
