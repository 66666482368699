import LayoutBase from '@/atoms-react/layout-base/LayoutBase.react'
import config from '@/config'
import '@/features/utils/i18n-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Suspense, useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ToastPosition, Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'
import { InitialRouter } from './index.react'
import { useListenForShortcuts } from '@/features/utils/shortcuts'
import { RecoilRoot } from 'recoil'
import { GatewaysProvider } from './flow/components/gateways/gateways-provider/index.react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Tooltip } from '@/atoms-react/tooltip/Tooptip.react'

export const queryClient = new QueryClient()

const toastOptions = {
  success: {
    position: 'bottom-right' as ToastPosition
  },
  error: {
    position: 'bottom-right',
    className: '!text-white !bg-red-500'
  }
}

export const OlkyPass = () => {
  useListenForShortcuts()

  useEffect(() => {
    // @ts-expect-error Query client hack for Vue
    window.queryClient = queryClient
  }, [])

  return (
    <Suspense fallback={<></>}>
      <RecoilRoot {...({} as any)}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            {/* @ts-expect-error ToastPosition wrong type */}
            <Toaster toastOptions={toastOptions} />
            <Tooltip />
            {/* @ts-expect-error Children wrong type */}
            <GoogleReCaptchaProvider reCaptchaKey={config.RECAPTCHA_PUBLIC_KEY}>
              <GatewaysProvider>
                <LayoutBase>
                  <InitialRouter />
                  <Tooltip />
                </LayoutBase>
              </GatewaysProvider>
            </GoogleReCaptchaProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </RecoilRoot>
    </Suspense>
  )
}
