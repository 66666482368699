import classNames from 'classnames'
import React from 'react'
import { twMerge } from 'tailwind-merge'

export enum AnimationState {
  initial = 'initial',
  final = 'final',
  showingUp = 'showingUp',
  closing = 'closing',
  opening = 'opening'
}

export interface LayoutBaseProps
  extends React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>> {
  center?: boolean
}

function LayoutBase({ className, children, center }: LayoutBaseProps) {
  return (
    <div
      className={twMerge(
        classNames(
          'bg-white dark:bg-slate-950 flex flex-col w-screen h-dvh overflow-auto',
          center && 'justify-center items-center'
        ),
        className
      )}
    >
      {children}
    </div>
  )
}

export default LayoutBase
