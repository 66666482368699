import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import { computed, ref } from 'vue'
import { defaultInputClassName, errorInputClassName } from '.'

export type InputProps = {
  size?: 'sm' | 'md' | 'lg'
  hasError?: boolean
  multiline?: boolean
  inputClass?: string
  class?: string
  disabled?: boolean
  inputRef?: any
  pattern?: string
} & Partial<HTMLInputElement | HTMLTextAreaElement> &
  VModelProps<string>

export const Input = defineJsxComponent<InputProps>(
  (props, { emit, model }) => {
    const size = props.size || 'md'
    const disableErrorUi = ref(true)

    const computedInputClassName = computed(() => {
      let className = props.hasError
        ? errorInputClassName()
        : defaultInputClassName({ disableErrorUi: disableErrorUi.value })

      className += props.disabled ? ' opacity-75 pointer-events-none ' : ''

      if (!props.multiline) {
        if (size === 'lg') className += ' h-10 px-4'
        else if (size === 'sm') className += ' h-7 px-3'
        else className += ' h-9 px-3'
      }

      return `${className} ${props.class || ''}`
    })

    if (props.multiline) {
      return () => (
        <textarea
          ref={props.inputRef as any}
          class={computedInputClassName.value}
          onFocus={() => (disableErrorUi.value = true)}
          onBlur={() => (disableErrorUi.value = false)}
          onInput={(e: any) => {
            emit('update:modelValue', e.target.value)
          }}
          {...(props as any)}
        >
          {model()}
        </textarea>
      )
    }

    return () => (
      <input
        ref={props.inputRef as any}
        class={computedInputClassName.value}
        value={model()}
        onFocus={() => (disableErrorUi.value = true)}
        onBlur={() => (disableErrorUi.value = false)}
        onInput={(e: any) => {
          emit('update:modelValue', e.target.value)
        }}
        disabled={props.disabled}
        {...(props as any)}
      />
    )
  },
  {
    inheritAttrs: false
  }
)
