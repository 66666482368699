import classNames from 'classnames'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface ColumnProps extends React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>> {
  className?: React.ComponentPropsWithoutRef<'div'>['className']
  center?: boolean
}

function Column({ className, center, ...props }: ColumnProps) {
  return (
    <div
      className={twMerge(
        classNames('flex flex-col min-h-full', center && 'justify-center items-center', className)
      )}
      {...props}
    ></div>
  )
}

export default Column
