import {createStore} from 'vuex';
import {auth} from "./auth.store";
import {home} from "./home.store";
import {loader} from "./loader.store";
import {user} from "./user.store";
import {supplier} from "./supplier.store";
import {transaction} from "./transaction.store";
import {beneficiary} from "./beneficiary.store";
import {transfer} from "./transfer.store";
import {card} from "./card.store";

export const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        home,
        loader,
        user,
        supplier,
        transaction,
        beneficiary,
        transfer,
        card
    },
})