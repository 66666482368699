import Image from '@/atoms-react/image/Image.react'
import '../index.css'

export const LoginSpaceshipAnimation = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <div className="animate-float z-10 absolute top-0 left-0 right-0 h-full w-full max-w-3xl mx-auto">
        <Image
          src="/images/olkypass/signup-illustration/spaceship.svg"
          className={'z-10 absolute top-0 left-0 right-0 h-full w-full max-w-3xl mx-auto'}
          alt="Spaceship"
        />
        <Image
          src="/images/olkypass/signup-illustration/light.svg"
          className={
            'z-10 absolute top-0 left-0 right-0 h-full w-full max-w-3xl mx-auto animate-heavy-pulse'
          }
          alt="Spaceship"
        />
      </div>
      <Image
        src="/images/olkypass/signup-illustration/planets.png"
        className={'z-10 absolute top-0 left-0 right-0 h-full w-full max-w-3xl mx-auto'}
        alt="Planets"
      />
    </div>
  )
}
