export const data = {
  labels: ['Euro', 'Bitcoin', 'Ethereum', 'Polygon', 'Autres'],
  datasets: [
    {
      backgroundColor: ['#FF8080', '#F6A522', '#F9C74F', '#8AA4F3', '#D9D9D9'],
      data: [10000, 10000, 10000, 4073, 4070]
    }
  ]
}

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true,
        color: 'white', // couleur du texte de légende
        font: {
          size: 14, // taille de la police de légende
          family: 'Montserrat, sans-serif', // police de légende
        },
      },
      /*datalabels: {
        display: true,
        color: 'white', // Couleur du texte des étiquettes
        font: {
          weight: 'bold', // Poids de la police des étiquettes
        },
        formatter: function(value, context) {
          return value; // La valeur à afficher dans la légende
        },
      },*/
    }
  }
}
