<template>
  <ion-page id="CodePinSavePage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button default-href="/pay/supplier/more" :icon="caretBackSharp"></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.codePinSettingsPage.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
        <ion-row class="mt-3">
          <ion-col class="text-center">
            <ion-icon :icon="lockClosed" size="large" color="blue_olky"> </ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="p-0 mt-0">
          <ion-col class="mx-2">
            <h1 class="text-center">
              {{ $t('olkypay.labels.codePinSettingsPage.save_code_sub_page.title') }}
            </h1>
          </ion-col>
        </ion-row>
        <ion-row class="mx-2">
          <ion-col size="1" class="text-center">
            <ion-icon :icon="alertCircle" color="medium" size="large"></ion-icon>
          </ion-col>
          <ion-col class="mt-2">
            <a href="#" @click.prevent="goToRouter('MoreAuthSettingPinComplexitySubTabRouter')"
              >&nbsp;{{
                $t('olkypay.labels.codePinSettingsPage.save_code_sub_page.complexity_help')
              }}</a
            >
          </ion-col>
        </ion-row>

        <ion-row class="mt-4">
          <ion-col class="text-center">
            <label class="text-normal">
              {{
                $t('olkypay.labels.codePinSettingsPage.save_code_sub_page.create_code.first_part')
              }}
              <span class="text-blue-dark">
                {{
                  $t(
                    'olkypay.labels.codePinSettingsPage.save_code_sub_page.create_code.second_part'
                  )
                }}</span
              >
            </label>
          </ion-col>
        </ion-row>
        <ion-row class="mt-3 rowContainer">
          <ion-col class="colContainer">
            <v-otp-input
              ref="otpInput"
              v-model:value="code_pin"
              input-classes="otp-pin"
              separator=""
              :num-inputs="4"
              :should-auto-focus="false"
              input-type="password"
            />
          </ion-col>
        </ion-row>
        <ion-row class="mt-4">
          <ion-col class="text-center">
            <label class="text-normal">
              {{
                $t('olkypay.labels.codePinSettingsPage.save_code_sub_page.confirm_code.first_part')
              }}
              <span class="text-blue-dark">
                {{
                  $t(
                    'olkypay.labels.codePinSettingsPage.save_code_sub_page.confirm_code.second_part'
                  )
                }}</span
              >
            </label>
          </ion-col>
        </ion-row>
        <ion-row class="mt-3 rowContainer">
          <ion-col class="colContainer">
            <v-otp-input
              ref="otpInput"
              v-model:value="confirm_code_pin"
              input-classes="otp-pin"
              separator=""
              :num-inputs="4"
              :should-auto-focus="false"
              input-type="password"
            />
          </ion-col>
        </ion-row>
        <ion-row class="mt-4">
          <ion-col class="text-center">
            <button
              class="btn bg-gradient-info mb-0 ms-2 large-button"
              :disabled="code_pin === '' || code_pin !== confirm_code_pin"
              @click="proceedToSavePin"
            >
              {{ $t('olkypay.buttons.codePinSettingsPage.save_code') }}
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import VOtpInput from 'vue3-otp-input'
import AuthenticationProcessPopup from '../../../components/security/authenticationProcessPopup.vue'
import InfoModal from '../../../components/InfoModal.vue'
import {
  IonHeader,
  IonContent,
  IonPage,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  alertController,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonItem,
  IonToggle
} from '@ionic/vue'
import { caretBackSharp, fingerPrint, lockClosed, personCircle, alertCircle } from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
import { UserService } from '../../../services/user.service'

export default {
  name: 'CodePinSavePage',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonItem,
    IonToggle,
    VOtpInput,
    AuthenticationProcessPopup,
    InfoModal
  },
  data() {
    return {
      caretBackSharp,
      fingerPrint,
      lockClosed,
      personCircle,
      alertCircle,
      code_pin: '',
      confirm_code_pin: '',
      infoDialog: {
        isOpenInfoModal: false,
        title: 'olkypay.notifications.success.codePinSettingsPage.save_code_sub_page.header',
        content: 'olkypay.notifications.success.codePinSettingsPage.save_code_sub_page.message',
        button: 'olkypay.buttons.codePinSettingsPage.close_dialog'
      }
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    ...mapActions('user', ['setAuthenticationMethod']),
    goToRouter(router_name) {
      this.$router.push({ name: router_name })
    },
    resetData() {
      this.code_pin = ''
      this.confirm_code_pin = ''
    },
    async proceedToSavePin() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      }
    },
    async onSuccessAuthenticateUser() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.saveCodePin.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      await UserService.saveCodePin({ code_pin: this.code_pin })
        .then(async (res) => {
          this.setAuthenticationMethod('code_pin')
          this.stopLoader()
          const successDialog = {
            type: 'success',
            title: this.$i18n.t(
              'olkypay.notifications.success.codePinSettingsPage.save_code_sub_page.header'
            ),
            content: this.$i18n.t(
              'olkypay.notifications.success.codePinSettingsPage.save_code_sub_page.message'
            ),
            button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
          }
          const response_modal = await this.$refs.infoModal.openModal(successDialog)
          if (response_modal) {
            this.$router.push({ name: 'MoreTabRouter' })
            this.resetData()
          }
        })
        .catch(async (err: any) => {
          this.stopLoader()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t(
              'olkypay.notifications.error.codePinSettingsPage.save_code_sub_page.header'
            ),
            content: this.$i18n.t(
              'olkypay.notifications.error.codePinSettingsPage.save_code_sub_page.message'
            ),
            button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
          }
          await this.$refs.infoModal.openModal(failedDialog)
        })
        .finally(() => {
          this.stopLoader()
        })
    }
  },
  computed: {},
  mounted() {
    this.resetData()
  }
}
</script>
