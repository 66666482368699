import _ from 'lodash'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { defineVueComponent } from '../../jsx-utils'

export const Link = defineVueComponent({
  inheritAttrs: false,
  props: {
    class: { type: String, default: '' },
    href: { type: String, default: null },
    to: { type: String, default: null },
    onClick: { type: Function, default: null },
    noColor: { type: Boolean, default: false },
    target: { type: String, default: '_self' }
  },
  setup: (props: any, { slots }: any) => {
    const router = useRouter()

    const computedHref = computed(() => {
      return props.href || 'javascript:void(0);' // Using JavaScript URLs is not recommended, consider a different approach
    })

    const handleClick = (event: Event) => {
      if (props.onClick) props.onClick()
      if (props.to) router.push(props.to)
      if (props.to || props.onClick) event.preventDefault()
    }

    const linkClasses = computed(() => {
      const colors = props.noColor ? '' : 'text-secondary-500 hover:text-secondary-700'
      return `${colors} ${props.class || ''}`
    })

    return () => (
      <a
        href={computedHref.value}
        target={props.target}
        class={linkClasses.value}
        {..._.omit(props, 'target', 'class', 'href', 'onClick')}
        onClick={(e: any) => handleClick(e)}
      >
        {slots.default?.()}
      </a>
    )
  }
})
