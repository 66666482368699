<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    class="dialogKyc"
    :scrim="true"
    persistent
  >
    <v-card class="cardBoxPopup d-flex justify-space-around">
      <v-card-item class="pa-0">
        <template #append>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </template>
      </v-card-item>
      <v-card-title>
        <v-timeline direction="horizontal" class="timeLineBar" truncate-line="both" line-inset="12">
          <v-timeline-item :class="step >= 0 ? 'checked' : ''">
            <template #opposite>
              {{ $t('olkypass.accountPage.dialog.identity.step1') }}
            </template>
          </v-timeline-item>
          <v-timeline-item :class="step >= 2 ? 'checked' : ''">
            {{ $t('olkypass.accountPage.dialog.identity.step2') }}
          </v-timeline-item>
          <v-timeline-item :class="step >= 4 ? 'checked' : ''">
            <template #opposite>
              {{ $t('olkypass.accountPage.dialog.identity.step3') }}
            </template>
          </v-timeline-item>
        </v-timeline>
      </v-card-title>
      <!-- STEP 0 : Welcome page -->
      <v-card-text v-if="step === 0">
        <WelcomeKYCIdentity ref="welcomeKYCIdentity"></WelcomeKYCIdentity>
      </v-card-text>
      <v-card-actions v-if="step === 0">
        <v-row>
          <v-col class="d-flex justify-end align-center">
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              size="medium"
              @click="nextStep(step, checkDisabled)"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <!-- STEP 1 : Complete basic user info -->
      <v-card-text v-if="step === 1">
        <!-- SUB STEP -->
        <div v-if="sub_step === 1">
          <v-row>
            <v-col class="pa-2 ma-2 informationTitle">
              {{ $t('olkypass.accountPage.dialog.identity.informationTitle') }}
            </v-col>
          </v-row>
        <AccountInfoChild ref="accountInfoChild" :columns="2"></AccountInfoChild>
          <DialogConfirm ref="dialogConfirm"></DialogConfirm>
        </div>
        <div v-if="sub_step === 2">
          <v-row>
            <v-col class="pa-2 ma-0 informationTitle">
              {{ $t('olkypass.accountPage.dialog.identity.residenceAddressTitle') }}
            </v-col>
          </v-row>
          <AddressFormContent
            :address="residenceAddress"
            :disable-label="true"
          ></AddressFormContent>
          <DialogConfirm ref="dialogConfirm"></DialogConfirm>
        </div>
      </v-card-text>
      <v-card-actions v-if="step === 1">
        <v-row v-if="sub_step === 1">
          <v-col class="d-flex justify-space-between pa-0">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="prevStep(step, checkDisabled)"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.back') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :disabled="checkDisabled"
              size="medium"
              @click="openConfirmDialog(step, 'basic_info')"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="sub_step === 2">
          <v-col class="d-flex justify-space-between pa-0">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="prevSubStep(sub_step)"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.back') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :disabled="checkHasEmptyRequiredAddressFields"
              size="medium"
              @click="openConfirmDialog(step, 'residence_address')"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <!-- STEP 2 : Choice of type document to identify -->
      <v-card-text v-if="step === 2">
        <KYCIdentityTypes ref="kycIdentityTypes"></KYCIdentityTypes>
      </v-card-text>
      <v-card-actions v-if="step === 2">
        <v-row>
          <v-col class="d-flex justify-space-between pa-0">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="prevStep(step, checkDisabled)"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.back') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :disabled="checkDisabledAuthentification"
              size="medium"
              @click="setDocumentVerificationChoice(step)"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <!-- STEP 3 : Call Iframe KYC -->
      <v-card-text v-if="step === 3" class="px-0">
        <div class="iframe-container">
          <iframe
            class="kyc-iframe"
            width="100%"
            height="650px"
            :src="[sessionUrl]"
            :allow="allowSourcesAccess"
            loading="eager"
          >
          </iframe>
        </div>
      </v-card-text>
      <!-- STEP 4 : Return of KYC end session -->
      <v-card-text v-if="step === 4">
        <KYCIdentityEndSession ref="kycIdentityEndSession"></KYCIdentityEndSession>
      </v-card-text>
      <v-card-actions v-if="step === 4">
        <v-row>
          <v-col class="d-flex justify-end align-center pa-0">
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              size="medium"
              @click="destroyDialog"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.finish') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <!--    <loader></loader>-->
  </v-dialog>
</template>

<script>
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import Loader from '../Loader.vue'
import AccountInfoChild from '../Common/AccountInfoChild.vue'
import WelcomeKYCIdentity from '../KycSteps/WelcomeKYCIdentity.vue'
import KYCIdentityTypes from '../KycSteps/KYCIdentityTypes.vue'
import KYCIdentityEndSession from '../KycSteps/KYCIdentityEndSession.vue'
import DialogConfirm from '../DialogConfirm.vue'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import AddressFormContent from '../FormsDialog/AddressFormContent.vue'
import _ from 'lodash'
import config from '@/config'
import {Routes} from "@/features/router";
import {useRouter} from "vue-router";

export default {
  name: 'KYCIdentityDialogChild',
  components: {
    Loader,
    AccountInfoChild,
    DialogConfirm,
    WelcomeKYCIdentity,
    KYCIdentityTypes,
    KYCIdentityEndSession,
    AddressFormContent
  },
  mixins: [NotificationsMixins, ToolsMixins],
  data() {
    return {
      uris: {
        _post: {
          profile: '/user/profile/edit'
        }
      },
      editableFields: ['CountryOfCitizenship', 'ActivityCode', 'CSPCode'],
      panels: {
        basicInfo: {
          box_fields: [
            'FirstName',
            'LastName',
            'OtherFirstNames',
            'MaidenName',
            'DateOfBirth.date',
            'CountryOfCitizenship',
            'countryOfBirth',
            'StateOfBirth',
            'ActivityCode',
            'CSPCode'
          ],
          address_fields: ['Tag', 'Address1', 'City', 'ZipCode', 'Country']
        }
      },
      dialog: false,
      hasEmptyPersonalData: false,
      step: 0,
      sub_step: 1,
      /*sessionAlgoregUsed: '',*/
      api_config: {
        onBoardingUrl: config.API_VID_ALGOREG
      },
      access_token: '',
      sessionUrl: '',
      allowSourcesAccess:
        'camera ' +
        config.API_VID_ALGOREG +
        '; microphone ' +
        config.API_VID_ALGOREG +
        '; geolocation ' +
        config.API_VID_ALGOREG +
        ';',
      setCurrentEntityFunctionName: '',
      informationStep1: '/images/olkypass/informationStep1.png',
      validationStep3: '/images/olkypass/validationStep3.png',
      residenceAddress: {
        Tag: this.$i18n.t('olkypass.accountPage.dialog.identity.residenceAddressStaticLabel'),
      }
    }
  },
  methods: {
    prevStep(step, hasEmptyPersonalData) {
      if (this.step === 2 && !hasEmptyPersonalData) {
        this.step = step - 2
      } else {
        this.step = step - 1
      }
      if (this.step === 1) {
        this.sub_step = 1
      }
    },
    prevSubStep(sub_step) {
      if (!this.checkDisabled) {
        this.step = this.step - 1
      } else {
        this.sub_step = sub_step - 1
      }
    },
    nextStep(step, hasEmptyPersonalData) {
      if (this.step === 0 && !hasEmptyPersonalData && !this.hasEmptyResidenceAddress) {
        this.step = step + 2
      } else {
        this.step = step + 1
        if (!hasEmptyPersonalData && this.hasEmptyResidenceAddress) {
          this.sub_step = 2
        }
      }
    },
    async setDocumentVerificationChoice(step) {
      this.kycSession.senario = 'olkypass_kyc'
      if (this.kycSession.document.need_manual_validation) {
        this.kycSession.senario = 'olkypass_manual_kyc'
      }
      this.step = step + 1
      await this.prepareKycSessionData()
    },
    fillIFrameUrl() {
      this.sessionUrl =
        this.api_config.onBoardingUrl +
        '/?session_id=' +
        this.kycSession.sessionId +
        '&iframe_callback=1&show_consent=1&show_home=1&show_final=0&use_speech=0&powered_by=0&theme=' +
        (document.querySelector('.v-theme--myCustomDarkTheme') ? 'dark' : 'light')
    },
    async open() {
      this.step = 0
      this.dialog = true
      await store.dispatch('resetKycSession')
    },
    async internalEventListener(msg) {
      if (msg.data.event === 'reload') {
        window.location.reload()
      }
      if (msg.data.event === 'end_session') {
        await store.dispatch('playLoader')
        this.kycSession.sessionScoring = msg.data.content.success
        this.kycSession.AbstractEntity = this.currentUUID
        await store.dispatch('updateSession', this.kycSession)
        if (this.currentTreatedEntity === 'company') {
          this.setCurrentEntityFunctionName = 'setCurrentCompany'
        } else {
          this.setCurrentEntityFunctionName = 'setCurrentNaturalPerson'
        }
        await store.dispatch(this.setCurrentEntityFunctionName, {
          UUID: this.currentUUID
        })
        await store.dispatch('stopLoader')
        /*await this.destroyDialog();*/
        this.step = 4
        //this.nextStep(this.step, this.hasEmptyPersonalData);
      }
    },
    async destroyDialog() {
      const router = useRouter()
      await store.dispatch('playLoader')
      if (this.currentTreatedEntity === 'company') {
        this.setCurrentEntityFunctionName = 'setCurrentCompany'
      } else {
        this.setCurrentEntityFunctionName = 'setCurrentNaturalPerson'
      }
      await store.dispatch(this.setCurrentEntityFunctionName, {
        UUID: this.currentUUID
      })
      await store.dispatch('stopLoader')
      this.dialog = false
      this.sessionUrl = ''
      window.removeEventListener('message', this.internalEventListener)
      if (this.kycState.level >= 50) {
        // redirect to
        await router.push( Routes.dashboard)
      }
    },
    addWindowEventListener() {
      window.addEventListener('message', this.internalEventListener)
    },
    async prepareKycSessionData() {
      this.sessionUrl = ''
      store.dispatch('playLoader')
      // await store.dispatch("findLastIncomplete",{UUID: this.currentUUID});
      this.addWindowEventListener()
      await store.dispatch('prepareKycSession', {
        UUID: this.currentUUID,
        language: this.currentLanguage,
        sessionType: 'INFO',
        naturalPerson: this.naturalPerson,
        sessionSenario: this.kycSession.senario
      })
      await store.dispatch('createNewSession', {
        sessionId: this.kycSession.sessionId,
        AbstractEntity: this.currentUUID,
        UUID: this.currentUUID,
        sessionType: 'INFO',
        naturalPerson: this.naturalPerson,
        sessionSenario: this.kycSession.senario
      })
      this.fillIFrameUrl()
      store.dispatch('stopLoader')
    },
    async updateBasicInfo(step) {
      /*this.dialog = false;*/
      store.dispatch('playLoader')
      await fetchServer(this.uris._post.profile, { method: 'PUT', body: this.naturalPerson })
        .then((response) => {
          if (response) {
            this.displayNotification(
              'success',
              this.$i18n.t('olkypass.accountPage.notifications.success.profile.update')
            )
            this.step = step + 1
          }
        })
        .catch((error) => {
          if (error.response.data.message !== undefined) {
            this.displayNotification('error', error.response.data.message)
          } else {
            this.displayNotification(
              'error',
              this.$i18n.t('olkypass.accountPage.notifications.error.profile.load')
            )
          }
        })
        .finally(async () => {
          store.dispatch('stopLoader')
          await store.dispatch('setCurrentNaturalPerson')
        })
    },
    async checkAddressAvailability(step) {
      if (!this.residenceAddress.AbstractEntityUUID) {
        this.residenceAddress.AbstractEntityUUID = this.naturalPerson.UUID
      }
      await store.dispatch('playLoader')
      const objectToSend = _.cloneDeep(this.residenceAddress)
      objectToSend.UUID = this.currentUUID
      objectToSend.language = this.currentLanguage

      let uri = 'user/address/create_new'
      if (this.currentTreatedEntity === 'company') {
        uri = 'company/address/create_new'
      }
      await fetchServer(uri, { body: objectToSend })
        .then(async (response) => {
          this.residenceAddress.UUID = response.data.uuid
          await this.savePartialData(step)
        })
        .catch((error) => {
          this.displayNotification(
            'error',
            this.$i18n.t('olkypass.accountPage.notifications.error.address.create')
          )
        })
        .finally(() => store.dispatch('stopLoader'))
    },
    async savePartialData(step) {
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      await store.dispatch(setPartialDataFunctionName, {
        typeDto: 'AdresseDto',
        UUID: this.naturalPerson.UUID,
        Address: this.residenceAddress
      })
      if (this.currentTreatedEntity === 'company') {
        await store.dispatch('setCurrentCompany', { UUID: this.currentUUID })
      } else {
        await store.dispatch('setCurrentNaturalPerson')
      }

      this.displayNotification(
        'success',
        this.$i18n.t('olkypass.accountPage.notifications.success.profile.update')
      )
      this.step = step + 1
      await store.dispatch('stopLoader')
    },
    async openConfirmDialog(step, call_source) {
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.update.infos.header',
          'accountPage.dialog.confirm.update.infos.body'
        )
      ) {
        if (call_source === 'basic_info') {
          // when increment it will be on the first step
          if (this.hasEmptyResidenceAddress) {
            step = 0
            this.sub_step = 2
          }
          await this.updateBasicInfo(step)
        } else {
          await this.checkAddressAvailability(step)
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'kycSession',
      'currentTreatedEntity',
      'currentUUID',
      'currentLanguage',
      'naturalPerson',
      'kycState'
    ]),
    checkDisabled() {
      return this.checkCompletedBasicInfo(this.panels.basicInfo.box_fields, this.naturalPerson)
    },
    checkDisabledAuthentification() {
      let returnCheck = true
      if (
        (this.kycSession.document.type_id && !this.kycSession.document.need_other_name) ||
        (this.kycSession.document.need_other_name && this.kycSession.document.other_name)
      ) {
        returnCheck = false
      }
      return returnCheck
    },
    hasEmptyResidenceAddress() {
      return this.isEmptyObject(this.naturalPerson.Address)
    },
    checkHasEmptyRequiredAddressFields() {
      const result = this.checkCompletedBasicInfo(
        this.panels.basicInfo.address_fields,
        this.residenceAddress
      )
      return result
    }
  }
}
</script>

<style scoped>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

.iframe-container {
  /*padding-top: 45.25%; !* 16:9*!*/
  overflow: hidden;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  /*height: 100%;
  left: 0;
  position: absolute;
  top: 0;*/
  width: 100%;
}
</style>
