import _ from 'lodash'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import TextWithIcon from '../text-with-icon-row/TextWithIconRow.react'
import { ExclamationCircleIcon, InformationCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid'

export enum AlertType {
    Info,
    Danger,
    Warning,
    Success
}

export type AlertProps = {
    type?: AlertType
    title?: string
    description?: string
} & React.ComponentPropsWithoutRef<'div'>

export const Alert = ({
    className,
    children,
    title,
    type = AlertType.Info,
    ...props
}: AlertProps) => {

    let icon = InformationCircleIcon
    let divClasses = "bg-blue-100 border-blue-500"
    let iconColor = "text-blue-500"

    if (type === AlertType.Danger) {
        icon = ExclamationCircleIcon
        divClasses = "bg-red-100 border-red-500"
        iconColor = "text-red-500"
    } else if (type === AlertType.Warning) {
        icon = ExclamationCircleIcon
        divClasses = "bg-orange-100 border-orange-500"
        iconColor = "text-orange-500"
    } else if (type === AlertType.Success) {
        icon = CheckCircleIcon
        divClasses = "bg-green-100 border-green-500"
        iconColor = "text-green-500"
    }

    return (
        <div className={twMerge("p-4 rounded-sm border-2", divClasses, className, !title && "flex gap-2")} {...props}>
            <TextWithIcon iconColor={iconColor} suffixIcon={icon}>
                <span className="text-sm">{title}</span>
            </TextWithIcon>
            <div className={twMerge("flex-1", title && "ml-8")}>
                {children}
            </div>
        </div>
    )
}
