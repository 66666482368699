import { Base } from '@/atoms-react/text/Text.react'
import { AliasIBAN } from '@/features/user/types'
import { CreditCardIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { AliasCard } from '../alias-card/index.react'
import { useTranslation } from 'react-i18next'

type IbanCardProps = {
  iban: AliasIBAN
  isLoading?: boolean
  onClick?: () => void
  onDelete?: () => void
  onPublicClick?: () => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'>

export const IbanCard = ({
  iban,
  ...props
}: IbanCardProps) => { 
  const { t } = useTranslation(["pass"])
  return (
    <AliasCard tooltipDesignation={t('olkypass.aliasPublicPage.this_iban')} icon={CreditCardIcon} isPublic={iban?.Public} {...props} >
      <>
        <Base
          className={twMerge(
            'md:mr-3 font-normal text-ellipsis overflow-hidden break-normal'
          )}
          title={`${iban.BankInfoDto.Institution} ${iban.BankInfoDto.Institution}`}
        >
          {`${iban.BankInfoDto.Institution} ${iban.BankInfoDto.Institution}`}
        </Base>
        <Base
          className={twMerge(
            'md:mr-3 font-semibold text-ellipsis overflow-hidden break-normal'
          )}
          title={`${iban.Iban}`}
        >
          {`${iban.Iban}`}
        </Base>
        <Base
          className={twMerge(
            'md:mr-3 font-semibold text-gray-500 text-ellipsis overflow-hidden break-normal'
          )}
          title={`BIC ${iban.BIC}`}
        >
          {`BIC ${iban.BIC}`}
        </Base>
      </>
    </AliasCard >
  )
}
