import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { DynamicBlobAnimation } from '@/components/animations/dynamic-blob/index.react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../../flow-header.react'

interface DeclarativeWelcomeProps extends LayoutBaseProps {
  onValidated?: () => void
}

function DeclarativeWelcome({ onValidated = () => {} }: DeclarativeWelcomeProps) {
  const { t } = useTranslation(['pass'])

  function execute() {
    onValidated()
  }

  return (
    <>
      <Column>
        <div className="grow mb-12">
          <FlowHeader
            title={t('flow.id_control.signup.declarative_identity.label.welcome_title')}
            description={t('flow.id_control.signup.declarative_identity.label.welcome_description')}
          />

          <DynamicBlobAnimation src="/images/olkypass/signup-identity-bg.png" />
        </div>

        <div className="shrink-0">
          <Button shortcut="enter" onClick={() => execute()} icon={ChevronRightIcon}>
            {t('flow.id_control.input.start')}
          </Button>
        </div>
      </Column>
    </>
  )
}

export default DeclarativeWelcome
