<template>
  <v-card class="mx-auto elementCard" width="auto">
    <template #title>
      {{ $t('olkypass.mainPage.welcome') }}
    </template>
    <v-card-text class="elementData">
      <v-row>
        <v-col>
          <h1>
            {{ $t('olkypass.mainPage.bonjour') }} {{ naturalPerson.FirstName }}
            {{ naturalPerson.LastName }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2 v-if="kycState.level < 100 || kycState.decision === 'pending'">
            {{ $t('olkypass.mainPage.olkypass.cardTitle') }}
          </h2>
          <h2 v-if="kycState.level === 100 && kycState.decision !== 'pending'">
            {{ $t('olkypass.mainPage.olkypass.cardTitle2') }}
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p v-if="kycState.level < 100 || kycState.decision === 'pending'">
            {{ $t('olkypass.mainPage.olkypass.cardDesc') }}
          </p>
          <p v-if="kycState.level === 100 && kycState.decision !== 'pending'">
            {{ $t('olkypass.mainPage.olkypass.cardDesc2') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            v-if="kycState.level < 100 || kycState.decision === 'pending'"
            variant="flat"
            color="primary"
            height="auto"
            class="text-none validationBtn no-ui"
            size="medium"
            @click="ionRouter.navigate(Routes.account_identity, 'root')"
          >
            {{ $t('olkypass.mainPage.olkypass.cardBtn') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from '../../store'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { Routes } from '@/features/router'
import { useIonRouter } from '@ionic/vue'

export default {
  name: 'DashboardWelcomeBox',
  mixins: [ToolsMixins],
  data: () => ({
    Routes: Routes,
    ionRouter: useIonRouter()
  }),
  computed: {
    ...mapGetters(['kycState', 'loading', 'naturalPerson', 'currentUUID', 'currentLanguage'])
  },
  async mounted() {
    this.detectNaturalPersonIdentity(this.naturalPerson)
  }
}
</script>
