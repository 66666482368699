import { defineStore } from 'pinia'

export const createLoaderStore = (key: string, value = false) =>
  defineStore('loader-' + key, {
    state: (): { loading: boolean } => {
      return { loading: value || false }
    },
    actions: {
      set(loading: boolean) {
        this.loading = loading
      }
    }
  })
