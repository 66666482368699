import { Button } from '@/atoms-react/button/Button.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { formatNumber } from '@/features/utils/functions'
import { Themes } from '@/types/theme'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { ModalAddWallet } from '../modal-add-wallet/index.react'
import { ModalFeedBank } from '../modal-feed-kypay-bank/index.react'
import { ModalCryptoToBank } from '../modal-feed-kypay-crypto/index.react'
import { ModalBankToCrypto } from '../modal-transfer-funds/index.react'
import { ModalWithdrawBank } from '../modal-withdraw-kypay-bank/index.react'
import { BalanceCard } from './balance-card.react'
import { ModalKypayOnChainExplanation } from '../modal-kypay-onchain-explanation/index.react'
import { SolidAuthnModal } from '../../../identity/components/security/solid-authn-modal.react'

export const BalanceCardKypay = ({
  withButton,
  balanceType,
  allowedToKypay = true,
  className
}: {
  withButton?: boolean
  balanceType: 'euros' | 'onChain'
  allowedToKypay?: boolean
  className?: string
}) => {
  const { t } = useTranslation(['wallet'])
  const {
    kypayQuery,
    userWalletQuery,
    getUserWalletBalanceQuery,
    createUserWalletMutation,
    sendKypayToUserWalletMutation
  } = useKypay()

  const { data: kypay, isLoading: isKypayLoading, refetch: refetchKypay } = kypayQuery()
  const { data: wallet, isLoading: isWalletLoading, refetch: refetchWallet } = userWalletQuery()

  const {
    data: userBalance,
    isLoading: isLoadingUserBalance,
    refetch: refetchUserBalance
  } = getUserWalletBalanceQuery(wallet?.wallet_address ?? '', { enabled: !!wallet })

  const { mutateAsync: createUserWallet } = createUserWalletMutation()
  const { mutateAsync: sendKypayToUserWallet, isPending: isPendingSendKypayToUserWallet } =
    sendKypayToUserWalletMutation()

  const [modals, setModals] = useState({
    addWallet: false,
    addWalletSolidAuthn: false,
    feedBank: false,
    withdrawBank: false,
    withdrawBankSolidAuthn: false,
    cryptoToBank: false,
    bankToCrypto: false,
    bankToCryptoSolidAuthn: false,
    kypayOnChainDetails: false
  })

  const amountValue =
    balanceType === 'euros'
      ? formatNumber((kypay?.balance ?? 0) / 100)
      : userBalance && !isNaN(userBalance?.balanceEther)
        ? formatNumber(userBalance?.balanceEther)
        : 0 ?? null

  return (
    <>
      <BalanceCard
        disabled={!allowedToKypay}
        label={
          balanceType === 'euros'
            ? t('kypay.balance_card.off_chain_balance')
            : t('kypay.balance_card.on_chain_balance')
        }
        unit={balanceType === 'euros' ? '€' : 'KYPAY'}
        unitProps={{ className: balanceType === 'euros' ? 'text-3xl' : 'text-xl' }}
        color={balanceType === 'euros' ? 'text-blue-500' : ''}
        amount={amountValue}
        loading={
          balanceType === 'euros'
            ? isKypayLoading || isPendingSendKypayToUserWallet
            : isLoadingUserBalance || isPendingSendKypayToUserWallet
        }
        roundTo={balanceType === 'onChain' ? 0 : undefined}
        withButton={withButton}
        className={className}
        detailsModalIcon={balanceType === 'onChain'}
        onClickIcon={() => setModals((data) => ({ ...data, kypayOnChainDetails: true }))}
      >
        {withButton &&
          (balanceType === 'euros' ? (
            <div className="mb-2 mt-2 md:flex md:mt-0">
              <Button
                size="md"
                onClick={() => setModals((data) => ({ ...data, bankToCrypto: true }))}
                disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                className={'mt-4 mr-4 w-max shadow-none'}
              >
                {t('kypay.button.transfer_on_chain')}
              </Button>
              <Button
                size="md"
                theme={Themes.white}
                onClick={() => setModals((data) => ({ ...data, feedBank: true }))}
                disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                className={'mt-2 md:mt-4 mr-2 md:mr-4 w-max'}
              >
                {t('kypay.button.feed')}
              </Button>
              <Button
                size="md"
                theme={Themes.white}
                onClick={() => setModals((data) => ({ ...data, withdrawBank: true }))}
                disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                className={'mt-2 md:mt-4 w-max'}
              >
                {t('kypay.button.withdraw')}
              </Button>
            </div>
          ) : (
            <>
              {userBalance && !isLoadingUserBalance && (
                <div className={'flex mb-2 mt-2 md:mt-0'}>
                  <Button
                    size="md"
                    theme={Themes.white}
                    onClick={() => setModals((data) => ({ ...data, cryptoToBank: true }))}
                    disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                    className={'w-max mt-4'}
                  >
                    {t('kypay.button.transfer')}
                  </Button>
                </div>
              )}
              {!userBalance && !isLoadingUserBalance && (
                <div className="flex mb-2 mt-2 md:flex md:mt-0">
                  <Button
                    size="md"
                    theme={Themes.white}
                    onClick={() => setModals((data) => ({ ...data, addWallet: true })) }
                    disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                    className={'w-max'}
                  >
                    {t('kypay.button.add_user_wallet')}
                  </Button>
                </div>
              )}
            </>
          ))}
      </BalanceCard>
      <ModalAddWallet
        open={modals.addWallet}
        onClose={() => setModals((data) => ({ ...data, addWallet: false }))}
        onValidated={async (wallet) => {
          const response = await createUserWallet(wallet)
          if (response.success) {
            setModals((data) => ({ ...data, addWallet: false }))
            toast.success(t('kypay.success.create_wallet'))
          } else {
            toast.error(t('kypay.error.create_wallet'))
          }

          refetchWallet()
        }}
      />
      <ModalCryptoToBank
        open={modals.cryptoToBank}
        onClose={() => setModals((data) => ({ ...data, cryptoToBank: false }))}
        wallet={wallet?.wallet_address}
        onSetWallet={() => {
          setModals((data) => ({ ...data, addWallet: true }))
        }}
      />
      <ModalFeedBank
        open={modals.feedBank}
        onClose={() => setModals((data) => ({ ...data, feedBank: false }))}
      />
      <ModalWithdrawBank
        open={modals.withdrawBank}
        onClose={() => setModals((data) => ({ ...data, withdrawBank: false }))}
      />
      <ModalBankToCrypto
        wallet={wallet?.wallet_address ?? null}
        open={modals.bankToCrypto}
        onClose={() => setModals((data) => ({ ...data, bankToCrypto: false }))}
        onValidated={async (amount) => {
          setModals((data) => ({ ...data, bankToCrypto: false }))
          await sendKypayToUserWallet({ amount: amount * 100 })
          refetchKypay()
          refetchUserBalance()
        }}
        onSetWallet={() => {
          setModals((data) => ({ ...data, addWallet: true }))
        }}
      />
      <ModalKypayOnChainExplanation
        open={modals.kypayOnChainDetails}
        onClose={() => setModals((data) => ({ ...data, kypayOnChainDetails: false }))}
      />
      {modals.addWalletSolidAuthn && (
        <SolidAuthnModal
          onClose={() => setModals((data) => ({ ...data, addWalletSolidAuthn: false }))}
          onValidated={async () => {
            await setModals((data) => ({ ...data, addWalletSolidAuthn: false }))
            await setModals((data) => ({ ...data, addWallet: true }))
          } } />
      )}
      {modals.withdrawBankSolidAuthn && (
        <SolidAuthnModal
          onClose={() => setModals((data) => ({ ...data, withdrawBankSolidAuthn: false }))}
          onValidated={async () => {
            await setModals((data) => ({ ...data, withdrawBankSolidAuthn: false }))
            await setModals((data) => ({ ...data, withdrawBank: true }))
          } } />
      )}
      {modals.bankToCryptoSolidAuthn && (
        <SolidAuthnModal
          onClose={() => setModals((data) => ({ ...data, bankToCryptoSolidAuthn: false }))}
          onValidated={async () => {
            await setModals((data) => ({ ...data, bankToCryptoSolidAuthn: false }))
            await setModals((data) => ({ ...data, bankToCrypto: true }))
          } } />
      )}
    </>
  )
}
