import { store as OlkypayStore } from '@/app/olkypay/store'
import router from '@/features/router'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { FingerprintAIO } from '@ionic-native/fingerprint-aio'
import { IonicVue } from '@ionic/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import { init as initI18n } from './features/utils/i18n'
import OlkyPassDeprecatedStore from '@/app/olkypass/views/_deprecated/vue/store/index'
import OlkyPassDeprecatedVuetify from '@/app/olkypass/views/_deprecated/vue/vuetify.js'
import ToastPlugin from 'vue-toast-notification'
import config from '@/config'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import 'animate.css'

import '@ionic/vue/css/core.css'
import '@ionic/vue/css/display.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/typography.css'
import './main.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vue-toast-notification/dist/theme-sugar.css'

import '@/sentry'

import initReactFastclick from 'react-fastclick'
initReactFastclick()

const app = createApp(App)
  .use(ToastPlugin)
  .use(IonicVue)
  .use(router)
  .use(initI18n())
  .use(createPinia())
  .use(OlkyPassDeprecatedVuetify)
  .provide('olkypass-store', OlkyPassDeprecatedStore)
  .use(OlkypayStore)
  .provide('olkypass-store', OlkyPassDeprecatedStore)
  .use(VueReCaptcha, {
    siteKey: config.RECAPTCHA_PUBLIC_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: false
    }
  })

if (Capacitor.isNativePlatform()) {
  // app.use(FingerprintAIO);
  app.config.globalProperties.$fingerprintAIO = FingerprintAIO
}

if (Capacitor.isPluginAvailable('SplashScreen')) {
  SplashScreen.show({
    showDuration: 10000,
    autoHide: true
  })
}

router.isReady().then(() => {
  app.mount('#app')
})
