import { useEffect, useState } from 'react'
import './index.css'
import { twMerge } from 'tailwind-merge'

export const Logo = (props: {
  className?: string
  loading?: boolean
  init?: boolean
  color?: string
  potato?: string
  potatoColor?: string
}) => {
  const [init, setInit] = useState(props.init)

  useEffect(() => {
    setTimeout(() => {
      setInit(false)
    }, 2000)
  }, [])

  return (
    <div
      style={{ position: 'relative', aspectRatio: '1/1' }}
      className={twMerge(props.className, 'animated-logo')}
    >
      {!props.potato && (
        <div
          style={{ position: 'absolute', top: '12%', left: '16%' }}
          className={(init ? 'init ' : '') + (props.loading ? 'loading ' : '')}
        >
          <svg viewBox="0 0 49 48" fill="none" style={{ width: '100%', height: '100%' }}>
            <defs>
              <mask id="masko">
                <path
                  id="path-masko"
                  className="masks"
                  d="M7.5 20.5C5.5 21.3333 1.7 24 2.5 28C3.5 33 7 34 9 34C11 34 15 32.5 15.5 28C16 23.5 13 21 11.5 20.5C9 19.6667 5.5 20.5 5.5 24.5"
                />
              </mask>
              <mask id="maskl">
                <path id="path-maskl" className="masks" d="M16.5 0L25 42" />
              </mask>
              <mask id="maskk">
                <path
                  id="path-maskk"
                  className="masks"
                  d="M23 0L26 15L28 29.5C27.1667 26 25.5 14.5 28 11.5C30.5 7.5 33 2 33.5 3C34.75 5.5 27.3563 13.7138 29.5 15C32 16 36.6667 17.5 38.5 18.5"
                />
              </mask>
              <mask id="masky">
                <path
                  id="path-masky"
                  className="masks"
                  d="M34.5 8.5C36 9.5 41 13 42.5 12.5C44.5 11.8333 46 2.5 47 3C48 3.5 34.5 48 34.5 48"
                />
              </mask>
            </defs>
            <path
              d="M11.9229 18.7585C7.80533 17.0379 1.77917 19.3839 0.399386 24.1572C-1.30796 30.0639 3.58155 36.1606 8.8995 35.9478C12.8467 36.0327 15.1221 34.6736 16.3107 31.2095C16.7901 29.8122 17.019 28.253 16.9919 26.7745C16.9245 23.1389 15.2309 20.141 11.9229 18.7585ZM10.3462 31.9597C10.0202 32.051 9.67619 32.094 9.31996 32.0866C9.29554 32.0866 9.27165 32.0882 9.24723 32.0882C6.61029 32.0882 4.47292 29.9508 4.47292 27.3139C4.47292 27.2794 4.47504 27.2454 4.47557 27.2109C4.46867 26.9704 4.48301 26.7304 4.52282 26.4926C4.55521 26.2993 4.60724 26.0939 4.67572 25.8858C5.26713 23.7802 7.08969 21.9619 9.24776 21.9619C11.8847 21.9619 14.0221 24.6764 14.0221 27.3133C14.0221 29.5717 12.4533 31.4628 10.3467 31.9592L10.3462 31.9597Z"
              fill={props.color || '#00AFEB'}
              mask="url(#masko)"
            />
            <path
              d="M25.744 38.1453C25.0936 34.1132 24.4868 29.8501 23.7016 25.6205C22.857 21.0739 21.8419 16.5592 20.9065 12.0297C20.2291 8.75034 19.5904 5.46252 18.8721 2.19223C18.5997 0.953124 17.7482 0.236423 16.9412 0.44931C15.8879 0.726967 15.7499 1.61409 15.7207 2.52881C15.7154 2.68861 15.7074 2.85 15.7228 3.00821C15.9405 5.23052 16.0339 7.4746 16.4077 9.6709C17.5295 16.2699 18.7277 22.8572 19.9636 29.436C20.5078 32.3336 21.1586 35.2148 21.8812 38.0731C21.9529 38.3576 22.0516 38.638 22.1663 38.914C23.2679 41.9544 24.0775 41.6741 24.5494 41.5536C24.5792 41.5557 24.611 41.5462 24.645 41.5276C25.1886 41.3689 26.1745 40.8677 25.7445 38.1458L25.744 38.1453Z"
              fill={props.color || '#00AFEB'}
              mask="url(#maskl)"
            />
            <path
              d="M29.5858 26.6906C29.5247 23.3359 29.2943 20.5047 29.0018 18.1857C29.0374 17.9511 29.0825 17.7164 29.1404 17.4823C31.3553 18.2956 33.447 19.0606 35.5344 19.8352C38.0641 20.7075 38.7888 17.9293 38.225 16.8426C37.6612 15.7553 33.9481 14.3304 30.8323 12.8083C31.2778 11.9653 31.6223 11.3516 31.9308 10.7203C32.9463 8.64294 33.9561 6.56291 34.9521 4.4765C35.4081 3.52143 35.4315 2.5998 34.5013 1.89424C33.6609 1.25611 32.7462 1.45626 31.7619 2.50795C31.3855 2.90984 31.0781 3.3903 30.8069 3.87288C29.6782 5.88018 28.575 7.90182 27.3136 10.1878C26.9106 8.3191 26.5783 6.70996 26.2141 5.10826C25.9142 3.7874 25.6238 2.46177 25.241 1.16373C24.9793 0.276614 24.1569 -0.17252 23.3675 0.0626651C22.8058 0.229896 22.1687 0.915277 22.0153 1.48917C21.7971 2.30356 21.9256 3.23581 22.028 4.10381C22.1682 5.29195 22.3843 6.47531 22.6338 7.64646C23.9143 13.6567 25.1953 19.6664 26.53 25.6649C26.573 25.8576 26.6255 26.0482 26.6829 26.2383L26.6813 26.2394C27.2127 28.4171 27.6698 29.6254 28.8203 29.404C29.4064 29.2909 29.5784 28.3427 29.5927 27.3112L29.5943 27.3123C29.5943 27.2942 29.5938 27.2762 29.5932 27.2581C29.5948 27.0691 29.5916 26.8785 29.5847 26.6901L29.5858 26.6906Z"
              fill={props.color || '#00AFEB'}
              mask="url(#maskk)"
            />
            <path
              d="M48.6635 2.8297C48.4347 2.23617 48.3104 1.64316 47.3076 1.49026C46.2479 1.32834 45.5195 2.19847 45.1415 2.88916L42.8353 11.2459L37.3847 8.05209C34.8608 7.1639 34.1186 9.93728 34.6755 11.0277C35.2181 12.09 38.7485 13.4927 41.8022 14.9898L33.7709 44.0938C33.5893 44.913 33.3733 45.7406 33.5692 46.564C33.9222 48.0479 35.6688 48.4949 36.7094 47.3694C37.3905 46.6325 37.7558 45.7003 38.0382 44.7436C39.4387 40.0006 40.7834 35.2396 42.0364 30.4557C43.4108 25.2079 44.6478 19.9244 45.9756 14.6643C46.8149 11.3394 47.728 8.03297 48.5663 4.708C48.7192 4.10119 48.8711 3.3675 48.664 2.83023L48.6635 2.8297Z"
              fill={props.color || '#00AFEB'}
              mask="url(#masky)"
            />
          </svg>
        </div>
      )}
      {!!props.potato && (
        <div
          style={{ position: 'absolute', top: '42%', left: '13%', width: '26px' }}
          className="(init ? 'init ' : '') + (loading ? 'loading ' : '')"
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '11px'
            }}
            className="text-white font-bold"
          >
            {props.potato}
          </div>
          <svg
            width="100"
            height="100"
            style={{ width: '100%', height: '48%' }}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.9702 0.669231C10.0084 -1.40415 2.74679 1.42284 1.08412 7.17468C-0.973258 14.2924 4.91869 21.6391 11.3269 21.3825C16.0833 21.4849 18.8252 19.8472 20.2576 15.6729C20.8353 13.9891 21.111 12.1102 21.0784 10.3286C20.9971 5.94767 18.9564 2.3351 14.9702 0.669231Z"
              fill={props.potatoColor || props.color || '#00AFEB'}
            />
          </svg>
        </div>
      )}
      <div
        style={{ position: 'absolute', top: '20%' }}
        className={(init ? 'init ' : '') + (props.loading ? 'loading ' : '')}
      >
        <div>
          <svg
            viewBox="0 0 23 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '40%', height: 'auto', overflow: 'visible' }}
          >
            <path
              id="redhair"
              d="M5.18148 11.4545C6.94829 12.7196 7.61668 14.1376 6.87131 15.038C6.07019 16.0059 3.86593 15.6608 1.96109 14.3091C0.687476 13.405 0.0249237 12.3634 0.714021 11.1434C1.38772 9.9505 3.32389 10.1246 5.18201 11.455L5.18148 11.4545Z"
              fill={props.color || '#DD1322'}
              className="hairscale"
              transform-origin="7px 15px"
            />
            <path
              id="greenhair"
              d="M12.1033 9.98898C12.2669 11.3098 11.4833 12.0865 10.1497 11.8593C9.62143 11.7691 9.1054 11.587 8.69555 11.1713C6.80823 9.259 5.40508 7.03722 4.51 4.51389C4.2488 3.77754 4.21694 2.95625 5.00957 2.40731C5.86112 1.81696 6.69356 2.16257 7.42141 2.60109C8.82562 3.4468 11.909 8.41647 12.1033 9.98951V9.98898Z"
              fill={props.color || '#C2C925'}
              className="hairscale"
              transform-origin="11px 12px"
            />

            <path
              id="bluehair"
              d="M15.7945 3.88949C15.974 5.49916 15.4075 6.5912 14.3632 6.6496C13.2409 6.71278 12.1839 5.33883 11.9827 3.60971C11.8479 2.45343 12.1638 1.55782 13.3907 1.30458C14.5899 1.05665 15.6055 2.19701 15.7945 3.88949Z"
              fill={props.color || '#00AFEB'}
              className="hairscale"
              transform-origin="14.5px 6px"
            />

            <path
              id="orangehair"
              d="M17.6167 7.3722C17.6512 5.57779 17.8519 3.96388 18.4629 2.43385C18.7873 1.62212 19.2518 0.750922 20.2749 0.973366C21.334 1.20377 22.1367 2.08983 22.0539 3.14524C21.9339 4.67103 21.9482 6.23503 21.4375 7.72737C21.0723 8.79393 20.5164 9.4825 19.2816 9.4034C17.8524 9.31208 17.5976 8.35542 17.6167 7.37274V7.3722Z"
              fill={props.color || '#F39200'}
              className="hairscale"
              transform-origin="19px 9px"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
