import { useCallback } from 'react'
import { useUser } from './use-user'
import { NaturalEntityDataType } from '@/features/utils/types'

export const useSetAliasPublic = () => {
    const { useEditProfile, useUserProfile } = useUser()

    const { data: profile } = useUserProfile()

    const { mutateAsync: editProfile } = useEditProfile()

    const setAliasPublic = useCallback(
        (
            type:
                | NaturalEntityDataType.AliasIBANDto
                | NaturalEntityDataType.AliasMobileDto
                | NaturalEntityDataType.AliasMailDto,
            uuid: string,
            isPublic: boolean
        ) => {
            if (!profile) {
                return
            }
            if (type === NaturalEntityDataType.AliasIBANDto) {
                const iban = profile?.naturalPerson.AliasIBAN.find((iban) => iban.UUID === uuid)
                if (!iban) {
                    return
                }
                iban.Public = isPublic
                editProfile({
                    typeDto: NaturalEntityDataType.AliasIBANDto,
                    UUID: profile?.naturalPerson.UUID || '',
                    AliasIBAN: iban
                })
            } else if (type === NaturalEntityDataType.AliasMobileDto) {
                const mobile = profile?.naturalPerson.AliasMobile.find((mobile) => mobile.UUID === uuid)
                if (!mobile) {
                    return
                }
                mobile.Public = isPublic
                editProfile({
                    typeDto: NaturalEntityDataType.AliasMobileDto,
                    UUID: profile?.naturalPerson.UUID || '',
                    AliasMobile: mobile
                })
            } else if (type === NaturalEntityDataType.AliasMailDto) {
                const mail = profile?.naturalPerson.AliasEmail.find((mail) => mail.UUID === uuid)
                if (!mail) {
                    return
                }
                mail.Public = isPublic
                editProfile({
                    typeDto: NaturalEntityDataType.AliasMailDto,
                    UUID: profile?.naturalPerson.UUID || '',
                    AliasEmail: mail
                })
            }
        },
        [editProfile, profile]
    )

    return {
        setAliasPublic
    }
}
