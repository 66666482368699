import { Notice } from '@/atoms/notice'
import { SectionTitle, Subtitle } from '@/atoms/text/Text.tsx'
import { Section } from '@/components/layout/Section'
import { useCompany } from '@/features/company/hooks/use-company'
import { CompanyDTO } from '@/features/company/types'
import { defineJsxComponent } from '@/jsx-utils'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CompanyItem } from './company-item'

export const CompanyList = defineJsxComponent((props: any) => {
  const t = useI18n().t
  const { companyStore } = useCompany()
  const filteredCompanies = computed(() => {
    if (companyStore.companies) {
      return companyStore.companies.filter((company: CompanyDTO) => {
        const search = props.search
        if (null === search || undefined === search) {
          return true
        }
        const result =
          company.legal_name?.toLowerCase().includes(search.toLowerCase()) ||
          (company.legal_form as any)?.toLowerCase().includes(search.toLowerCase()) ||
          company.website_url?.toLowerCase().includes(search.toLowerCase()) ||
          company.comment?.toLowerCase().includes(search.toLowerCase())
        return result
      })
    } else {
      return []
    }
  })

  return () => (
    <div>
      <Section class="mb-4">
        <SectionTitle>{t('olkypass.companyPage.section.listCompanies.title')}</SectionTitle>
        <Subtitle class="mb-6">{t('olkypass.companyPage.section.listCompanies.subtitle')}</Subtitle>
        {filteredCompanies.value && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredCompanies.value.map((company) => (
                <CompanyItem key={(company as any).id} company={company} />
              ))}
            </div>
          </>
        )}
        {!filteredCompanies.value.length && (
          <>
            <Notice type="info" class="mt-4">
              {t('olkypass.companyPage.help.no_data_found')}
            </Notice>
          </>
        )}
      </Section>
    </div>
  )
})
