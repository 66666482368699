import { MenuItem } from '@/app/olkypass/views/_layout/index.react'
import Avatar from '@/atoms-react/avatar/Avatar.react'
import { Modal } from '@/atoms-react/modal/index.react'
import { Base, Info } from '@/atoms-react/text/Text.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { Routes } from '@/features/router'
import { ArrowPathIcon, BuildingOffice2Icon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export const AccountSelector = ({ smallVersion }: { smallVersion: boolean }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation(['atoms'])
  const { id, companies, company, change } = useCompanyContext()

  const { user: userQuery } = useAuth()
  const user = userQuery.data

  const [switchCompany, setSwitchCompany] = useState(false)

  const fullName = [user?.naturalPerson.FirstName, user?.naturalPerson.LastName]
    .filter(Boolean)
    .join(' ')

  // This will make sure we get back to personal account when we exist companies ready pages
  // Fixme: When all pages are ready, we can remove this or replace it by something more eleganto
  useEffect(() => {
    if (
      id &&
      location.pathname !== Routes.dashboard &&
      location.pathname !== Routes.account_identity &&
      location.pathname !== Routes.kycoin &&
      location.pathname !== Routes.kypay &&
      location.pathname !== Routes.support &&
      location.pathname !== Routes.companies &&
      location.pathname !== Routes.companies_add
    ) 
      change(null)
      setSwitchCompany(false)
  }, [location.pathname])
  useEffect(() => {
    if (
      id &&
      location.pathname !== Routes.dashboard &&
      location.pathname !== Routes.kycoin &&
      location.pathname !== Routes.kypay
    )
      navigate(Routes.dashboard)
  }, [id])

  return (
    <div className={twMerge('w-full group', smallVersion ? '' : 'flex grow')}>
      <Modal
        open={switchCompany}
        closable={false}
        onClickOutside={() => setSwitchCompany(false)}
        positionWrapperClassName="sm:text-left"
        className="sm:align-top sm:m-2 sm:p-3 sm:max-w-80"
      >
        <div className="p-3 pt-1 pb-4">
          <AccountSelectorCard
            onClick={() => setSwitchCompany(!switchCompany)}
            title={company?.legal_name || fullName}
            pro={company ? true : false}
            smallVersion={false}
            description={
              company ? (
                <>{t('account_selector.pro_account')}</>
              ) : (
                t('account_selector.personal_account')
              )
            }
            id={id}
            avatar={undefined}
          />
        </div>
        <div className="space-y-2">
          {company &&
            <div onClick={() => setSwitchCompany(false)}>
              <AccountSelectorCard
                title={fullName}
                description={t('account_selector.personal_account')}
                smallVersion={false}
                id={null}
                avatar={undefined}
                selectable
              />
            </div>
          }
          {(companies || []).filter(c => company?.id !== c.id).map((c) => (
            <div key={c.id} onClick={() => setSwitchCompany(false)}>
              <AccountSelectorCard
                key={c.id}
                title={c.legal_name}
                description={t('account_selector.pro_account')}
                pro
                smallVersion={false}
                id={c.identity_uuid || null}
                selectable
              />
            </div>
          ))}
          <div className="p-3 py-1">
            <MenuItem
              smallVersion={false}
              icon={(p) => <BuildingOffice2Icon {...p} />}
              to={Routes.companies}
            >
              {t('account_selector.companyPage')}
            </MenuItem>
          </div>
        </div>
      </Modal>

      <AccountSelectorCard
        onClick={() => setSwitchCompany(true)}
        title={company?.legal_name || fullName}
        description={
          company ? t('account_selector.pro_account') : t('account_selector.personal_account')
        }
        pro={company ? true : false}
        smallVersion={smallVersion}
        id={id}
        avatar={undefined}
      />
    </div>
  )
}

const AccountSelectorCard = ({
  pro,
  title,
  description,
  avatar,
  id,
  smallVersion,
  onClick,
  selectable
}: {
  pro?: boolean
  title: string
  description: string | ReactNode
  avatar?: string | null
  id: string | null
  smallVersion?: boolean
  onClick?: () => void
  selectable?: boolean
}) => {
  const { id: selectedId, change } = useCompanyContext()
  const selected = id === selectedId

  return (
    <div
      onClick={onClick || (() => change(id))}
      className={twMerge(
        'flex grow w-full justify-center items-center cursor-pointer',
        selectable && 'p-2 rounded-sm hover:bg-slate-100 dark:hover:bg-slate-800',
        selectable &&
        selected &&
        'bg-blue-100 hover:bg-blue-200 dark:bg-blue-900 dark:hover:bg-blue-800'
      )}
    >
      <div
        className={twMerge(
          'w-12 h-12 flex items-center justify-center shrink-0 relative',
          selectable ? '' : 'rounded-full border-2 border-blue-500'
        )}
      >
        <Avatar size={10} src={avatar} fallback={title} />
        {!selectable && (
          <div className="absolute border-2 border-white dark:border-slate-900 -right-1 -bottom-1 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center">
            <ArrowPathIcon className="text-white w-3 h-3 group-has-[:hover]:rotate-180 transition-all" />
          </div>
        )}
      </div>
      <div className={twMerge("ml-3 overflow-hidden grow transition-all overflow-hidden opacity-100", smallVersion && "ml-0 opacity-0")}>
        <Base className="overflow-hidden w-full text-ellipsis whitespace-nowrap -mb-1 font-semibold">
          {title}
        </Base>
        <Info className="overflow-hidden w-full text-ellipsis whitespace-nowrap flex items-center mt-1">
          {pro && <BuildingOffice2Icon className="inline-block w-4 h-4 mr-1" />}
          {description}
        </Info>
      </div>
      {selectable && selected && <CheckCircleIcon className={'ml-4 text-blue-500 w-6 h-6'} />}
    </div>
  )
}
