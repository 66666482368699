<template>
  <Doughnut :data="data" :options="options" />
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, LinearScale, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
import * as chartConfig from './chartConfig.ts'
/*import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';*/

ChartJS.register(ArcElement, Tooltip, LinearScale, Legend/*, ChartJSPluginDatalabels*/)

export default {
  name: 'DashboardOlkyWalletChart',
  components: {
    Doughnut
  },
  data() {
    return chartConfig
  }
}
</script>