import { ConfirmationResponse } from '@/features/register/types'
import { fetchServer } from '@/features/utils/fetch'
import { AddressCreate } from '../../types'
import { AddressConverters } from '../converters'

export class AddressApiClient {
  static async create(userUUID: string, address: AddressCreate): Promise<ConfirmationResponse> {
    const response = await fetchServer('/user/address/create_new', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: AddressConverters.Create_FullDTO(userUUID, address)
    })

    if (!response.ok) throw new Error()

    const res = await response.json()
    return res.data || { existStatus: false }
  }
}
