import { defineJsxComponent } from '@/jsx-utils'

export const Section = defineJsxComponent<{
  class?: string
}>((props, { slots }) => {
  return () => (
    <div class={'w-full rounded-md bg-white dark:bg-slate-990 sm:p-6 p-4 shadow-md ' + props.class}>
      {slots.default?.()}
    </div>
  )
})
