import { WebAuthnRegisterApi } from "@/features/auth/api-client/webauthn-register-api-client"
import { UseMutationOptions, useMutation } from "@tanstack/react-query"

export const useSecurity = () => {
    const newWebauthn = (options?: UseMutationOptions<any, Error, string>) => {
        return useMutation<any, Error, string>({mutationFn: async (deviceName: string) => {
            const challenge = await WebAuthnRegisterApi.registerWebAuthnUser(deviceName)
            return challenge
        }, ...options})
    }

    return { newWebauthn }
}