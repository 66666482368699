import { Button } from '@/atoms-react/button/Button.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrencyInput, formatNumber } from '@/features/utils/functions.ts'

export const ModalFeedBank = ({ ...props }: ModalProps) => {
  const { t } = useTranslation(['wallet'])
  const [amountString, setAmountString] = useState<string>('0,00')
  const [amount, setAmount] = useState<number | null>(null)

  const { feedKypayBalanceMutation } = useKypay()
  const { mutateAsync: feedKypayBalance } = feedKypayBalanceMutation()
  const resetInputs = () => {
    setAmount(0)
    setAmountString(formatNumber(0))
  }
  return (
    <Modal
      className="sm:w-full w-full max-w-lg"
      {...props}
      onClose={() => {
        resetInputs()
        props.onClose?.()
      }}
    >
      <ModalContent title={t('kypay.modal_deposit_funds.label.choice_bank_card')}>
        <div className={'mt-4 opacity-1'}>
          <InputLabel
            value={amountString}
            onChange={(value: string) => {
              const formattedValue = formatCurrencyInput(value)
              const parsed = parseFloat(formattedValue)
              if (!isNaN(parsed)) {
                setAmount(parsed)
                setAmountString(formatNumber(parsed))
              }
            }}
            suffixIcon={() => <span>€</span>}
            type="text"
            min="0.00"
            max="10000.00"
            step="0.01"
            label={t('kypay.modal_transfer_fund.input.amount_label')}
            placeholder={t('kypay.modal_transfer_fund.input.amount_placeholder')}
          />
          <Button
            disabled={!amount || !amountString}
            className={'mt-6'}
            onClick={() => {
              if (amount) {
                feedKypayBalance({ amount: amount * 100, description: 'Alimenter mon solde' })
                resetInputs()
                props.onClose?.()
              }
            }}
          >
            {t('kypay.button.feed')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
