import { Subtitle, Title } from '@/atoms/text/Text.tsx'
import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'
import { FooterView } from './components/FooterView'
import { FormResetPasswordView } from './components/FormResetPasswordView'
import { LayoutView } from './components/LayoutView'

type Props = { id: string; token: string; language: string } & VModelProps<null>

export const ResetPasswordView = defineJsxComponent<Props>((props) => {
  const { t } = useI18n()
  return () => (
    <LayoutView>
      <Title>{t('login.forgetPasswordPage.label.header_card')}</Title>
      <Subtitle class="mt-3">{t('login.forgetPasswordPage.label.new_pwd')}</Subtitle>
      <FormResetPasswordView id={props.id} token={props.token} language={props.language} />
      <FooterView />
    </LayoutView>
  )
})
