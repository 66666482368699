import { AnimatedHeight } from '@/atoms-react/animated-height/AnimatedHeight.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import RadioButton from '@/atoms-react/radio-button/index.react'
import { Info, SectionTitleSmall } from '@/atoms-react/text/Text.react'
import { CompanyLinkNPLE } from '@/features/company/types'
import { FormFieldGroup } from '@/features/utils/types'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

type FormCompanyLinkNPProps = {
  data: Partial<CompanyLinkNPLE>
  fields?: {
    link?: FormFieldGroup<CompanyLinkNPLE>
  }
  onChange: (link: CompanyLinkNPLE, valid: boolean) => void
} & React.ComponentPropsWithoutRef<'div'>

export function isCompanyLinkNPLEValid(_data: Partial<CompanyLinkNPLE>) {
  return true
}

export const FormCompanyLinkNPLE = ({
  data,
  onChange: _onChange = () => {},
  ...props
}: FormCompanyLinkNPProps) => {
  const { t } = useTranslation(['pass'])

  const cardClassName =
    'w-full 2xl:w-3/4 pt-0 self-center border-2 border-slate-50 rounded-sm text-black dark:text-white dark:border-slate-700'

  const onChange = (link: Partial<CompanyLinkNPLE>) => {
    const valid = !!isCompanyLinkNPLEValid(link)
    _onChange(link as CompanyLinkNPLE, valid)
  }

  return (
    <div className={twMerge('p-4', cardClassName, props.className)} {..._.omit(props, 'className')}>
      <SectionTitleSmall className="block mt-4">
        {t('business.collaborator.beneficiary.title')}
      </SectionTitleSmall>

      <RadioButton
        id={'bene_co'}
        className="mb-4"
        value={data.bene_co ? '1' : '0'}
        onChange={(output) => onChange({ ...data, bene_co: output === '1' ? 1 : 0 })}
        listOfChoices={[
          { id: '1', title: t('general.yes') },
          { id: '0', title: t('general.no') }
        ]}
        choicesInRow
      />

      <AnimatedHeight>
        {!!data.bene_co && (
          <div>
            <div className="space-y-4 md:space-y-0 md:flex md:flex-row md:space-x-4 w-full">
              <InputLabel
                className="grow"
                label={
                  t('business.collaborator.beneficiary.participation') +
                  ' ' +
                  (data.beneco_percent
                    ? `(${data.beneco_percent / 1000}%)`
                    : t('business.collaborator.beneficiary.participation_sub'))
                }
                type="number"
                placeholder={t('business.collaborator.beneficiary.participation_placeholder')}
                value={`${parseInt(data.beneco_percent as any)}`}
                onChange={(value) => onChange({ ...data, beneco_percent: parseInt(value) })}
              />
              <Dropdown
                className="md:w-1/4"
                label={t('business.collaborator.beneficiary.holding')}
                placeholder={t('general.select')}
                value={
                  data.direct_holding && data.indirect_holding
                    ? '3'
                    : data.direct_holding
                      ? '1'
                      : data.direct_holding
                        ? '2'
                        : ''
                }
                onChange={({ key }) => {
                  if (key === '1') {
                    onChange({ ...data, direct_holding: 1, indirect_holding: 0 })
                  } else if (key === '2') {
                    onChange({ ...data, direct_holding: 0, indirect_holding: 1 })
                  } else if (key === '3') {
                    onChange({ ...data, direct_holding: 1, indirect_holding: 1 })
                  }
                }}
                items={[
                  { key: '1', value: t('business.collaborator.beneficiary.direct') },
                  { key: '2', value: t('business.collaborator.beneficiary.indirect') },
                  { key: '3', value: t('business.collaborator.beneficiary.mix') }
                ]}
              />
              <Dropdown
                className="md:w-1/4"
                label={t('business.collaborator.beneficiary.voting_rights')}
                placeholder={t('general.select')}
                value={data.voting_rights ? '1' : '0'}
                onChange={({ key }) => onChange({ ...data, voting_rights: key === '1' ? 1 : 0 })}
                items={[
                  { key: '1', value: t('general.yes') },
                  { key: '0', value: t('general.no') }
                ]}
              />
            </div>
            <Dropdown
              className="mt-4 md:w-max"
              label={t('business.collaborator.beneficiary.beneficial_owner')}
              placeholder={t('general.select')}
              value={data.beneficial_owner ? '1' : '0'}
              onChange={({ key }) => onChange({ ...data, beneficial_owner: key === '1' ? 1 : 0 })}
              items={[
                { key: '1', value: t('general.yes') },
                { key: '0', value: t('general.no') }
              ]}
            />
            <Info className="block mt-2">
              {t('business.collaborator.beneficiary.beneficial_owner_info')}
            </Info>
          </div>
        )}
      </AnimatedHeight>

      <div className={'border-b border-slate-100 w-full h-0 my-6'}></div>

      <SectionTitleSmall className="block mt-4">
        {t('business.collaborator.agent.title')}
      </SectionTitleSmall>

      <RadioButton
        className="mb-4"
        id={'agent'}
        value={data.agent ? '1' : '0'}
        onChange={(output) => onChange({ ...data, agent: output === '1' ? 1 : 0 })}
        listOfChoices={[
          { id: '1', title: t('general.yes') },
          { id: '0', title: t('general.no') }
        ]}
        choicesInRow
      />

      <AnimatedHeight>
        {!!data.agent && (
          <div className="space-y-4 md:space-y-0 md:flex md:flex-row md:space-x-4 w-full">
            <InputLabel
              className="grow"
              label={t('business.collaborator.agent.function')}
              placeholder={t('business.collaborator.agent.function_placeholder')}
              value={data.agent_function as any}
              onChange={(value) => onChange({ ...data, agent_function: value })}
            />
            <Dropdown
              label={t('business.collaborator.agent.via_le')}
              placeholder={t('general.select')}
              value={data.agent_via_le ? '1' : '0'}
              onChange={({ key }) => onChange({ ...data, agent_via_le: key === '1' ? 1 : 0 })}
              items={[
                { key: '1', value: t('general.yes') },
                { key: '0', value: t('general.no') }
              ]}
            />
            {!!data.agent_via_le && (
              <InputLabel
                label={t('business.collaborator.agent.name')}
                placeholder="ACME Inc."
                value={data.non_le_agent as any}
                onChange={(value) => onChange({ ...data, non_le_agent: value })}
              />
            )}
          </div>
        )}
      </AnimatedHeight>

      <div className={'border-b border-slate-100 w-full h-0 my-6'}></div>

      <SectionTitleSmall className="block mt-4">
        {t('business.collaborator.legal_representative.title')}
      </SectionTitleSmall>

      <RadioButton
        id={'legal_representative'}
        value={data.legal_representative ? '1' : '0'}
        onChange={(output) => onChange({ ...data, legal_representative: output === '1' ? 1 : 0 })}
        listOfChoices={[
          { id: '1', title: t('general.yes') },
          { id: '0', title: t('general.no') }
        ]}
        choicesInRow
      />
    </div>
  )
}
