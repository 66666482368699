import './index.css'

export const AnimatedEnvelopePass = () => {
  return (
    <svg
      width="432"
      height="274"
      viewBox="0 0 432 274"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2642_5641)">
        <path d="M16 142L216 29.5L416 141.5V269.5H16V142Z" fill="#D4DADD" />
      </g>
      <rect
        x="24"
        y="142"
        width="384"
        height="120"
        rx="6"
        fill="url(#pattern0_2642_5641)"
        className="animated-passport"
      />
      <g filter="url(#filter1_d_2642_5641)">
        <path d="M16 142L119.356 200.712L310.631 199.983L416 141.5V270H16V142Z" fill="white" />
      </g>
      <g filter="url(#filter2_d_2642_5641)">
        <path d="M16 240L216 150L416 240V270H16V240Z" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_2642_5641"
          x="0"
          y="0.5"
          width="432"
          height="272"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-13" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2642_5641" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2642_5641"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern0_2642_5641"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_2642_5641" transform="scale(0.000868056 0.00277778)" />
        </pattern>
        <filter
          id="filter1_d_2642_5641"
          x="0"
          y="112.5"
          width="432"
          height="160.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-13" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2642_5641" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2642_5641"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2642_5641"
          x="8"
          y="138"
          width="416"
          height="136"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2642_5641" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2642_5641"
            result="shape"
          />
        </filter>
        <image
          id="image0_2642_5641"
          width="1152"
          height="360"
          href="/images/olkypass/passportKYC.png"
        />
      </defs>
    </svg>
  )
}
