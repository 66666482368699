import type {
  CompanyCreateDTO,
  CompanyDTO,
  CompanyDetailedDTO,
  CompanyLinkNPLE,
  CompanyRCSDetails
} from '@/features/company/types'
import { fetchServer } from '@/features/utils/fetch'
import {
  StandardErrorResponse,
  StandardResponse,
  isCompanyRCSDetailsEmpty,
  isStandardErrorResponse,
  isStandardResponse
} from '@/features/utils/types'

export class CompanyApiClient {
  /**
   *
   * @param companyModel
   * @param addressModel
   */
  static async createCompany(companyCreate: CompanyCreateDTO, linkNPLE?: CompanyLinkNPLE) {
    const response = await fetchServer('/company/new', {
      method: 'POST',
      reloadOn401: false,
      body: {
        ...companyCreate,
        linkNPLE: linkNPLE ? [linkNPLE] : []
      }
    })
    const res = await response.json()
    try {
      return res as { success: boolean; data: ''; message: '' }
    } catch (error) {
      return res as { success: false; data: ''; message: string }
    }
  }

  /**
   *
   * @param companyModel
   */
  static async editCompany(companyModel: any) {
    const response = await fetchServer('/company/edit/identity', {
      method: 'PUT',
      reloadOn401: false,
      body: {
        company: companyModel.value
      }
    })
    const res = await response.json()
    try {
      return res as { success: boolean; data: ''; message: '' }
    } catch (error) {
      return res as { success: false; data: ''; message: string }
    }
  }

  /**
   *
   */
  static async fetchListOfCompanies(): Promise<StandardResponse<CompanyDTO[] | null>> {
    try {
      const response = await fetchServer('/company/list', {
        method: 'GET'
      })
      const res = await response.json()
      return res
    } catch (error) {
      return { success: false, data: null }
    }
  }

  /**
   *
   * @param identity_uuid
   */
  static async fetchCompanyDetails(
    identity_uuid: string | string[]
  ): Promise<StandardResponse<CompanyDetailedDTO | null>> {
    try {
      const response = await fetchServer('/company/detail', {
        body: {
          identity_uuid
        }
      })
      const res = await response.json()
      return res
    } catch (error) {
      return { success: false, data: null }
    }
  }

  static async fetchCompanyDetailsByRCS(
    country: string,
    rcs: string
  ): Promise<StandardResponse<CompanyRCSDetails> | StandardErrorResponse> {
    const companyDetailsByRCSIsError = (
      json:
        | CompanyRCSDetails
        | StandardErrorResponse
        | { legalInformation: { success: false; message: string } }
        | { legalInformation: [] }
    ) => {
      if ('legalInformation' in json && 'message' in json.legalInformation) {
        throw new Error()
      }
      if (isCompanyRCSDetailsEmpty(json)) throw new Error()

      return json as CompanyRCSDetails | StandardErrorResponse | StandardResponse<CompanyRCSDetails>
    }

    const response = await fetchServer('/public/legal_information/' + country + '/' + rcs)
    const json = (await response.json()) as unknown as
      | CompanyRCSDetails
      | StandardErrorResponse
      | { legalInformation: { success: false; message: string } }
      | { legalInformation: [] }

    const rcsData = companyDetailsByRCSIsError(json)

    if (!isStandardErrorResponse(rcsData) && !isStandardResponse<CompanyRCSDetails>(rcsData)) {
      return { success: true, data: rcsData }
    } else if (isStandardErrorResponse(rcsData)) {
      return rcsData
    }

    throw new Error()
  }

  /**
   *
   * @param company_identity_uuid
   * @param collaborator_identity_uuid
   */
  static async associateNewCollaborator(
    company_identity_uuid: string,
    collaborator_identity_uuid: string
  ) {
    const response = await fetchServer('/company/associate_collaborator', {
      method: 'POST',
      reloadOn401: false,
      body: {
        company_identity_uuid,
        collaborator_identity_uuid
      }
    })
    const res = await response.json()
    try {
      return res as { success: boolean; data: ''; message: '' }
    } catch (error) {
      return res as { success: false; data: ''; message: string }
    }
  }

  /**
   *
   * @param uuid
   */
  static async disassociateCollaborator(uuid: string) {
    const response = await fetchServer('/company/disassociate_collaborator', {
      method: 'POST',
      reloadOn401: false,
      body: {
        uuid
      }
    })
    const res = await response.json()
    try {
      return res as { success: boolean; data: ''; message: '' }
    } catch (error) {
      return res as { success: false; data: ''; message: string }
    }
  }
}
