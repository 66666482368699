import { Modal, ModalContent } from "@/atoms-react/modal/index.react"
import { ChangePassword } from "./change-password.react"

type ChangePasswordModalProps = {
    onClose: () => void
    onValidated: () => void
}

export const ChangePasswordModal = ({ onClose, onValidated }: ChangePasswordModalProps) => {
    return (
        <Modal className={'sm:m-0 m-0 sm:w-full md:w-9/12'} maxWidthClassName={'max-w-5xl'} open={true} onClose={onClose}>
            <ModalContent title={''} >
                <ChangePassword onValidated={onValidated} />
            </ModalContent>
        </Modal>
    )
}