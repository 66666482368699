import { generateSeed } from '@/features/utils/functions'
import _ from 'lodash'
import { DropdownItem, GroupParams } from './index.react'

export type DropdownNativeInputWidgetProps = {
  items: (DropdownItem & { separator?: boolean })[]
  selectedValue: DropdownItem | null
  groups?: GroupParams[]
  onChange?: (value: string) => void
} & Omit<Omit<React.ComponentPropsWithoutRef<'select'>, 'value'>, 'onChange'>

export const DropdownNativeInputWidget = ({
  items,
  selectedValue,
  onChange,
  groups,
  ...props
}: DropdownNativeInputWidgetProps) => {
  return (
    <>
      <select
        className="absolute w-full h-full top-0 left-0 opacity-0"
        value={selectedValue?.key ?? ''}
        onChange={(e: any) => {
          const key = e.target.value

          if (!onChange) return
          onChange(key)
        }}
        {..._.omit(props, 'autoComplete', 'value', 'onChange')}
      >
        {(groups ?? []).flatMap((group) => [
          items
            .filter((item) => item.groupKey === group.key)
            .sort((item1, item2) => (item1.value > item2.value ? 1 : -1))
            .flatMap(({ key, value }) => (
              <option key={key + generateSeed(Date.now().toString())} value={key}>
                {value}
              </option>
            )),
          <option
            disabled
            key={'separator-' + generateSeed(Date.now().toString())}
            value={'--separator-no-value' + generateSeed(Date.now().toString())}
          ></option>
        ])}

        {items
          .sort((item1, item2) => (item1.value > item2.value ? 1 : -1))
          .flatMap(({ key, value }) => (
            <option key={key + generateSeed(Date.now().toString())} value={key}>
              {value}
            </option>
          ))}
      </select>
    </>
  )
}
