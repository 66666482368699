import _ from 'lodash'
import seedrandom from 'seedrandom'
import { Info } from '../text/Text.react'
import { twMerge } from 'tailwind-merge'

export const getGradient = (name: string) => {
  const seed = Math.floor(seedrandom(name)() * 100000000) % 360
  const canvas: HTMLCanvasElement = document.createElement('canvas')
  canvas.width = 64
  canvas.height = 64
  const ctx = canvas.getContext('2d')
  const gradient = ctx!.createLinearGradient(64, 64, 0, 0)
  if (name === '-') {
    gradient.addColorStop(0, 'hsl(' + seed + ', 0%, 50%)')
    gradient.addColorStop(1, 'hsl(' + Math.abs(seed - 60) + ', 10%, 50%)')
  } else {
    gradient.addColorStop(0, 'hsl(' + seed + ', 80%, 50%)')
    gradient.addColorStop(1, 'hsl(' + Math.abs(seed - 60) + ', 80%, 50%)')
  }
  ctx!.fillStyle = gradient
  ctx!.fillRect(0, 0, 64, 64)
  const b64 = canvas.toDataURL('image/jpeg')
  return b64
}

export default function Avatar(
  props: any & {
    shape?: 'square' | 'circle'
    fallback?: string
    avatar: string
    size: 5 | 24 | 14 | 48
  }
) {
  const size = props.size || 14
  const className =
    ' inline-block h-' +
    size +
    ' w-' +
    size +
    (props.shape === 'square' ? ' rounded-sm ' : ' rounded-full ') +
    ' overflow-hidden bg-slate-200 ' +
    (props.className || '')

  if (props.avatar || props.src) {
    return (
      <div
        className={className}
        {..._.omit(props, 'avatar', 'className', 'src')}
        style={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundImage: 'url(' + (props.avatar || props.src) + ')'
        }}
      />
    )
  }

  if (props.fallback) {
    return (
      <span
        className={className + ' flex items-center justify-center text-white background-cover '}
        style={{
          backgroundImage: `url(${getGradient(props.fallback)})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
        {..._.omit(props, 'avatar', 'className', 'src')}
      >
        <Info
          noColor
          className={twMerge(
            size < 8 ? 'text-xxs' : size < 14 ? 'text-md' : size < 24 ? 'text-2xl' : 'text-4xl',
            'font-medium'
          )}
        >
          {props.fallback
            .split(' ')
            .filter((a: string) => a.trim())
            .map((a: string) => a[0].toUpperCase())
            .slice(0, 2)
            .join('')}
        </Info>
      </span>
    )
  }

  return (
    <span className={className} {..._.omit(props, 'avatar', 'className', 'src')}>
      <svg
        className="h-full w-full text-slate-400 bg-slate-200"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  )
}
