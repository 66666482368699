import { ConfirmationResponse } from "@/features/register/types";
import { Iban } from "@/features/user/types";
import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { IbanApiClient } from "../api-client/api-client";

export default function useCreateIban(options?: UseMutationOptions<ConfirmationResponse, Error, Iban>) {
    return useMutation<ConfirmationResponse, Error, Iban>({
        mutationFn: async (iban) => {
            return IbanApiClient.create(iban);
        }, ...options
    })
}