import { getRoute } from '@/app/olkypass/utils/routes'
import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { AuthApiClient } from '@/features/auth/api-client/api-client'
import { Routes } from '@/features/router'
import { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FlowHeader } from '../../../flow-header.react'
import { PasswordInput } from '@/atoms-react/input/password-input.react'

export const ResetPassword = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['pass'])
  const { executeRecaptcha } = useGoogleReCaptcha()
  const token = new URLSearchParams(document.location.search).get('token') || ''
  const id = new URLSearchParams(document.location.search).get('id') || ''

  const { session_uuid } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false)

  const resetPassword = async () => {
    setLoading(true)
    try {
      const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
          throw new Error('Execute recaptcha not yet available')
        }

        return executeRecaptcha('login')
      }

      const captchaToken = await handleReCaptchaVerify()
      if (captchaToken) {
        await AuthApiClient.resetPassword({
          password,
          confirm_password: password,
          id,
          token,
          language: i18n.language,
          captcha_token: captchaToken || ''
        })
        toast.success(t('flow.id_control.label.reset_success'))
        await new Promise((resolve) => setTimeout(resolve, 2000))

        navigate(getRoute(Routes.flow_block, { session_uuid, block: 'welcome' }), {
          replace: true
        })
      } else {
        toast.error('An error occurred')
      }
    } catch (e) {
      toast.error('An error occurred')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Column>
        <div className="grow mb-12 flex flex-col">
          <FlowHeader
            back={false}
            title={t('flow.id_control.label.reset_title')}
            description={t('flow.id_control.label.reset_description')}
          />
          <PasswordInput
            onChange={({ password, valid }) => {
              setPassword(password)
              setIsPasswordValid(valid)
            }}
          />
        </div>

        <div className="shrink-0">
          <Button
            shortcut="enter"
            onClick={() => resetPassword()}
            disabled={!isPasswordValid}
            loading={loading}
          >
            {t('flow.id_control.input.save')}
          </Button>
        </div>
      </Column>
    </>
  )
}
