import {getJwt, setTwoFaAuthenticating} from '@/features/auth/utils'
import AuthService from '../services/auth.service'
import AuthenticationError from '../services/authentication.error'

const state = {
  authenticating: false,
  accessToken: getJwt().token,
  authenticationErrorCode: 0,
  authenticationError: '',
  refreshTokenPromise: null,
  twoFaAuthenticating: false,
  isInternalReAuthenticated: false
}
const getters = {
  authenticationErrorCode: (state: { authenticationErrorCode: any }) => {
    return state.authenticationErrorCode
  },

  authenticationError: (state: { authenticationError: any }) => {
    return state.authenticationError
  },

  authenticating: (state: { authenticating: any }) => {
    return state.authenticating
  },

  twoFaAuthenticating: (state: { twoFaAuthenticating: any }) => {
    return state.twoFaAuthenticating
  },
  isInternalReAuthenticated: (state: { isInternalReAuthenticated: any }) => {
    return state.isInternalReAuthenticated
  }
}
const actions = {
  async signIn(context: any, signInData: any) {
    context.commit('setTwoFaAuthenticatingStatus', false)
    context.commit('signInRequest')
    return new Promise((resolve, reject) => {
      AuthService.signIn(signInData)
        .then((res) => {
          if (!res) {
            context.commit('setTwoFaAuthenticatingStatus', true)
          } else {
            context.commit('signInSuccess', res)
          }
          resolve(res)
        })
        .catch((err) => {
          if (err instanceof AuthenticationError) {
            context.commit('signInError', {
              errorCode: err.errorCode,
              errorMessage: err.message
            })
            reject(err.message)
          }
        })
    })
  },
  async signInViaFingerPrint(context: any, signInData: any) {
    context.commit('signInRequest')
    return new Promise((resolve, reject) => {
      AuthService.signInViaFingerPrint(signInData)
        .then((res) => {
          context.commit('signInSuccess', res)
          resolve(res)
        })
        .catch((err) => {
          if (err instanceof AuthenticationError) {
            context.commit('signInError', {
              errorCode: err.errorCode,
              errorMessage: err.message
            })
            reject(err.message)
          }
        })
    })
  },
  signOut(context: any) {
    context.commit('signOutRequest')
    return new Promise<void>((resolve) => {
      AuthService.signOut()
      resolve()
    })
  },

  refreshToken(context: any, state: { refreshTokenPromise: any }) {
    if (!state.refreshTokenPromise) {
      const p = AuthService.refreshToken()
      context.commit('refreshTokenPromise', p)

      p.then(
        (response) => {
          context.commit('refreshTokenPromise', null)
          context.commit('loginSuccess', response)
        },
        (error) => {
          context.commit('refreshTokenPromise', error)
        }
      )
    }

    return state.refreshTokenPromise
  },

  setAuthenticatingStatus(context: any, status: any) {
    context.commit('setAuthenticatingStatus', status)
  },
  setIsInternalReAuthenticatedStatus(context: any, status: any) {
    context.commit('setIsInternalReAuthenticatedStatus', status)
  },
  setTwoFaAuthenticatingStatus(context: any, status: any) {
    context.commit('setTwoFaAuthenticatingStatus', status)
  },

  async twoFaCodeCheck(context: any, twoFaData: any) {
    context.commit('signInRequest')
    return new Promise((resolve, reject) => {
      AuthService.twoFaCodeCheck(twoFaData)
        .then((res) => {
          context.commit('signInSuccess', res)
          resolve(res)
        })
        .catch((err) => {
          if (err instanceof AuthenticationError) {
            context.commit('signInError', {
              errorCode: err.errorCode,
              errorMessage: err.message
            })
            reject(err.message)
          }
        })
    })
  }
}
const mutations = {
  signInRequest(state: {
    authenticating: boolean
    authenticationError: string
    authenticationErrorCode: number
  }) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  signInSuccess(
    state: {
      accessToken: any
      authenticating: boolean
    },
    accessToken: any
  ) {
    state.accessToken = accessToken
    state.authenticating = false
  },

  signInError(
    state: {
      authenticating: boolean
      authenticationErrorCode: any
      authenticationError: any
    },
    { errorCode, errorMessage }: any
  ) {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
  },

  signOutRequest(state: { authenticating: boolean; twoFaAuthenticating: boolean }) {
    state.authenticating = false
    state.twoFaAuthenticating = false
  },

  refreshTokenPromise(state: { refreshTokenPromise: any }, promise: any) {
    state.refreshTokenPromise = promise
  },

  processSuccess(state: { authenticating: boolean }) {
    state.authenticating = false
  },

  setAuthenticatingStatus(state: { authenticating: any }, status: any) {
    state.authenticating = status
  },
  setIsInternalReAuthenticatedStatus(state: { isInternalReAuthenticated: any }, status: any) {
    state.isInternalReAuthenticated = status
  },
  setTwoFaAuthenticatingStatus(state: { twoFaAuthenticating: any }, status: any) {
    state.twoFaAuthenticating = status
    setTwoFaAuthenticating(status)
  }
}
export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
