import { Capacitor } from '@capacitor/core'
// must be prod
let mainRoot = 'https://api.olkypay.com'
if (!Capacitor.isNativePlatform()) {
  const origin = document.location.origin
  if (origin.includes('localhost')) {
    // you can change it to use uat
    mainRoot = 'http://localhost:8000'
  } else if (origin.includes('uat')) {
    mainRoot = 'https://apidev.olkypay.com'
  }
}
const url = '/_settings_environment'
const options = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}
let environmentServerSide = {}

try {
  const response = await fetch(mainRoot.replace(/\/$/, '') + '/' + url.replace(/^\//, ''), options)
  const rawData = await response.json()
  environmentServerSide = rawData.data
} catch (e) {
  console.info('environmentServerSide error')
}

export default environmentServerSide
