const state = {
  responseData: '',
  responseErrorCode: 0,
  responseError: '',
  currentTransfer: {}
}

const getters = {
  responseData: (state: { responseData: any }) => {
    return state.responseData
  },

  responseErrorCode: (state: { responseErrorCode: any }) => {
    return state.responseErrorCode
  },
  responseError: (state: { responseError: any }) => {
    return state.responseError
  },
  currentTransfer: (state: { currentTransfer: any }) => {
    return state.currentTransfer
  }
}

const actions = {
  setCurrentTransfer(context: any, transfer: any) {
    context.commit('setCurrentTransfer', transfer)
  }
}

const mutations = {
  dataRequest(state: { responseError: string; responseErrorCode: number }) {
    state.responseError = ''
    state.responseErrorCode = 0
  },
  dataSuccess(state: { responseData: string }, payload: any) {
    state.responseData = payload
  },
  dataError(
    state: {
      responseError: any
      responseErrorCode: any
    },
    { errorCode, errorMessage }: any
  ) {
    state.responseError = errorMessage
    state.responseErrorCode = errorCode
  },
  setCurrentTransfer(state: { currentTransfer: any }, transfer: any) {
    // this will init current transfer
    if (!Object.keys(transfer).length) {
      state.currentTransfer = {
        typeVirement: '',
        title: '',
        executionDate: [],
        toDate: []
      }
    } else {
      Object.assign(state.currentTransfer, transfer)
    }
  }
}

export const transfer = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
