import { Routes } from '@/features/router'
import { useUser } from '@/features/user/hooks/use-user-vue'
import { createLoaderStore } from '@/features/utils/loader'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { AuthApiClient } from '../api-client/api-client'
import { setJwt } from '../utils'
import { reactLoginStatusChange } from './use-auth-react'

const Loader = createLoaderStore('user')
const InitialReady = createLoaderStore('user-ready', true)
let refreshInterval: any
let lastAutoRefresh: number = Date.now()

/**
 * This is a placeholder function that will be replaced by a vue "setState" equivalent
 * so react can control the refresh of the user profile
 */
let _refreshVueAuth = () => { }

export const refreshVueAuth = async () => {
  _refreshVueAuth()
}

export const useAuth = () => {
  const { user, refreshProfile } = useUser()

  const loader = Loader()
  const initialLoading = InitialReady()
  const router = useRouter()

  // Get user profile
  const profile = async () => {
    await refreshProfile()
  }

  // Refresh user token while the user is active
  const refresh = async () => {
    try {
      // logout()
      const { token } = await AuthApiClient.refresh()
      if (token) {
        setJwt({ token, refresh: token })
      } else {
        logout()
        return false
      }
    } catch (e) {
      logout()
      return false
    }
    return true
  }

  // Logout the user and optional redirect to the login page
  const logout = (redirectToClient = true) => {
    setJwt({ token: null, refresh: null })
    user.set(null)
    if (refreshInterval) clearInterval(refreshInterval)
    if (redirectToClient) {
      const path = router.currentRoute.value.path

      // Special case for reset password until backend changes the endpoint (or we do)
      if (path.indexOf('/sign-in/reset-password') === 0) {
        router.push(Routes.flow + '/-/login/reset' + document.location.search)
        return
      }

      const query = router.currentRoute.value.query
      router.push({
        path: path.includes(Routes.flow) ? path : Routes.flow, query: { r: encodeURIComponent(path), ...query }
      })
    }
  }

  // Setup a single interval for all the application
  const setupRefreshInterval = async () => {
    if (!refreshInterval) {
      window.addEventListener('focus', () => {
        if (refreshInterval && user.user && lastAutoRefresh < Date.now() - 1000 * 60 * 1) {
          refresh()
          lastAutoRefresh = Date.now()
        }
      })

      window.addEventListener('blur', () => {
        if (refreshInterval && user.user && lastAutoRefresh < Date.now() - 1000 * 60 * 1) {
          refresh()
          lastAutoRefresh = Date.now()
        }
      })

      refreshInterval = setInterval(
        () => {
          if (document.hidden || !user.user) return
          lastAutoRefresh = Date.now()
          refresh()
        },
        1000 * 60 * 2 // 2 minutes
      )

      // First time we also try to log the user in
      if (await refresh()) {
        loader.set(true)
        //Init at least one time
        await profile()
        loader.set(false)
      }
      initialLoading.set(false)
    }
  }

  setupRefreshInterval()

  // If logged in status changes, call reactLoginStatusChange
  watch(user, () => {
    reactLoginStatusChange(!!user.user)
  })

  _refreshVueAuth = profile

  return {
    loader,
    initialLoading,
    logout,
    user,
    profile
  }
}
