import { AuthApiClient } from "@/features/auth/api-client/api-client";
import { LoginResponse } from "@/features/auth/types";
import {UseMutationOptions, useMutation} from '@tanstack/react-query'

export type TwoFaAuthenticateMutationProps = {
    email: string,
    password: string, 
    challenge: string
}

export default function useTwoFaAuthenticateMutation(options?: UseMutationOptions<LoginResponse, Error, TwoFaAuthenticateMutationProps>) {
    return useMutation<LoginResponse, Error, TwoFaAuthenticateMutationProps>({mutationFn: async ({ email, password, challenge }: TwoFaAuthenticateMutationProps) => {
        return AuthApiClient.login(email, password, challenge);
    }, ...options})
}