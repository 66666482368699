import ApiService from './api.service'
import { type AxiosRequestConfig } from 'axios'

class ResponseError extends Error {
  errorCode: any
  errorMessage: any

  constructor(errorCode: any, message: string | undefined) {
    super(message)
    this.name = this.constructor.name
    if (message != null) {
      this.message = message
    }
    this.errorCode = errorCode
  }
}

const TransactionService = {
  getTransactions: async function (paginationOptions: any) {
    const url = '/writings'
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      params: {
        page: paginationOptions.page,
        search: paginationOptions.search ? btoa(paginationOptions.search) : ''
      }
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  getOrders: async function (paginationOptions: any) {
    const url = '/orders/supplier'
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      params: {
        filter: paginationOptions.filter
        // search: paginationOptions.search ? paginationOptions.search : ""
      }
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.message)
    }
  },
  sendOrdersValidationCode: async function (inputData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: '/orders/askvalidate/' + inputData.orderIds,
      data: inputData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  validateOrdersWithCode: async function (inputData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: '/orders/validate/' + inputData.orderIds,
      data: inputData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  createNewOrder: async function (transfer: any) {
    const transferData = transfer.transferData
    const client_id = transfer.client_id
    const url = '/orders/client/' + client_id
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: url,
      data: transferData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  cancelOrder: async function (inputData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: '/orders/cancel/' + inputData.order_id,
      data: inputData
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  }
}

export { TransactionService, ResponseError }
