<template>
  <ion-grid class="ion-margin-bottom">
    <ion-row>
      <ion-col>
        <div class="card mb-4 mt-0 card-plain">
          <div class="card-header pb-0">
            <ion-row>
              <ion-col>
                <h2 class="mb-0 text-lg">{{ $t('olkypay.labels.homePage.my_last_transactions') }}</h2>
              </ion-col>
            </ion-row>
          </div>
          <div class="card-body pt-4">
            <ion-searchbar
              show-clear-button="focus"
              animated="true"
              :placeholder="$t('olkypay.inputs.homePage.search')"
              class="no-elevation bg-gray-100 border-radius-xs"
              @ionBlur="searchTransactions($event)"
            ></ion-searchbar>
            <div v-if="isTransactionsLoading" class="text-center mt-4">
              <ion-spinner name="bubbles" class="text-center"></ion-spinner>
              <br />
              <ion-label class="text-center text-gray text-md">{{
                $t('olkypay.labels.homePage.loading_data')
              }}</ion-label>
            </div>
            <div v-else-if="!isTransactionsLoading && transactions.length">
              <!--                  @ionBlur="searchTransactions($event)"-->
              <div v-for="(transactionsList, dateKey) in groupedTransactions">
                <ion-row class="mt-2">
                  <ion-col size="1">
                    <ion-icon :icon="calendar" class="text-gray"></ion-icon>
                  </ion-col>
                  <ion-col>
                    <h4 class="text-gray font-weight-bolder">{{ dateKey }}</h4>
                  </ion-col>
                </ion-row>
                <div v-for="transaction in transactionsList">
                  <transaction :transaction="transaction"></transaction>
                </div>
              </div>
              <ion-infinite-scroll @ionInfinite="displayPaginatedTransactions($event)">
                <ion-infinite-scroll-content
                  :loading-text="$t('olkypay.labels.homePage.loading_data')"
                  color="blue_olky"
                  loading-spinner="bubbles"
                ></ion-infinite-scroll-content>
              </ion-infinite-scroll>
            </div>
            <div v-else>
              <div class="d-flex flex-column bg-gray-200 border-radius-lg p-1 mt-2">
                <span class="mb-2 text-md">{{
                  $t('olkypay.labels.homePage.no_transactions_found')
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang="ts">
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSearchbar,
  IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner
} from '@ionic/vue'
import {
  arrowBack,
  arrowDownSharp,
  arrowUp,
  calendar,
  create,
  downloadSharp,
  ellipsisHorizontal,
  ellipsisVertical,
  helpCircle,
  logOut,
  personCircle,
  search
} from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
import Transaction from './transaction.vue'

export default {
  name: 'TransactionsList',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLoading,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonSearchbar,
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonBackButton,
    IonButtons,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    Transaction
  },
  data() {
    return {
      arrowBack,
      create,
      ellipsisHorizontal,
      ellipsisVertical,
      helpCircle,
      search,
      personCircle,
      logOut,
      calendar,
      arrowUp,
      arrowDownSharp,
      downloadSharp,
      paginationOptions: {
        page: 1,
        search: ''
      },
      displayedTransactions: [],
      isTransactionsLoading: false
    }
  },
  methods: {
    ...mapActions('loader', [
      'playSkeletonLoader',
      'stopSkeletonLoader',
      'playLoader',
      'stopLoader'
    ]),
    ...mapActions('transaction', ['loadTransactions', 'initTransactions']),
    async searchTransactions(event) {
      this.isTransactionsLoading = true
      this.initTransactions()
      this.paginationOptions.search = event.target.value
      await this.loadTransactions({ pagination_options: this.paginationOptions })
      this.isTransactionsLoading = false
    },
    async displayPaginatedTransactions(event) {
      this.paginationOptions.page++
      await this.loadTransactions({ pagination_options: this.paginationOptions })
      // No more data to load, disable infinite scroll
      // event.target.disabled = true;
      event.target.complete()
    }
  },
  async mounted() {
    this.isTransactionsLoading = true
    this.initTransactions()
    await this.loadTransactions({ pagination_options: this.paginationOptions })
    this.isTransactionsLoading = false
  },
  computed: {
    ...mapGetters('loader', ['isSkeletonLoading']),
    ...mapGetters('transaction', ['transactions']),
    groupedTransactions() {
      return this.transactions.reduce((result, item) => {
        const key =
          item.accountingDate[2] + '-' + item.accountingDate[1] + '-' + item.accountingDate[0]
        if (!result[key]) {
          result[key] = []
        }
        result[key].push(item)
        return result
      }, {})
    }
  }
}
</script>

<style scoped></style>
