import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../_layout/index.react'
import { useSupport } from '@/features/support/hooks/use-support'
import Loader from '@/atoms-react/loader/Loader.react'
import { twMerge } from 'tailwind-merge'
import { Base } from '@/atoms-react/text/Text.react'

export const SupportApp = () => {
  const { t } = useTranslation(['pass'])
  const { SupportUrl } = useSupport()
  const { data: url, isError } = SupportUrl()

  return (
    <>
      <LayoutTitle>
        <div className="flex items-center gap-x-2">{t('support.label.main_title')}</div>
      </LayoutTitle>

      <div className="flex flex-col md:flex-col 2xl:flex-row w-full h-full gap-y-6 md:gap-x-6 relative">
        <Loader
          className={twMerge(
            'absolute top-1/2 left-1/2 transition-opacity text-gray-500',
            url ? 'opacity-0' : 'opacity-100'
          )}
        />
        <div
          className={twMerge('h-full w-full transition-opacity', url ? 'opacity-100' : 'opacity-0')}
        >
          {url && (
            <iframe
              className="h-full w-full border-2 rounded-xl"
              src={url}
              sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            />
          )}
        </div>
        {isError && (
          <div className="absolute top-0 left-0 right-0 bottom-0 m-auto w-full max-w-md h-5 text-center">
            <Base className="mt-4 block animate__animated animate__fadeIn animate__faster">
              {t('olkypass.supportPage.error')}
            </Base>
          </div>
        )}
      </div>
    </>
  )
}
