<template>
  <ion-page class="tracking-normal">
    <ion-content id="morePage" :fullscreen="true" v-if="!isSkeletonLoading">
      <header-app-with-label :title="$t('olkypay.tabs.homePage.more')"></header-app-with-label>
      <ion-grid class="ion-margin-bottom">
        <ion-row>
          <ion-col>
            <h3 class="font-weight-bold">{{ $t('olkypay.labels.morePage.daily_life') }}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item detail="true" :href="OLKY_SUPPORT_LINK">
              <ion-icon slot="start" aria-hidden="true" :icon="person" color="blue_olky"></ion-icon>
              <ion-label>
                <h2 class="text-md">{{ $t('olkypay.labels.morePage.contact') }}</h2>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item detail="true" :href="OLKY_PRIVACY_POLICY_LINK">
              <ion-icon
                slot="start"
                aria-hidden="true"
                :icon="documentLock"
                color="blue_olky"
              ></ion-icon>
              <ion-label>
                <h2 class="text-md">{{ $t('olkypay.labels.morePage.privacy_policy') }}</h2>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row class="mt-3">
          <ion-col>
            <h3 class="font-weight-bold">
              {{ $t('olkypay.labels.morePage.application_parameters') }}
            </h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <language-switcher></language-switcher>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item detail="true" @click="goToRouter('MoreAuthSettingsSubTabRouter')">
              <ion-icon
                slot="start"
                aria-hidden="true"
                :icon="settings"
                color="blue_olky"
              ></ion-icon>
              <ion-label>
                <h2 class="text-md">{{ $t('olkypay.labels.morePage.authentication_settings') }}</h2>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item detail="true" :href="OLKY_HELP_LINK">
              <ion-icon
                slot="start"
                aria-hidden="true"
                :icon="helpCircle"
                color="blue_olky"
              ></ion-icon>
              <ion-label>
                <h2 class="text-md">{{ $t('olkypay.labels.morePage.help') }}</h2>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item detail="true" :href="OLKY_ABOUT_LINK">
              <ion-icon slot="start" aria-hidden="true" :icon="idCard" color="blue_olky"></ion-icon>
              <ion-label>
                <h2 class="text-md">{{ $t('olkypay.labels.morePage.about') }}</h2>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item detail="true" :href="OLKY_LEGAL_LINK">
              <ion-icon slot="start" aria-hidden="true" :icon="book" color="blue_olky"></ion-icon>
              <ion-label>
                <h2 class="text-md">{{ $t('olkypay.labels.morePage.legal_notices') }}</h2>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item detail="true" @click="handleLogout">
              <ion-icon slot="start" aria-hidden="true" :icon="logOut" color="blue_olky"></ion-icon>
              <ion-label>
                <h2 class="text-md">{{ $t('olkypay.labels.morePage.log_off') }}</h2>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <full-skeleton v-else></full-skeleton>
  </ion-page>
</template>

<script lang="ts">
import config from '@/config'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  alertController
} from '@ionic/vue'
import {
  bodyOutline,
  book,
  documentLock,
  helpCircle,
  idCard,
  lockClosed,
  logOut,
  person,
  settings
} from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
import LanguageSwitcher from '../../components/LanguageSwitcher.vue'
import HeaderAppWithLabel from '../../components/headers/HeaderAppWithLabel.vue'
import FullSkeleton from '../../components/skeleton/FullSkeleton.vue'
import { Routes } from '@/features/router'
export default {
  name: 'MorePage',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    HeaderAppWithLabel,
    LanguageSwitcher,
    FullSkeleton
  },
  data() {
    return {
      person,
      logOut,
      bodyOutline,
      book,
      helpCircle,
      documentLock,
      idCard,
      lockClosed,
      settings
    }
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions('user', ['setSecurityInfos']),
    ...mapActions('loader', ['playSkeletonLoader', 'stopSkeletonLoader']),
    async handleLogout() {
      this.playSkeletonLoader()
      this.signOut()
        .then(() => {
          this.$router.replace(Routes.login)
        })
        .catch(async (err: any) => {
          const errorAlert = await alertController.create({
            header: 'Failed',
            subHeader: 'Sign out Failed',
            message: err,
            buttons: ['OK']
          })
          await errorAlert.present()
        })
        .finally(() => {
          this.stopSkeletonLoader()
        })
    },
    goToRouter(router_name: string) {
      this.$router.push({ name: router_name })
    }
  },
  async mounted() {
    this.playSkeletonLoader()
    await this.setSecurityInfos()
    this.stopSkeletonLoader()
  },
  computed: {
    ...mapGetters('loader', ['isSkeletonLoading']),
    ...mapGetters('home', ['currentLanguage']),
    ...mapGetters('user', ['user_settings']),
    OLKY_SUPPORT_LINK() {
      return config.OLKY_MAIN_LINK + this.currentLanguage + config.OLKY_SUPPORT_LINK
    },
    OLKY_PRIVACY_POLICY_LINK() {
      return config.OLKY_MAIN_LINK + this.currentLanguage + config.OLKY_PRIVACY_POLICY_LINK
    },
    OLKY_HELP_LINK() {
      return config.OLKY_MAIN_LINK + this.currentLanguage + config.OLKY_HELP_LINK
    },
    OLKY_ABOUT_LINK() {
      return config.OLKY_MAIN_LINK + this.currentLanguage + config.OLKY_ABOUT_LINK
    },
    OLKY_LEGAL_LINK() {
      return config.OLKY_MAIN_LINK + this.currentLanguage + config.OLKY_LEGAL_LINK
    }
  }
}
</script>

<style scoped></style>
