import { getRoute } from '@/app/olkypass/utils/routes'
import { A } from '@/atoms-react/a/A.react'
import { AnimatedHeight } from '@/atoms-react/animated-height/AnimatedHeight.react'
import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import Image from '@/atoms-react/image/Image.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import useAuthenticateWebAuthnMutation from '@/features/flow/hooks/use-authenticate-web-authn'
import useCheckLoginMutation from '@/features/flow/hooks/use-check-login'
import { Routes } from '@/features/router'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { client } from '@passwordless-id/webauthn'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { FlowHeader } from '../../../flow-header.react'
import { animatedNext } from '../../../layout/AnimatedForm.react'
import { StorageService } from '@/features/utils/storage'
import { WebAuthnApi } from '@/features/auth/api-client/webauthn-api-client.ts'

interface AuthenticateProps extends LayoutBaseProps {
  email: string
  onValidated?: (output: AuthenticateOutput) => void
  overlayMode?: boolean
  is2faActivated?: boolean
}

export interface AuthenticateOutput {
  is2FARequired: boolean
  token: string
  email: string
  password: string | null
}

function Authenticate({
  email,
  is2faActivated,
  onValidated = () => {},
  ...props
}: AuthenticateProps) {
  const { session_uuid, block } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation(['pass'])
  const [ready, setReady] = useState(false)
  const [tryingWebauthn, setTryingWebauthn] = useState(true)
  const [password, setPassword] = useState('')
  const {
    mutate: webauthnLogin,
    data: webauthnToken,
    isPending: isPendingWebauthn
  } = useAuthenticateWebAuthnMutation()

  const { mutate: classicalLogin, isPending: isPendingClassicalLogin } = useCheckLoginMutation()

  /* Try to login with webauthn */
  useEffect(() => {
    const executeEffect = async () => {
      // Temporary fix because we are still using security from vue
      StorageService.setItem('username', email)
      if (client.isAvailable()) {
        setTryingWebauthn(true)
        let activeCredentials = []
        try {
          activeCredentials = await WebAuthnApi.retrieveWebAuthnActiveCredentials(email)
        } catch (e) {
          console.log(e)
        }
        webauthnLogin(email, {
          onSuccess: async (webauthnToken) => {
            if (webauthnToken) {
              onValidated({ token: webauthnToken, email, password: null, is2FARequired: false })
            } else {
              if (!is2faActivated && activeCredentials?.length) {
                animatedNext('back', async () => {
                  window.history.back()
                })
                toast.error(t('flow.id_control.error.webauthn_failed'))
              } else {
                setTryingWebauthn(false)
              }
            }
          },
          onError: (error) => {
            console.error('error', error)
            if (!is2faActivated && activeCredentials?.length) {
              animatedNext('back', async () => {
                window.history.back()
              })
              toast.error(t('flow.id_control.error.webauthn_failed'))
            } else {
              setTryingWebauthn(false)
            }
          },
          onSettled: () => setReady(true)
        })
      }
    }

    executeEffect()
  }, []) // Add necessary dependencies in the array if required

  /* Button continue is pushed */
  function execute() {
    if (webauthnToken) {
      return
    }

    if (!password) {
      toast.error(t('flow.id_control.error.password_required'))
      return
    }

    classicalLogin(
      { email, password },
      {
        onSuccess: (data) => {
          onValidated({
            token: data?.token ?? '',
            email,
            password,
            is2FARequired: data?.two_fa_required ?? false
          })
        },
        onError: () => {
          toast.error(t('flow.id_control.error.check_login_failed'))
        }
      }
    )
  }

  return (
    <>
      <Column {...props}>
        <div className="grow mb-12 flex flex-col">
          <FlowHeader
            title={t('flow.id_control.label.login_title')}
            description={
              tryingWebauthn
                ? t('flow.id_control.label.login_webauthn_description')
                : t('flow.id_control.label.login_description')
            }
          />
          <AnimatedHeight>
            {ready && (
              <>
                {tryingWebauthn && (
                  <div className={'w-full flex justify-center items-center mt-4 grow pb-16'}>
                    <Image
                      src="/images/olkypass/touch-id.svg"
                      className={'w-1/2 dark:hidden'}
                      alt="Touch ID"
                    />
                    <Image
                      src="/images/olkypass/touch-id-dark.svg"
                      className={'w-1/2 hidden dark:block'}
                      alt="Touch ID"
                    />
                  </div>
                )}
                {!tryingWebauthn && (
                  <>
                    <InputLabel
                      autoFocus
                      type="password"
                      placeholder={t('flow.id_control.input.placeholder_password')}
                      className={'pb-4'}
                      value={password}
                      autoComplete="current-password"
                      onChange={(value) => setPassword(value)}
                      label={t('flow.id_control.input.password')}
                    />
                    <A
                      onClick={() =>
                        animatedNext('forward', async () =>
                          navigate(
                            getRoute(Routes.flow_substep, {
                              session_uuid,
                              block,
                              step: 'recover',
                              substep: encodeURIComponent(email)
                            })
                          )
                        )
                      }
                      className={'text-blue-500 font-semibold'}
                    >
                      {t('flow.id_control.actions.forgot_password')}
                    </A>
                  </>
                )}
              </>
            )}
          </AnimatedHeight>
        </div>
        <div
          className={twMerge(
            'shrink-0 transition-all ',
            ready && !tryingWebauthn ? 'opacity-100' : 'pointer-events-none opacity-0'
          )}
        >
          <Button
            shortcut="enter"
            onClick={() => execute()}
            icon={ChevronRightIcon}
            loading={isPendingClassicalLogin || isPendingWebauthn}
          >
            {t('flow.id_control.input.continue')}
          </Button>
        </div>
      </Column>
    </>
  )
}

export default Authenticate
