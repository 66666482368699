export type SendEmailCodeResponse = {
    success: boolean
    data?: {
        cypher: string
    }
    message: string
}

export type ValidationEmailCodeResponse = {
    success: boolean
    data: string
    message: string
}

export type ValidationEmailCodeParams = {
    cypher: string
    codeEmail: string
}

export enum ChangePasswordErrorCode {
    WrongOTP = 'wrong_OTP',
    WrongPassword = 'wrong_password'
}

export type ChangePasswordParams = {
    password: string
}

export type ChangePasswordResponse = {
    success: boolean
    errorCode?: ChangePasswordErrorCode
}

export type TryConnectionParams = {
    email: string
    password: string
    codeOtp: string
}

export type TryConnectionResponse = {
    success: boolean
    data?: string
}