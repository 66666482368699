import Loader from '@/atoms-react/loader/Loader.react'
import { Base, Title } from '@/atoms-react/text/Text.react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

type BalanceCardProps = {
  amount: number | string | null
  unit: string
  unitProps?: React.ComponentPropsWithoutRef<'span'>
  label: string
  color?: string
  children?: React.ReactNode
  loading?: boolean
  roundTo?: number
  withButton?: boolean
  detailsModalIcon?: boolean
  disabled?: boolean
  onClickIcon?: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const BalanceCard = ({
  amount,
  unit,
  unitProps = {},
  label,
  color,
  loading = false,
  children,
  roundTo = 2,
  detailsModalIcon,
  disabled = false,
  onClickIcon,
  ...props
}: BalanceCardProps) => {
  const { t } = useTranslation(["atoms"])
  return (
    <div {...(disabled && {"data-tooltip": t('molecules.kyc_alert.kyc_warning')})} className={twMerge(props.className, "flex w-full")}>
      <div
        className={twMerge(
          'border-2 border-slate-50 rounded-sm px-4 py-2 md:px-6 md:py-4 w-full text-black dark:text-white dark:border-slate-700 flex flex-col justify-between',
          props.className,
          disabled && "opacity-40 pointer-events-none"
        )}

        {..._.omit(
          props,
          'className',
          'amount',
          'unit',
          'unitProps',
          'label',
          'color',
          'loading',
          'roundTo',
          'withButton'
        )}
      >

        <div>
          <div className={twMerge('flex w-full', color)}>
            <Base className="text-slate-300">{label}</Base>
          </div>
          <div className={twMerge(`flex w-full`, color)}>
            {!loading && (
              <div className="flex items-center gap-x-2">
                <Title className={twMerge(color, 'font-bold')} noColor>
                  {_.isSafeInteger(amount) && (
                    <>
                      {amount === 0 && amount}
                      {amount !== 0 && ((amount as number) / 100).toFixed(roundTo)}
                      <span
                        className={twMerge('ml-2 text-2xl', unitProps.className)}
                        {..._.omit(unitProps, 'className')}
                      >
                        {unit}
                      </span>
                    </>
                  )}

                  {_.isString(amount) && (
                    <>
                      {amount}
                      {
                        <span
                          className={twMerge('ml-2 text-2xl', unitProps.className)}
                          {..._.omit(unitProps, 'className')}
                        >
                          {unit}
                        </span>
                      }
                    </>
                  )}
                </Title>
                {detailsModalIcon && (
                  <InformationCircleIcon
                    className="h-6 w-6 mt-2 hover:cursor-pointer hover:text-black/80"
                    onClick={onClickIcon}
                  />
                )}
              </div>
            )}
            {loading && <Loader className={'w-8 h-8 text-blue-500'} />}
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}
