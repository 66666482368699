import { twMerge } from 'tailwind-merge'
import Text from '../text/Text.react'
import stylesheet from './index.module.css'

interface RadioButtonProps {
  className?: string
  value: string
  label?: string
  listOfChoices: { id: string; title: string }[]
  choicesInRow?: boolean
  onChange?: (value: string) => void
  id?: string
}

export default function RadioButton({
  value,
  label,
  listOfChoices,
  onChange = () => {},
  choicesInRow = false,
  id,
  ...props
}: RadioButtonProps) {
  const handleRadioChange = (id: string) => {
    onChange(id)
  }

  return (
    <>
      <div {...props}>
        <Text type="label">{label}</Text>
        <fieldset className={twMerge(stylesheet.fieldset, 'focus:bg-red-500')}>
          <div
            className={twMerge(
              choicesInRow && 'flex space-x-6',
              !choicesInRow && 'flex-col space-y-5',
              'items-center'
            )}
          >
            {listOfChoices.map((choice) => (
              <div
                key={choice.id}
                className={twMerge(stylesheet.inputwrapper, 'space-x-2 flex mt-2 cursor-pointer')}
                onClick={() => handleRadioChange(choice.id)}
              >
                <input
                  id={choice.id}
                  name={'radiobutton_choice_' + id}
                  type="radio"
                  checked={choice.id === value}
                  onChange={() => handleRadioChange(choice.id)}
                  onClick={(e: any) => e.preventDefault}
                  className={twMerge(
                    stylesheet.input,
                    'h-6 w-6 border-slate-100 hover:ring-blue-900 cursor-pointer'
                  )}
                />
                <Text type="base" className={'font-semibold'}>
                  {choice.title}
                </Text>
              </div>
            ))}
            <div className={stylesheet.div}></div>
          </div>
        </fieldset>
      </div>
    </>
  )
}
