import React from 'react'
import Column from '@/atoms-react/column/Column.react'
import { twMerge } from 'tailwind-merge'
import styles from './index.module.scss'

type SkelettonProps = {
  className?: React.ComponentPropsWithoutRef<'div'>['className']
} & React.ComponentPropsWithoutRef<'div'>

export const Skeletton = ({ className, ...props }: SkelettonProps) => {
  return (
    <Column
      className={twMerge('flex-1 h-16 rounded-sm', styles.animatedBackground, className)}
      {...props}
    ></Column>
  )
}
