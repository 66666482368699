import { defineJsxComponent } from '@/jsx-utils'
import _ from 'lodash'

enum Type {
  'title',
  'subtitle',
  'section',
  'section-small',
  'base',
  'base-strong',
  'base-small',
  'info',
  'info-small'
}

type Props = {
  class?: string
  type?: Type
  noColor?: boolean
}

export const Text = defineJsxComponent<Props>((props, { slots }) => {
  if (!slots.default?.()) return () => <></>

  const defaultClassName = (): string => {
    let baseClassName = ''

    switch (props.type) {
      case Type.title:
        baseClassName =
          'text-3xl lg:text-4xl leading-tight lg:leading-tight font-bold block ' +
          (props.noColor ? '' : 'text-[#000] dark:text-white')
        break
      case Type.subtitle:
        baseClassName =
          'text-base font-regular block ' +
          (props.noColor ? '' : 'text-slate-700 dark:text-white dark:opacity-75')
        break
      case Type.section:
        baseClassName =
          'text-xl font-medium block mb-2 ' + (props.noColor ? '' : 'text-black dark:text-white')
        break
      case Type['section-small']:
        baseClassName =
          'text-base font-medium block ' + (props.noColor ? '' : 'text-slate-900 dark:text-white')
        break
      case Type['base-strong']:
        baseClassName =
          'text-base font-semibold ' + (props.noColor ? '' : 'text-slate-900 dark:text-white')
        break
      case Type.base:
        baseClassName =
          'text-base font-normal ' + (props.noColor ? '' : 'text-slate-900 dark:text-white')
        break
      case Type['base-small']:
        baseClassName =
          'text-sm font-normal ' + (props.noColor ? '' : 'text-slate-900 dark:text-white')
        break
      case Type.info:
        baseClassName =
          'text-sm font-normal ' + (props.noColor ? '' : 'text-slate-500 dark:text-white')
        break
      case Type['info-small']:
        baseClassName =
          'text-xs font-normal ' + (props.noColor ? '' : 'text-slate-500 dark:text-white')
        break
    }

    return baseClassName
  }

  const className: string = defaultClassName() + ' ' + (props.class || '')

  return () => (
    <>
      <span class={className} {..._.omit(props, 'class', 'type', 'noColor', 'style')}>
        {slots.default?.()}
      </span>
    </>
  )
})

export const Title = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type.title}>
      {slots.default?.()}
    </Text>
  )
})

export const Subtitle = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type.subtitle}>
      {slots.default?.()}
    </Text>
  )
})

export const SectionTitle = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type.section}>
      {slots.default?.()}
    </Text>
  )
})

export const SectionTitleSmall = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type['section-small']}>
      {slots.default?.()}
    </Text>
  )
})

export const Base = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type.base}>
      {slots.default?.()}
    </Text>
  )
})

export const BaseSmall = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type['base-small']}>
      {slots.default?.()}
    </Text>
  )
})

export const Strong = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type['base-strong']}>
      {slots.default?.()}
    </Text>
  )
})

export const Info = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type.info}>
      {slots.default?.()}
    </Text>
  )
})

export const InfoSmall = defineJsxComponent<Props>((props, { slots }) => {
  return () => (
    <Text {...props} type={Type['info-small']}>
      {slots.default?.()}
    </Text>
  )
})
