import Image from '@/atoms-react/image/Image.react'
import { Modal } from '@/atoms-react/modal/index.react'
import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ModalKypayOnChainExplanationProps = {
  open: boolean
  onClose: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const ModalKypayOnChainExplanation = ({
  open,
  onClose
}: ModalKypayOnChainExplanationProps) => {
  const { t } = useTranslation(['pass'])
  return (
    <Modal open={open} closable onClose={onClose} className="sm:w-full w-full max-w-lg">
      <div className="flex flex-col h-[600px]">
        <div className="bg-white h-2/5 md:h-1/2 w-full rounded-sm mb-6 p-6 overflow-hidden flex items-center justify-center">
          <Image src="/images/olkypass/kypay-onchain.png" alt="kypay onChain illustration" />
        </div>
        <CardTitle className="text-center mb-4">
          {t('olkypass.KypayPage.explanationKypayOnChainTitle')}
        </CardTitle>
        <Base className="text-center text-slate-400 dark:text-slate-400">
          {t('olkypass.KypayPage.explanationKypayOnChainContent')}
        </Base>
      </div>
    </Modal>
  )
}
