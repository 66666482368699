import { defineStore } from 'pinia'
import type { RegisterCredentialType } from './types'

export const RegisterStore = defineStore('RegisterStore', {
  state: (): {
    credentials: RegisterCredentialType | null
    encryptedData: any | null
    attempts: number
  } => {
    return { credentials: null, encryptedData: null, attempts: 0 }
  },
  actions: {
    setCredentials(credentials: RegisterCredentialType | null) {
      this.credentials = credentials
    },
    setEncryptedData(encryptedData: string | null) {
      this.encryptedData = encryptedData
    },
    getAttempts() {
      return this.attempts
    },
    setAttempts(attempts: number) {
      this.attempts = attempts
    }
  }
})
