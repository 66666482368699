import { useQuery } from '@tanstack/react-query'
import { SupportApiClient } from '../api-client/api-client'

export const useSupport = () => {

  const SupportUrl = () =>
    useQuery({
      queryKey: ['support', 'url'],
      queryFn:  () => SupportApiClient.getSupportUrl()
    })

  return {
    SupportUrl
  }
}
