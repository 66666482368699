import NotLoggedLayoutVue from '@/app/login/Layout.vue'
import { LoginRouterChildren, LoginRoutes } from '@/app/login/router'
import OlkyPassVueToReact from '@/app/olkypass/VueToReact'
import { OlkyPassRoutes } from '@/app/olkypass/utils/routes'
import OlkypayLayoutVue from '@/app/olkypay/Layout.vue'
import { OlkyPayRouterChildren } from '@/app/olkypay/router'
import config from '@/config'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { getJwt } from './auth/utils'
import { reactNavigate } from './utils/hooks/use-expose-react-router'

export const Routes = {
  ...[] /* OlkypayRoutes */,
  ...OlkyPassRoutes,
  ...LoginRoutes
}

const olkypayMode = !config.OLKYPASS_MODE

/*const loginRoute = olkypayMode ? '/pay/login' : '/sign-in'*/
const loginRoute = Routes.flow + '/-/welcome'
const clientHomeRoute = olkypayMode ? '/pay/suppliers' : OlkyPassRoutes.dashboard

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/pass',
      children: [
        {
          path: '/:pathMatch(.*)*',
          component: OlkyPassVueToReact,
          meta: {
            noIonic: true,
            public: true
          }
        }
      ],
      meta: {
        noIonic: true
      }
    },
    {
      path: Routes.flow,
      children: [
        {
          path: '/:pathMatch(.*)*',
          component: OlkyPassVueToReact,
          meta: {
            noIonic: true,
            public: true
          }
        }
      ],
      meta: {
        noIonic: true,
        public: true
      }
    },
    {
      path: '/pay',
      // When pay can join common layout, uncomment this line
      component: OlkypayLayoutVue,
      children: OlkyPayRouterChildren
    },
    {
      path: '/sign-in',
      component: NotLoggedLayoutVue,
      children: LoginRouterChildren,
      name: 'LoginParent',
      meta: {
        noIonic: true,
        public: true
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: loginRoute
    }
  ]
})

router.beforeEach((to, _from, next) => {
  reactNavigate(to.fullPath)

  const isPublic = to.matched.some((record) => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut)
  const onlyWhenAuthenticating = to.matched.some((record) => record.meta.onlyWhenAuthenticating)
  const loggedIn = !!getJwt().token

  if (!isPublic && !loggedIn) {
    return next({
      path: loginRoute,
      query: { redirect: to.fullPath }
    })
  }

  if ((loggedIn && onlyWhenLoggedOut) || (loggedIn && onlyWhenAuthenticating)) {
    return next(clientHomeRoute)
  }

  next((vm) => {
    vm.$el.innerHTML = ''
  })
})

export default router
