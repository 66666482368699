import { Routes } from '@/features/router'
import { InputOTP } from '@/atoms/inputs/Otp'
import { Button } from '@/atoms/buttons/Button'
import { Link } from '@/atoms/link/Link2'
import { BaseSmall } from '@/atoms/text/Text.tsx'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import { ref } from 'vue'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'
import { useLogin as useAuth } from '@/features/auth/hooks/use-login-vue'

export const FormTwoFaView = defineVueComponent(() => {
  const { t } = useI18n()
  const { loader, user, verifyIdentityTwoFa } = useAuth()
  const challenge = ref('')
  const username = ref(user.credential!.username)
  const password = ref(user.credential!.password)
  return () => (
    <div class="mt-6 text-left">
      <LabelDecoration class="my-4">
        {{
          input: (input: any) => (
            <div class="w-full max-w-xs mx-auto">
              <InputOTP
                v-model={challenge.value}
                class={input.class}
                size="lg"
                length={6}
                separator={3}
                prefix=""
                onComplete={(code: string) =>
                  verifyIdentityTwoFa(false, username.value, password.value, code)
                }
              />
            </div>
          )
        }}
      </LabelDecoration>

      <div class="flex flex-row items-center w-full mt-6">
        <BaseSmall>
          <Link to={Routes.login}>{t('olkypass.twoFaPage.button.cancel')}</Link>
        </BaseSmall>

        <div class="grow" />

        <Button
          theme="primary"
          size="lg"
          disabled={!challenge.value}
          loading={loader.loading}
          onClick={() =>
            verifyIdentityTwoFa(false, username.value, password.value, challenge.value)
          }
        >
          {t('olkypass.twoFaPage.button.next')}
        </Button>
      </div>
    </div>
  )
})
