<template>
  <ion-page>
    <ion-content id="loginPage" :fullscreen="true">
      <!--      <language-switcher></language-switcher>-->
      <login-header :title="$t('olkypay.labels.loginPage.connect_to_olky')"></login-header>
      <div class="v-container--fluid">
        <ion-grid>
          <ion-row class="ion-justify-content-center ion-margin-top ion-align-items-center">
            <ion-col>
              <ion-input
                v-model="form.username"
                :label="$t('olkypay.inputs.loginPage.username')"
                label-placement="floating"
                :autofocus="true"
                :clear-input="true"
                fill="outline"
                :auto-focus="true"
              ></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item lines="none" class="border-row border-1 border-radius-md min-height-56">
                <ion-input
                    v-model="form.password"
                    :label="$t('olkypay.inputs.loginPage.password')"
                    label-placement="floating"
                    :autofocus="true"
                    :type="showPassword ? 'text' : 'password'"
                    :clear-input="true"
                >
                </ion-input>
                <ion-icon
                    slot="end"
                    :icon="showPassword ? eye: eyeOff"
                    color="medium"
                    @click="togglePasswordVisibility()"
                ></ion-icon>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-2">
            <ion-col size="10" class="px-2">
              <ion-checkbox v-model="remember_me"  label-placement="end"  @update:model-value="manipulateRememberMe">{{
                $t('olkypay.labels.loginPage.remember_me')
              }}</ion-checkbox>
            </ion-col>
            <ion-col size="2">
              <ion-icon :icon="alertCircle" color="medium" size="large" @click="openInfoModal"></ion-icon>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-button
                class="continue-button large-button"
                :disabled="!form.username || !form.password"
                @click="handleLogin"
                >{{ $t('olkypay.buttons.loginPage.standard.continue') }}
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <a href="/pay/reset_password" class="link-routers">{{
                $t('olkypay.linkers.loginPage.forget_password')
              }}</a>
            </ion-col>
          </ion-row>

          <ion-row class="mt-2 mb-3" v-if="showFingerPrintIcon"  @click="handleLoginViaFingerPrint">
            <ion-col class="text-center">
              <ion-icon :icon="fingerPrintOutline" color="blue_olky" size="large"></ion-icon>
              </ion-col>
          </ion-row>

        </ion-grid>
      </div>
      <loader></loader>
    </ion-content>
    <info-modal ref="infoModal"></info-modal>
    <biometric-verification-process ref="biometricVerificationProcess"></biometric-verification-process>
  </ion-page>
</template>

<script lang="ts">
import { eye, eyeOff,alertCircle, fingerPrintOutline} from 'ionicons/icons'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  IonInput,
  IonRouterOutlet,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
  alertController,
  IonLoading,
  IonCheckbox
} from '@ionic/vue'
// import {logoIonic} from 'ionicons/icons';
import olkyPayLogoWhite from '../../../../public/images/olkypay/logo-olkypay-header.png'
import LanguageSwitcher from '../components/LanguageSwitcher.vue'
import { mapActions, mapGetters } from 'vuex'
import Loader from '../components/Loader.vue'
import LoginHeader from '../components/security/loginHeader.vue'
import InfoModal from '../components/InfoModal.vue'
import {Capacitor} from "@capacitor/core";
import BiometricVerificationProcess from "../components/security/biometricVerificationProcess.vue";

export default {
  name: 'LoginPage',
  components: {
    BiometricVerificationProcess,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonRadio,
    IonRadioGroup,
    IonInput,
    IonImg,
    IonButton,
    IonRouterOutlet,
    alertController,
    IonLoading,
    IonCheckbox,
    LanguageSwitcher,
    LoginHeader,
    Loader,
    InfoModal
  },
  data() {
    return {
      eye,
      eyeOff,
      alertCircle,
      fingerPrintOutline,
      connection_mode: 'phone',
      form: {
        username: '',
        password: ''
      },
      showPassword: false,
      remember_me: false
    }
  },
  methods: {
    ...mapActions('auth', ['signIn', 'signInViaFingerPrint']),
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    ...mapActions('user', ['setUsername']),
    async manipulateRememberMe() {},
    async handleLogin() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.loginPage.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      this.setUsername(this.form.username)
      this.signIn(this.form)
        .then(async (res) => {
          if (!this.remember_me) {
            this.form.username = ''
            this.form.password = ''
          }
          if (res) {
            this.$router.push({ name: 'SuppliersRouter' })
          } else {
            this.$router.push({ name: 'TwoFactoryRouter' })
          }
        })
        .catch(async (err: any) => {
          this.stopLoader()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t('olkypay.notifications.error.loginPage.header'),
            content: this.$i18n.t('olkypay.notifications.error.loginPage.message'),
            button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
          }
          await this.$refs.infoModal.openModal(failedDialog)
        })
        .finally(() => {
          this.stopLoader()
        })
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    async handleLoginViaFingerPrint() {
        await this.$refs.biometricVerificationProcess.handleLoginViaFingerPrint();
    },
    async openInfoModal() {
      const infoDialog = {
        type: 'info',
        title: this.$i18n.t('olkypay.notifications.info.loginPage.remember_me.header'),
        content: this.$i18n.t('olkypay.notifications.info.loginPage.remember_me.content'),
        button: this.$i18n.t('olkypay.buttons.cardManagerPage.infos_understand')
      }
      await this.$refs.infoModal.openModal(infoDialog)
    },
  },
  async mounted() {},
  computed: {
    ...mapGetters('auth', ['authenticating', 'authenticationError', 'authenticationErrorCode']),
    ...mapGetters('user', ['user_settings']),
    olkyPayLogoWhite() {
      return olkyPayLogoWhite
    },
    showFingerPrintIcon() {
      return (Capacitor.isNativePlatform() && (this.user_settings.authentication_method === 'finger_print' || this.user_settings.authentication_method === 'face_id'))
    }
  }
}
</script>

<style scoped></style>
