const state = {
    isLoading: false,
    loaderModalDetails: {},
    isSkeletonLoading: false,
};
const getters = {
    isLoading: (state: { isLoading: any }) => {
        return state.isLoading;
    },
    loaderModalDetails: (state: { loaderModalDetails: any }) => {
        return state.loaderModalDetails;
    },
    isSkeletonLoading: (state: { isSkeletonLoading: any }) => {
        return state.isSkeletonLoading;
    }
};
const actions = {
    playLoader(context: any, loaderModalDetails: any) {
        context.commit('playLoader', loaderModalDetails)
    },
    stopLoader(context: any) {
        context.commit('stopLoader')
    },
    playSkeletonLoader(context: any) {
        context.commit('playSkeletonLoader')
    },
    stopSkeletonLoader(context: any) {
        context.commit('stopSkeletonLoader')
    }
}
const mutations = {
    playLoader(state: {
        isLoading: boolean,
        loaderModalDetails: any
    },loaderModalDetails: any) {
        state.isLoading = true;
        state.loaderModalDetails = loaderModalDetails;
    },
    stopLoader(state: {
        isLoading: boolean;
    }) {
        state.isLoading = false;
    },
    playSkeletonLoader(state: {
        isSkeletonLoading: boolean;
    }) {
        state.isSkeletonLoading = true;
    },
    stopSkeletonLoader(state: {
        isSkeletonLoading: boolean;
    }) {
        state.isSkeletonLoading = false;
    },

}
export const loader = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};