import { Loader } from '@/atoms/loader/Loader'
import _ from 'lodash'
import { computed } from 'vue'
import { defineVueComponent } from '../../jsx-utils'

type ButtonProps = {
  theme?: 'primary' | 'secondary' | 'danger' | 'default' | 'outlined' | 'invisible'
  size?: 'sm' | 'md' | 'lg' | 'xl'
  loading?: boolean
  disabled?: boolean
  icon?: any
  class?: string
  onClick?: (e: Event) => void
} & Partial<HTMLButtonElement>

export const Button = defineVueComponent(
  (props: ButtonProps, { attrs, slots }: any) =>
    () => {
      props = attrs //https://vuejs.org/api/general#function-signature
      const hasChildren = slots.default !== undefined

      const computedClass = computed(() => {
        const defaultRing =
          'hover:ring-4 ring-blue-100 dark:ring-slate-700 focus:ring-4 focus:ring-blue-200 dark:focus:ring-slate-800 '
        let colors =
          defaultRing +
          'text-white bg-blue-500 hover:bg-blue-400 active:bg-blue-500 border-transparent drop-shadow-sm '

        if (props.theme === 'secondary')
          colors =
            defaultRing +
            'text-blue-500 bg-blue-100 dark:bg-opacity-20 dark:hover:bg-opacity-50 dark:active:bg-opacity-75 dark:text-white hover:bg-blue-200 active:bg-blue-300 border-transparent '

        if (props.theme === 'danger')
          colors =
            'hover:ring-4 ring-rose-100 dark:ring-rose-500 dark:ring-opacity-20 focus:ring-4 focus:ring-rose-200 dark:focus:ring-rose-500 dark:focus:ring-opacity-10 text-white bg-rose-500 hover:bg-rose-600 active:bg-rose-700 border-transparent '

        if (props.theme === 'default')
          colors =
            defaultRing +
            'text-slate-900 border border-solid bg-white border-slate-200 hover:border-blue-400 active:border-blue-500 dark:active:border-slate-500 dark:bg-slate-800 dark:hover:bg-slate-700 dark:active:bg-slate-700 dark:text-white dark:border-slate-600'

        if (props.theme === 'invisible')
          colors =
            'text-slate-900 bg-transparent hover:bg-blue-50 active:bg-blue-100 dark:hover:bg-slate-700 dark:active:bg-slate-700 dark:text-white'

        if (props.theme === 'outlined')
          colors =
            defaultRing +
            'text-blue-500 bg-transparent hover:border-blue-400 active:border-blue-500 dark:bg-slate-900 dark:hover:bg-slate-900 dark:active:bg-slate-900 hover:bg-slate-50 hover:bg-opacity-50 active:bg-opacity-100 border-blue-500 border-solid	'

        if (props.disabled || props.loading) colors += ' opacity-50 pointer-events-none'

        let className = colors

        if (props.size === 'xl') className = className + ' text-base h-14 px-12 '
        else if (props.size === 'lg') className = className + ' text-base h-10 px-5 '
        else if (props.size === 'sm') className = className + ' px-4 text-sm h-8 px-3'
        else className = className + ' px-4 text-base h-9'

        if (!hasChildren) {
          if (props.size === 'lg') className = className + ' w-11 !p-0 justify-center'
          else if (props.size === 'sm') className = className + ' w-8 !p-0 justify-center'
          else className = className + ' w-9 !p-0 justify-center'
        }

        return `transition-all focus:transition-none align-middle rounded-full whitespace-nowrap overflow-hidden text-ellipsis inline-flex items-center justify-center py-2 border text-sm font-medium focus:outline-none ${className} ${props.class}`
      })

      const handleClick = (e: any) => props.onClick?.(e)

      const iconClass = 'w-5 h-5 shrink-0 ' + (hasChildren ? '-ml-1 mr-2' : '-mx-2')
      const IconComponent = props.icon
      const isDisabled = computed(() => props.disabled || props.loading)

      return (
        <button
          {..._.omit(attrs, 'class', 'disabled', 'onClick')}
          class={computedClass.value}
          disabled={isDisabled.value}
          onClick={handleClick}
        >
          {props.loading && <Loader class="-ml-1 mr-2 h-5 w-5 shrink-0" />}
          {!props.loading && <IconComponent class={iconClass} />}
          {slots.default?.()}
        </button>
      )
    },
  {
    inheritAttrs: false
  }
)
