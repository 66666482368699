import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
const uris = {
  _post: {
    change_language: '/change_language'
  }
}
const state = {
  currentLanguage: 'fr'
}

const getters = {
  currentLanguage: (state) => state.currentLanguage
}

const mutations = {
  async SET_CURRENT_LANGUAGE(state, language) {
    state.currentLanguage = language
    window.localStorage.setItem('language', language)
  }
}

const actions = {
  async setCurrentLanguage({ commit }, language) {
    await fetchServer(uris._post.change_language, { body: { language: language } })
    commit('SET_CURRENT_LANGUAGE', language)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
