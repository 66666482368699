<template>
  <ion-page id="SuppliersPage" class="tracking-normal">
    <ion-content v-if="!isSkeletonLoading" :fullscreen="true">
      <div class="header-app height-111">
        <ion-grid>
          <ion-row class="mt-4">
            <ion-col size="1" class="mt-2">
              <div class="circleIconMenu">
                <ion-icon :icon="close" color="light" size="large" @click="handleLogout"></ion-icon>
              </div>
            </ion-col>
            <ion-col class="ion-margin-horizontal mt-1">
              <h1 class="font-weight-bold">{{ $t('olkypay.labels.suppliersPage.my_accounts') }}</h1>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <ion-grid class="mt-2 mb-1">
        <ion-row v-for="supplier in suppliers">
          <ion-col>
            <supplier :supplier="supplier"></supplier>
          </ion-col>
        </ion-row>
      </ion-grid>
      <biometric-verification-process
        ref="biometricVerificationProcess"
      ></biometric-verification-process>
    </ion-content>
    <full-skeleton v-else></full-skeleton>
    <loader></loader>
  </ion-page>
</template>

<script lang="ts">
import LanguageSwitcher from '../components/LanguageSwitcher.vue'
import Supplier from '../components/suppliers/supplier.vue'
import FullSkeleton from '../components/skeleton/FullSkeleton.vue'
import { mapActions, mapGetters } from 'vuex'
import Loader from '../components/Loader.vue'
import BiometricVerificationProcess from '../components/security/biometricVerificationProcess.vue'
import {
  alertController,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSearchbar,
  IonAccordion,
  IonAccordionGroup,
  IonLabel
} from '@ionic/vue'
import { close } from 'ionicons/icons'
export default {
  name: 'SuppliersPage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    alertController,
    IonLoading,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonSearchbar,
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    LanguageSwitcher,
    Supplier,
    FullSkeleton,
    Loader,
    BiometricVerificationProcess
  },
  data() {
    return {
      close
    }
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions('loader', ['playSkeletonLoader', 'stopSkeletonLoader']),
    ...mapActions('supplier', ['loadSuppliers']),
    ...mapActions('user', ['setAuthenticationMethod', 'setSecurityInfos']),
    async handleLogout() {
      this.playSkeletonLoader()
      this.signOut()
        .then((res) => {
          this.$router.push({name: 'LoginParent'})
        })
        .catch(async (err: any) => {
          const errorAlert = await alertController.create({
            header: 'Failed',
            subHeader: 'Sign out Failed',
            message: err,
            buttons: ['OK']
          })
          await errorAlert.present()
        })
        .finally(() => {
          this.stopSkeletonLoader()
        })
    }
  },

  async mounted() {
    this.playSkeletonLoader()
    await this.loadSuppliers()
      .then(async (res) => {})
      .catch(async (err: any) => {
        await this.handleLogout()
      })
      .finally(() => {
        this.stopSkeletonLoader()
      })
    await this.$refs.biometricVerificationProcess.tryToSaveFingerPrint()
    await this.setSecurityInfos()
  },
  computed: {
    ...mapGetters('supplier', ['suppliers']),
    ...mapGetters('loader', ['isSkeletonLoading']),
    ...mapGetters('user', ['user_settings', 'username'])
  }
}
</script>

<style scoped></style>
