import { Button } from '@/atoms/buttons/Button'
import { Input } from '@/atoms/inputs/Input'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import { Link } from '@/atoms/link/Link2'
import { BaseSmall } from '@/atoms/text/Text.tsx'
import { useLogin as useAuth } from '@/features/auth/hooks/use-login-vue'
import { Routes } from '@/features/router'
import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = { id: string; token: string; language: string } & VModelProps<null>

export const FormResetPasswordView = defineJsxComponent<Props>((props) => {
  const { t } = useI18n()
  const { loader, resetPassword } = useAuth()
  const credentials = ref({
    password: '',
    confirm_password: '',
    id: props.id,
    token: props.token,
    language: props.language
  })
  return () => (
    <div class="mt-6 text-left">
      <LabelDecoration class="my-4" label={t('login.forgetPasswordPage.input.new_pwd')}>
        {{
          input: (input: any) => (
            <Input
              size="lg"
              type="password"
              placeholder="••••••••"
              autocomplete="new-password"
              disabled={loader.loading}
              class={input.class}
              v-model={credentials.value.password}
            />
          )
        }}
      </LabelDecoration>

      <LabelDecoration class="my-4" label={t('login.forgetPasswordPage.input.confirm_new_pwd')}>
        {{
          input: (input: any) => (
            <Input
              size="lg"
              type="password"
              placeholder="••••••••"
              autocomplete="new-password"
              disabled={loader.loading}
              class={input.class}
              v-model={credentials.value.confirm_password}
            />
          )
        }}
      </LabelDecoration>

      <div class="flex flex-row items-center w-full mt-6">
        <BaseSmall>
          <Link to={Routes.login}>{t('login.forgetPasswordPage.button.cancel')}</Link>
        </BaseSmall>

        <div class="grow" />

        <Button
          theme="primary"
          size="lg"
          disabled={!credentials.value}
          loading={loader.loading}
          onClick={() => resetPassword(credentials.value)}
        >
          {t('login.forgetPasswordPage.button.next')}
        </Button>
      </div>
    </div>
  )
})
