export const KycoinIcon = (props: {
  size?: number
  color?: string
  style?: any
  className?: string
}) => {
  return (
    <div className={props.className} style={props.style}>
      <img src="/images/olkypass/kycoin.png" width="100%" height="100%" alt="KYCOIN" />
    </div>
  )
}
