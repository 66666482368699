<template>
  <ion-page id="ChoiceTypeTransferPage">
    <ion-content :fullscreen="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/pay/supplier/transfer"
            :icon="caretBackSharp"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>
          <h1>{{ $t('olkypay.labels.transferPage.one_time_tab.header') }}</h1>
        </ion-title>
      </ion-toolbar>
      <ion-grid class="">
        <ion-row>
          <ion-col>
            <div class="card border border-radius-lg bg-gray-200 card-plain">
              <div class="flex-column">
                <h4 class="text-dark text-md p-1">
                  {{
                    $t('olkypay.labels.transferPage.one_time_tab.steps.choice_type.info_message')
                  }}
                </h4>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="card card-plain">
              <div class="card-header pb-0 px-3">
                <h2 class="mb-0">
                  {{
                    $t('olkypay.labels.transferPage.one_time_tab.steps.choice_type.question_type')
                  }}
                </h2>
              </div>
              <div class="card-body mt-3">
                <div v-for="transferType in transferTypes">
                  <transfer-type :transfer-type="transferType"></transfer-type>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-fab v-if="currentTransfer.typeVirement" slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="blue_olky" @click="openNextStep">
          <ion-icon :icon="chevronForwardCircle"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import HeaderTabs from '../../../../components/HeaderTabs.vue'
import Stepper from '../../../../components/transfer/stepper.vue'
import TransferType from '../../../../components/transfer/transferType.vue'

import {
  IonTitle,
  IonContent,
  IonPage,
  IonIcon,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  IonFab,
  IonFabButton,
  IonBackButton,
  IonToolbar,
  IonButtons
} from '@ionic/vue'
import {
  handRight,
  calendarClearSharp,
  alertCircleOutline,
  timer,
  time,
  chevronForwardCircle
} from 'ionicons/icons'
import { caretBackSharp, alertCircle } from 'ionicons/icons'
import { mapGetters } from 'vuex'

export default {
  name: 'ChoiceTypeTransfer',
  components: {
    IonTitle,
    IonContent,
    IonPage,
    IonIcon,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonFab,
    IonFabButton,
    IonBackButton,
    IonToolbar,
    IonButtons,
    HeaderTabs,
    Stepper,
    TransferType
  },
  data() {
    return {
      handRight,
      calendarClearSharp,
      alertCircleOutline,
      timer,
      time,
      chevronForwardCircle,
      caretBackSharp,
      transferTypes: [
        {
          name: 'classic',
          icon: calendarClearSharp,
          title: 'olkypay.labels.transferPage.one_time_tab.steps.choice_type.classic_transfer.title',
          description:
            'olkypay.labels.transferPage.one_time_tab.steps.choice_type.classic_transfer.description'
        },
        {
          name: 'deferred',
          icon: time,
          title: 'olkypay.labels.transferPage.one_time_tab.steps.choice_type.deferred_transfer.title',
          description:
            'olkypay.labels.transferPage.one_time_tab.steps.choice_type.deferred_transfer.description'
        },
        {
          name: 'instant',
          icon: timer,
          title: 'olkypay.labels.transferPage.one_time_tab.steps.choice_type.instant_transfer.title',
          description:
            'olkypay.labels.transferPage.one_time_tab.steps.choice_type.instant_transfer.description'
        }
      ]
    }
  },
  methods: {
    openNextStep() {
      this.$router.push({ name: 'ReasonTransferSubTabRouter' })
    }
  },
  computed: {
    ...mapGetters('transfer', ['currentTransfer'])
  }
}
</script>

<style scoped></style>
