<template>
  <div class="card border mb-2">
    <ion-row>
      <ion-col size="1" >
        <ion-checkbox
v-if="isEditable" v-model="isSelectedOrder" :disabled="!isEditable"
                      :value="order.id" class="mt-3" @update:model-value="updateSelectedOrdersList"></ion-checkbox>
      </ion-col>
      <ion-col size="11" @click="goToRouter">
        <ion-row>
          <ion-col size="7">
            <h4 class="mt-2 mb-0 text-dark">{{ order.id }} - {{ order.category }}</h4>
            <!--          <h5 class="text-md mt-0 mb-0">{{ order.comment }}</h5>-->
            <span class="text-xs text-gray">{{ order.comment }}</span>
          </ion-col>

          <ion-col size="3">
            <div class="text-danger text-gradient text-sm font-weight-bold mt-2">
              - € {{ new Intl.NumberFormat('de-DE', {  minimumFractionDigits: 2,maximumFractionDigits: 2,}).format(order.nominalAmount / 100) }}
            </div>
          </ion-col>
          <ion-col size="1" class="mt-2">
            <ion-icon :icon="iconSwitchStatus" :color="iconColorSwitchStatus"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </div>
</template>

<script lang="ts">
import {
  IonRow, IonCol, IonIcon, IonImg, IonCheckbox
} from '@ionic/vue';
import {home, hourglass, timer, removeCircle, logoEuro, checkmarkCircle, basket} from 'ionicons/icons';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Order",
  components: {
    IonRow, IonCol, IonIcon, IonImg, IonCheckbox
  },
  props: [
    "order"
  ],
  data() {
    return {
      home, hourglass, timer, removeCircle, logoEuro, checkmarkCircle, basket,
      isSelectedOrder: false
    }
  },
  methods: {
    ...mapActions("transaction", ["manipulateSelectedOrders","setCurrentOrder"]),
    updateSelectedOrdersList() {
      const data = {operation: this.isSelectedOrder ? "add" : "remove", id: this.order.id}
      this.manipulateSelectedOrders(data)
    },
    goToRouter() {
      this.setCurrentOrder(this.order);
      this.$router.push({name: "OrderDetailsSubTabRouter"})
    }
  },
  computed: {
    iconSwitchStatus() {
      switch (this.order.orderStatusId) {
        case 1:
          return this.checkmarkCircle
          break;
        case 3:
          return this.removeCircle
          break;
        case 4:
          return this.hourglass
          break;
        case 5:
          return this.timer
          break;
        default:
          return this.basket;
      }
    },
    iconColorSwitchStatus() {
      switch (this.order.orderStatusId) {
        case 1:
          return "success"
          break;
        case 3:
          return "danger"
          break;
        case 4:
          return "secondary"
          break;
        case 5:
          return "warning"
          break;
        default:
          return "tertiary"
      }
    },
    isEditable() {
      switch (this.order.orderStatusId) {
        case 4:
          return true
          break;
        default:
          return false
      }
    }
  }
}
</script>

<style scoped>

</style>