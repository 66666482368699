<template>
  <ion-page id="ValidationTransferPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.transferPage.one_time_tab.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
        <!--        <stepper :current-step="4"></stepper>-->
        <ion-row class="mt-1 mb-3">
          <ion-col>
            <h2 class="mb-0">{{ $t('olkypay.labels.transferPage.validation_step.summary') }}</h2>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="card card-summary">
              <div class="card-header">
                <ion-row>
                  <ion-col size="2" class="mt-3">
                    <div class="circleIcon">
                      <i class="logoOlky"> <ion-img :src="olkypayIcon"></ion-img></i>
                    </div>
                  </ion-col>
                  <ion-col>
                    <h4 class="text-white mt-3 mb-0 font-weight-bold">
                      {{ $t('olkypay.labels.transferPage.one_time_tab.sender_account') }}
                    </h4>
                    <span class="text-xs text-white">{{ formattedSupplierIBAN }}</span>
                  </ion-col>
                </ion-row>
                <hr class="horizontal light mt-3 mb-2" />
              </div>
              <div class="card-body mx-2">
                <ion-row>
                  <ion-col>
                    <h4 class="text-white mt-2 mb-0 font-weight-bold">
                      {{ $t('olkypay.labels.transferPage.beneficiary_account') }}
                    </h4>
                    <span class="text-xs text-white">{{ formattedBeneficiaryIBAN }}</span>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <div class="big-amount">
                      <h1 class="text-dark text-center">{{ formatAmount }}</h1>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <h4 class="mb-0 font-weight-normal text-white">
                      {{
                        $t(
                          'olkypay.labels.transferPage.one_time_tab.steps.choice_type.transfer_type'
                        )
                      }}
                    </h4>
                    <h4 class="mb-0 mt-0 text-white font-weight-bold">
                      {{ $t(currentTransfer.title) }}
                    </h4>
                  </ion-col>
                </ion-row>

                <div v-if="currentTransfer.typeVirement === 'rec'">
                  <ion-row class="">
                    <ion-col>
                      <h4 class="mb-0 font-weight-normal text-white">
                        {{ $t('olkypay.inputs.transferPage.recurrent_tab.recurrence') }}
                      </h4>
                      <h4 class="mb-0 mt-0 text-white font-weight-bold">{{ formatFrequency }}</h4>
                    </ion-col>
                  </ion-row>
                </div>
                <ion-row class="">
                  <ion-col>
                    <h4 class="mb-0 font-weight-normal text-white">
                      {{
                        currentTransfer.typeVirement === 'rec'
                          ? $t('olkypay.inputs.transferPage.recurrent_tab.start_date')
                          : $t(
                              'olkypay.inputs.transferPage.one_time_tab.steps.choice_type.classic_transfer.transfer_date.label'
                            )
                      }}
                    </h4>
                    <h4 class="mb-0 mt-0 text-white font-weight-bold">{{ formatExecutionDate }}</h4>
                  </ion-col>
                </ion-row>
                <div v-if="currentTransfer.typeVirement === 'rec' && currentTransfer.toDate">
                  <ion-row class="">
                    <ion-col>
                      <h4 class="mb-0 font-weight-normal text-white">
                        {{ $t('olkypay.inputs.transferPage.recurrent_tab.end_date') }}
                      </h4>
                      <h4 class="mb-0 mt-0 text-white font-weight-bold">{{ formatUntilDate }}</h4>
                    </ion-col>
                  </ion-row>
                </div>
                <ion-row class="mb-4">
                  <ion-col>
                    <h4 class="mb-0 font-weight-normal text-white">
                      {{
                        $t(
                          'olkypay.inputs.transferPage.one_time_tab.steps.choice_type.classic_transfer.comment.label'
                        )
                      }}
                    </h4>
                    <h4 class="mb-0 mt-0 text-white font-weight-bold">
                      {{ currentTransfer.comment }}
                    </h4>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="blue_olky">
          <ion-icon :icon="settingsOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button color="success" @click="setOpenConfirmationModal(true)">
            <ion-icon :icon="checkmarkCircleOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button color="light" @click="gotoRouter">
            <ion-icon :icon="backspaceOutline"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-action-sheet
        :is-open="isConfirmationModalOpen"
        :header="$t('olkypay.labels.transferPage.confirm_dialog_create_order.header')"
        :sub-header="$t('olkypay.labels.transferPage.confirm_dialog_create_order.sub_header')"
        :buttons="actionSheetButtons"
        @didDismiss="actionSheetResult($event)"
      ></ion-action-sheet>
    </ion-content>
    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
    <validation-code-modal ref="validationCodeModal"></validation-code-modal>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import olkypayIcon from '../../../../../../../public/images/olkypay/olkypay_icon.svg'
import { TransactionService } from '../../../../services/transaction.service'
import HeaderTabs from '../../../../components/HeaderTabs.vue'
import Stepper from '../../../../components/transfer/stepper.vue'
import AuthenticationProcessPopup from '../../../../components/security/authenticationProcessPopup.vue'
import ValidationCodeModal from '../../../../components/security/validationCodeModal.vue'
import { caretBackSharp, alertCircle, swapHorizontal } from 'ionicons/icons'
import InfoModal from '../../../../components/InfoModal.vue'
import {
  IonTitle,
  IonContent,
  IonPage,
  IonIcon,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  IonFab,
  IonFabButton,
  IonItem,
  IonInput,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonFabList,
  alertController,
  toastController,
  IonActionSheet,
  IonBackButton,
  IonToolbar,
  IonButtons
} from '@ionic/vue'
import {
  arrowDown,
  arrowBack,
  chevronDownCircle,
  chevronForwardCircle,
  chevronBackCircle,
  chevronUpCircle,
  colorPalette,
  closeCircleOutline,
  trashOutline,
  saveSharp,
  checkmarkCircleOutline,
  backspaceOutline,
  settingsOutline
} from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ValidationTransfer',
  components: {
    IonTitle,
    IonContent,
    IonPage,
    IonIcon,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonFab,
    IonFabButton,
    IonItem,
    IonInput,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonFabList,
    IonActionSheet,
    IonBackButton,
    IonToolbar,
    IonButtons,
    HeaderTabs,
    Stepper,
    AuthenticationProcessPopup,
    ValidationCodeModal,
    InfoModal
  },
  data() {
    return {
      olkypayIcon,
      arrowDown,
      chevronDownCircle,
      chevronForwardCircle,
      chevronUpCircle,
      colorPalette,
      closeCircleOutline,
      trashOutline,
      chevronBackCircle,
      saveSharp,
      checkmarkCircleOutline,
      arrowBack,
      backspaceOutline,
      caretBackSharp,
      settingsOutline,
      actionSheetButtons: [
        {
          text: this.$i18n.t('olkypay.labels.followUpPage.confirm_dialog_orders.buttons.confirm'),
          data: {
            action: 'confirm'
          }
        },
        {
          text: this.$i18n.t('olkypay.labels.followUpPage.confirm_dialog_orders.buttons.cancel'),
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        }
      ],
      isConfirmationModalOpen: false,
      paginationOptions: {
        filter:  '?s=-id&p=1'
      }
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    ...mapActions('transaction', ['initSelectedOrders', 'manipulateSelectedOrders',"loadOrders",'initOrders']),
    ...mapActions('transfer', ['setCurrentTransfer']),
    ...mapActions('beneficiary', ['setCurrentBeneficiary']),
    async actionSheetResult(ev: CustomEvent) {
      this.setOpenConfirmationModal(false)
      if (ev.detail.data.action === 'confirm') {
        await this.proceedToCreateOrders()
      }
    },
    setOpenConfirmationModal(state: boolean) {
      this.isConfirmationModalOpen = state
    },
    async proceedToCreateOrders() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      }
    },
    async onSuccessAuthenticateUser() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.createNewOrder.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      await TransactionService.createNewOrder({
        client_id: this.currentBeneficiary.id,
        transferData: this.currentTransfer
      })
        .then(async (res) => {
          this.stopLoader()
          const order_id = res.id
          const inputData = {
            locale: this.currentLanguage,
            orderIds: [{ id: order_id }],
            type: undefined
          }
          if (this.currentTransfer.typeVirement === 'rec') {
            inputData.type = 'rec'
          }

          this.initSelectedOrders()
          this.manipulateSelectedOrders({ operation: 'add', id: order_id })
          const loaderModal = {
            title: this.$i18n.t('olkypay.loader.sendOrderValidationCode.title'),
            content: this.$i18n.t('olkypay.loader.please_wait')
          }
          this.playLoader(loaderModal)
          await TransactionService.sendOrdersValidationCode(inputData)
            .then(async (res) => {
              this.stopLoader()
              // open modal
              const response_modal = await this.$refs.validationCodeModal.openValidationDialog()
              if (response_modal) {
                await this.validateOrdersWithCodeDone()
              }
            })
            .catch(async (err: any) => {
              this.stopLoader()
              const failedDialog = {
                type: 'warning',
                title: this.$i18n.t(
                  'olkypay.notifications.error.transferPage.send_link_code.header'
                ),
                content: this.$i18n.t(
                  'olkypay.notifications.error.transferPage.send_link_code.message'
                ),
                button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
              }
              await this.$refs.infoModal.openModal(failedDialog)
            })
            .finally(() => {
              this.stopLoader()
            })
        })
        .catch(async (err: any) => {
          this.stopLoader()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t('olkypay.notifications.error.transferPage.create_order.header'),
            content: this.$i18n.t('olkypay.notifications.error.transferPage.create_order.message'),
            button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
          }
          await this.$refs.infoModal.openModal(failedDialog)
        })
        .finally(() => {
          this.stopLoader()
        })
    },
    async validateOrdersWithCodeDone() {
      this.initOrders()
      await this.loadOrders({ pagination_options: this.paginationOptions })
      this.initSelectedOrders()
      await this.setCurrentBeneficiary({})
      this.setCurrentTransfer({})
      this.$router.replace({ name: 'FollowUpSubTabRouter'})
    },
    async gotoRouter() {
      await this.setCurrentBeneficiary({})
      this.setCurrentTransfer({})
      this.$router.replace({ name: 'TransferTabRouter'})
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('home', ['currentLanguage']),
    ...mapGetters('supplier', ['currentSupplier']),
    ...mapGetters('transfer', ['currentTransfer']),
    ...mapGetters('beneficiary', ['currentBeneficiary']),
    formattedSupplierIBAN() {
      if (this.currentSupplier.iban) {
        return this.currentSupplier.iban.match(/.{1,4}/g).join(' ')
      }
      return ''
    },
    formattedBeneficiaryIBAN() {
      if (this.currentBeneficiary.iban) {
        return this.currentBeneficiary.iban.match(/.{1,4}/g).join(' ')
      }
      return ''
    },
    showButtonNext() {},
    formatAmount() {
      const eurosValue = this.currentTransfer.nominalAmount / 100
      return isNaN(eurosValue)
        ? ''
        : eurosValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
          })
    },
    formatExecutionDate() {
      return this.currentTransfer.executionDate.join('-')
    },
    formatUntilDate() {
      return this.currentTransfer.toDate.join('-')
    },
    formatFrequency() {
      switch (this.currentTransfer.rec) {
        case '1':
          return this.$i18n.t('olkypay.inputs.transferPage.recurrent_tab.frequencies.monthly')
          break
        case '2':
          return this.$i18n.t('olkypay.inputs.transferPage.recurrent_tab.frequencies.quarterly')
          break
        case '3':
          return this.$i18n.t('olkypay.inputs.transferPage.recurrent_tab.frequencies.semi_annual')
          break
        case '4':
          return this.$i18n.t('olkypay.inputs.transferPage.recurrent_tab.frequencies.annual')
          break
      }
    }
  }
}
</script>

<style scoped></style>
