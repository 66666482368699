import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import i18n from '@/features/utils/i18n.ts'

const uris = {
  _post: {
    prepare_chat_user_token: '/support/chat/userToken'
  }
}
const state = {
  chat_user_token: ''
}

const getters = {
  chat_user_token: (state) => state.chat_user_token
}

const mutations = {
  async PREPARE_CHAT_USER_TOKEN(state, response) {
    state.chat_user_token = response.data.token
  }
}

const actions = {
  async prepare_chat_user_token({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.prepare_chat_user_token, { body: data })
      commit('PREPARE_CHAT_USER_TOKEN', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
