<template>
  <ion-page id="OppositionCardPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/cards"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.oppositionCardPage.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
<!--        <ion-row>
          <ion-col>
            <h4 class="text-gray text-center">{{ currentCard.fullName }}</h4>
          </ion-col>
        </ion-row>-->
        <ion-row class="mt-2">
          <ion-col class="text-center">
            <img :src="particularCard" />
            <h5 class="text-center text-gray mt-0 mb-0">{{ currentCard.fullName }}</h5>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p class="text-dark mb-0">
              {{ $t('olkypay.labels.oppositionCardPage.opposition_help_message.part_1') }}
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h2 class="text-blue-dark mt-0 text-center">
              <ion-icon :icon="call" size="small"></ion-icon>
              {{ $t('olkypay.labels.oppositionCardPage.opposition_help_message.phone_number') }}
            </h2>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p class="text-dark">
              {{ $t('olkypay.labels.oppositionCardPage.opposition_help_message.part_2') }}
              <a :href="OLKY_CGU_CARD_BUSINESS">
                 <span class="text-blue-dark">
                {{
                     $t('olkypay.labels.oppositionCardPage.opposition_help_message.general_condition')
                   }}</span>
              </a>
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="text-center">
            <a :href="OLKY_SUPPORT_LINK"  class="btn bg-gradient-info mb-0 large-button">
              {{ $t('olkypay.buttons.oppositionCardPage.contact_us') }}
            </a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import businessCard from '../../../../../../public/images/olkypay/cards/business-card.png'
import particularCard from '../../../../../../public/images/olkypay/cards/particular-card.png'
import {
  IonHeader,
  IonContent,
  IonPage,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  alertController
} from '@ionic/vue'
import { caretBackSharp, alertCircle, call } from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
import config from "@/config";

export default {
  name: 'BlockCardPage',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid
  },
  data() {
    return {
      caretBackSharp,
      alertCircle,
      call,
      businessCard,
      particularCard
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    async contactUs() {}
  },
  computed: {
    ...mapGetters('card', ['currentCard']),
    ...mapGetters('home', ['currentLanguage']),
    OLKY_CGU_CARD_BUSINESS() {
      if (this.currentLanguage === 'fr') {
        return config.OLKY_MAIN_LINK +config.OLKY_CGU_CARD_BUSINESS_FR
      } else {
        return config.OLKY_MAIN_LINK + config.OLKY_CGU_CARD_BUSINESS_EN
      }
    },
    OLKY_SUPPORT_LINK() {
      return config.OLKY_MAIN_LINK + this.currentLanguage + config.OLKY_SUPPORT_LINK
    },
  }
}
</script>
