import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import Image from '@/atoms-react/image/Image.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { useSecurity } from '@/features/security/hooks/use-react-security'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../flow-header.react'
import { A } from '@/atoms-react/a/A.react'

export type AccountSecurityWebauthnOutput = {
  valid: boolean
}

type AccountSecurityWebauthnProps = {
  onValidated: (output: AccountSecurityWebauthnOutput) => void
} & React.ComponentPropsWithoutRef<'div'>

export const AccountSecurityWebauthn = ({
  onValidated = () => {},
  ...props
}: AccountSecurityWebauthnProps) => {
  const { t } = useTranslation(['pass'])
  const [theme, setTheme] = useState('light')

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    setTheme(prefersDarkMode ? 'dark' : 'light')
  }, [])

  const { newWebauthn } = useSecurity()
  const { mutateAsync: createNewWebauthn, isPending: isPendingNewWebauthn } = newWebauthn()
  const autoDeviceName = navigator.userAgent.includes('iPhone')
    ? 'iPhone'
    : navigator.userAgent.includes('Android')
      ? 'Android'
      : navigator.userAgent.includes('Mac')
        ? 'Mac'
        : navigator.userAgent.includes('Windows')
          ? 'Windows'
          : 'No name'
  const [deviceName, setDeviceName] = useState<string>('TODO_CHANGE_ME')

  const execute = async () => {
    if (deviceName) {
      const challenge = await createNewWebauthn(deviceName)
      if (challenge) {
        toast.success(t('flow.id_control.success.account_protected'))
        onValidated({ valid: true })
      } else {
        toast.success(t('flow.id_control.error.webauthn_failed'))
        onValidated({ valid: false })
      }
    }
  }

  return (
    <Column {..._.omit(props, 'key')}>
      <div className="grow mb-12">
        <FlowHeader
          title={t('flow.id_control.signup.label.account_security_title_webauthn')}
          description={t('flow.id_control.signup.label.account_security_description_webauthn')}
          back={false}
        />

        {!autoDeviceName && (
          <InputLabel
            autoFocus
            placeholder={t('flow.id_control.webauthn.device_name')}
            className={'mb-8'}
            value={deviceName}
            onChange={(value: string) => setDeviceName(value)}
            label={t('flow.id_control.webauthn.device_name')}
          />
        )}

        <div className={'w-full flex justify-center items-center'}>
          <Image
            src={
              theme === 'dark'
                ? '/images/olkypass/touch-id-dark.svg'
                : '/images/olkypass/touch-id.svg'
            }
            className={'w-1/2'}
            alt="Touch ID"
          />
        </div>
      </div>

      <Button
        shortcut="enter"
        className="shrink-0"
        onClick={() => execute()}
        icon={ChevronRightIcon}
        loading={isPendingNewWebauthn}
      >
        {t('flow.id_control.input.continue')}
      </Button>
      <div className="my-4 flex items-center justify-center">
        <A onClick={() => onValidated({ valid: false })}>{t('flow.id_control.input.skip')}</A>
      </div>
    </Column>
  )
}
