import { fetchServer } from '@/features/utils/fetch'
import { utils } from '@passwordless-id/webauthn'
import type { AuthenticateWebAuthnResponse, CredentialInterface } from '../types'

const webauthn_uri = {
  verifyUserAuthentication: '/webauthn/login/verify_user_authentication',
  retrieveWebauthnCredential: '/webauthn/login/retrieve_user_credential',
  retrieveWebauthnAllCredentials: '/webauthn/login/retrieve_user_all_credentials',
  retrieveWebauthnActiveCredentials: '/webauthn/login/retrieve_user_active_credentials',
  authnOptions: '/webauthn/login/get_authn_options'
}

export class WebAuthnApi {
  /**
   *
   */
  static async getAuthnOptions() {
    const response = await fetchServer(webauthn_uri.authnOptions, {
      method: 'GET',
      mainRoot: true
    })
    if (response.status === 200) {
      const res = await response.json()
      return res.data as {
        challenge: any
        timeout?: number
        rpId?: string
        attestation?: any
        userVerification?: any
        rawChallenge?: ArrayBuffer
        extensions?: any
        allowCredentials?: PublicKeyCredentialDescriptor[]
      }
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid getAuthnOptions')
    }
  }

  /**
   *
   * @param email
   * @param credential_id
   * @returns
   */
  static async retrieveWebAuthnCredential(email: string, credential_id: string | undefined) {
    const response = await fetchServer(webauthn_uri.retrieveWebauthnCredential, {
      method: 'POST',
      mainRoot: true,
      body: {
        username: email,
        credential_id
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as CredentialInterface // Return valid data
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid retrieveWebAuthnCredential')
    }
  }

  /**
   *
   * @param email
   */
  static async retrieveWebAuthnAllCredentials(email: string) {
    const response = await fetchServer(webauthn_uri.retrieveWebauthnAllCredentials, {
      method: 'POST',
      mainRoot: true,
      body: {
        username: email
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as Array<CredentialInterface>
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid retrieveWebAuthnAllCredentials')
    }
  }

  /**
   *
   * @param email
   */
  static async retrieveWebAuthnActiveCredentials(email: string) {
    const response = await fetchServer(webauthn_uri.retrieveWebauthnActiveCredentials, {
      method: 'POST',
      mainRoot: true,
      body: {
        username: email
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as Array<CredentialInterface>
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid retrieveWebAuthnActiveCredentials')
    }
  }

  /**
   *
   * @param email
   * @param clientAssertionResponse
   * @param assertionExpectations
   */
  static async verifyUserAuthentication(
    email: string,
    clientAssertionResponse: any,
    assertionExpectations: any
  ) {
    const response = await fetchServer(webauthn_uri.verifyUserAuthentication, {
      method: 'POST',
      mainRoot: true,
      body: {
        username: email,
        clientAssertionResponse,
        assertionExpectations
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as {
        login: boolean
        token: string
      } // Return valid data
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid verifyUserAuthentication')
    }
  }

  /**
   *
   * @param inputBase64
   */
  static decodeBase64(inputBase64: string) {
    // Replace URL-safe characters
    let base64 = inputBase64.replace(/-/g, '+').replace(/_/g, '/')
    // Add padding if needed
    while (base64.length % 4 !== 0) {
      base64 += '='
    }
    return base64
  }

  /**
   *
   */
  static async authenticateWebAuthnUser(
    email: string
  ): Promise<AuthenticateWebAuthnResponse | null | undefined> {
    try {
      const userActiveCredentials = await WebAuthnApi.retrieveWebAuthnActiveCredentials(email)
      let authnOptions = await WebAuthnApi.getAuthnOptions()
      authnOptions.challenge = utils.toBuffer(authnOptions.challenge)
      const challenge = authnOptions.challenge

      const allowCredentials: { id: Uint8Array, type: "public-key" }[] = userActiveCredentials.map(credential => ({
        type: 'public-key',
        id: new Uint8Array(atob(WebAuthnApi.decodeBase64(credential.credential_id)).split('').map(char => char.charCodeAt(0)))
      }))

      authnOptions = {
        allowCredentials,
        ...authnOptions
      }

      const rawClientAssertionResponse = await navigator.credentials.get({
        publicKey: authnOptions,
        mediation: 'required'
      }) as unknown as {
        id: string
        rawId: ArrayBuffer
        response: any
        type: string
        authenticatorAttachment: string
      }
      const userCredential = await WebAuthnApi.retrieveWebAuthnCredential(
        email,
        rawClientAssertionResponse?.id
      )
      /**
       * i did ... but won't work
       * ...rawClientAssertionResponse,
       */
      const clientAssertionResponse = {
        authenticatorAttachment: rawClientAssertionResponse?.authenticatorAttachment,
        id: rawClientAssertionResponse?.id,
        rawId: utils.toBase64url(rawClientAssertionResponse?.rawId),
        response: {
          authenticatorData: utils.toBase64url(
            rawClientAssertionResponse?.response?.authenticatorData
          ),
          clientDataJSON: utils.toBase64url(rawClientAssertionResponse?.response?.clientDataJSON),
          signature: utils.toBase64url(rawClientAssertionResponse?.response?.signature),
          userHandle: utils.toBase64url(rawClientAssertionResponse?.response?.userHandle)
        }
      }

      const assertionExpectations = {
        challenge: utils.toBase64url(challenge),
        origin: window.location.origin,
        factor: 'either',
        publicKey: userCredential.credential_key.credentialPublicKeyPem,
        prevCounter: userCredential.credential_key.counter,
        userHandle: utils.toBase64url(rawClientAssertionResponse?.response?.userHandle)
      }
      const authnResult = await WebAuthnApi.verifyUserAuthentication(
        email,
        clientAssertionResponse,
        assertionExpectations
      )
      return authnResult?.token ?? null
    } catch (error) {

      console.error(error)
      throw new Error("Cannot connect to webAuthn");
    }
  }
}
