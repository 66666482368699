import languageModule from '@/app/olkypass/views/_deprecated/vue/store/modules/languageModule.js'
import { Button } from '@/atoms/buttons/Button'
import { Input } from '@/atoms/inputs/Input'
import { Option, Select } from '@/atoms/inputs/Select'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import { useCompany } from '@/features/company/hooks/use-company'
import { CompanyDTO } from '@/features/company/types'
import { UserStaticDataStore } from '@/features/user/store'
import { AddressType as Address } from '@/features/user/types'
import { getLanguage } from '@/features/utils/i18n'
import { defineJsxComponent } from '@/jsx-utils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import AnimatedHeightVue from '../../../../../../atoms/animated-height/AnimatedHeight.vue'

export const CompanyForm = defineJsxComponent<{
  onComplete: () => void
}>((_, { attrs }) => {
  const t = useI18n().t
  const language = getLanguage()
  const isFormValid = computed(
    () =>
      companyModel.value.legal_name &&
      companyModel.value.mail_address &&
      companyModel.value.legal_form &&
      ((companyModel.value.date_incorporation && companyModel.value.RCS_number) ||
        !companyModel.value.RCS_number) &&
      companyModel.value.comment &&
      addressModel.value.address_1 &&
      addressModel.value.zip_code &&
      addressModel.value.city &&
      addressModel.value.country_id
  )
  const userStaticData = UserStaticDataStore()
  const countries = userStaticData.data?.countries
  const naceCodes = userStaticData.data?.naceCodes
  const legalStatus = userStaticData.data?.legalStatus
  const companyModel = ref<Partial<CompanyDTO>>({
    mail_address: '',
    legal_name: '',
    legal_form: 0,
    RCS_number: null,
    VAT_number: null,
    code_nace: '',
    date_incorporation: null,
    comment: '',
    website_url: ''
  })

  const addressModel = ref<Address>({
    address_1: '',
    zip_code: '',
    city: '',
    country_id: null
  })
  const { companyStore, newCompany } = useCompany()
  return () => (
    <AnimatedHeightVue>
      <form
        className="pb-6"
        onSubmit={async (e: any) => {
          e.preventDefault()
          if (await newCompany(companyModel)) {
            attrs.onComplete()
          }
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.legalName')}`}
            >
              {{
                input: (input: any) => (
                  <Input
                    size="lg"
                    required
                    class={input.class}
                    v-model={companyModel.value.legal_name}
                  />
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.legalForm')}`}
            >
              {{
                input: (input: any) => (
                  <Select
                    size="lg"
                    required
                    class={input.class}
                    v-model={companyModel.value.legal_form}
                  >
                    {(legalStatus || []).map((opt) => (
                      <Option value={opt.id} key={opt.id}>
                        {opt.defaultName}
                      </Option>
                    ))}
                  </Select>
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={t('olkypass.companyPage.basicInfo.input.rcsNumber')}
            >
              {{
                input: (input: any) => (
                  <Input size="lg" class={input.class} v-model={companyModel.value.RCS_number} />
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={t('olkypass.companyPage.basicInfo.input.vatNumber')}
            >
              {{
                input: (input: any) => (
                  <Input size="lg" class={input.class} v-model={companyModel.value.VAT_number} />
                )
              }}
            </LabelDecoration>
          </div>

          {companyModel.value.RCS_number && (
            <div className="md:col-span-4">
              <LabelDecoration
                class="my-2"
                label={`* ${t('olkypass.companyPage.basicInfo.input.dateOfIncorporation')}`}
              >
                {{
                  input: (input: any) => (
                    <Input
                      size="lg"
                      required
                      class={input.class}
                      type="date"
                      v-model={companyModel.value.date_incorporation}
                    />
                  )
                }}
              </LabelDecoration>
            </div>
          )}

          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.activity_sector_nace')}`}
            >
              {{
                input: (input: any) => (
                  <Select
                    size="lg"
                    required
                    class={input.class}
                    v-model={companyModel.value.code_nace}
                  >
                    {(naceCodes || []).map((opt) => (
                      <Option value={opt.id} key={opt.id}>
                        {opt.defaultName}
                      </Option>
                    ))}
                  </Select>
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.description_activity')}`}
            >
              {{
                input: (input: any) => (
                  <Input
                    size="lg"
                    required
                    multiline="multiline"
                    class={input.class}
                    v-model={companyModel.value.comment}
                  />
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.address')}`}
            >
              {{
                input: (input: any) => (
                  <Input
                    size="lg"
                    required
                    class={input.class}
                    v-model={addressModel.value.address_1}
                  />
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.postal_code')}`}
            >
              {{
                input: (input: any) => (
                  <Input
                    size="lg"
                    required
                    class={input.class}
                    v-model={addressModel.value.zip_code}
                  />
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.city')}`}
            >
              {{
                input: (input: any) => (
                  <Input size="lg" required class={input.class} v-model={addressModel.value.city} />
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.country')}`}
            >
              {{
                input: (input: any) => (
                  <Select
                    size="lg"
                    required
                    class={input.class}
                    v-model={addressModel.value.country_id}
                  >
                    {(countries || [])
                      .sort((a, b) =>
                        new Intl.Collator(languageModule.state.currentLanguage).compare(
                          a['fr' === language ? 'name' : 'englishName'],
                          b['fr' === language ? 'name' : 'englishName']
                        )
                      )
                      .map((opt) => (
                        <Option value={opt.id} key={opt.id}>
                          {opt.defaultName}
                        </Option>
                      ))}
                  </Select>
                )
              }}
            </LabelDecoration>
          </div>

          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`* ${t('olkypass.companyPage.basicInfo.input.mail_address')}`}
            >
              {{
                input: (input: any) => (
                  <Input
                    size="lg"
                    required
                    class={input.class}
                    v-model={companyModel.value.mail_address}
                  />
                )
              }}
            </LabelDecoration>
          </div>
          <div className="md:col-span-2">
            <LabelDecoration
              class="my-2"
              label={`${t('olkypass.companyPage.basicInfo.input.website_url')}`}
            >
              {{
                input: (input: any) => (
                  <Input size="lg" class={input.class} v-model={companyModel.value.website_url} />
                )
              }}
            </LabelDecoration>
          </div>
        </div>

        <Button
          class="float-right"
          type="submit"
          theme="primary"
          loading={companyStore.loading}
          disabled={!isFormValid.value}
        >
          {t('olkypass.companyPage.tooltip.button.save_collaborator_info')}
        </Button>
      </form>
    </AnimatedHeightVue>
  )
})
