import { RegisterApiClient } from "@/features/register/api-client/api-client";
import { ConfirmationResponse } from "@/features/register/types";
import {UseMutationOptions, useMutation} from '@tanstack/react-query'
import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";

export type ValidateEmailMutationProps = {
    code: string, 
    cypher: string,
    email: string
}

export default function useValidateEmailMutation(options?: UseMutationOptions<ConfirmationResponse, Error, ValidateEmailMutationProps>) {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { t } = useTranslation(["flow"]);

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
          console.error('Execute recaptcha not yet available')
          return
        }

        return executeRecaptcha('login');
      }, [executeRecaptcha])

    return useMutation<ConfirmationResponse, Error, ValidateEmailMutationProps>({mutationFn: async ({ code, cypher, email }: ValidateEmailMutationProps) => {
        const token = await handleReCaptchaVerify()
        const data = await RegisterApiClient.validateEmail(cypher, code, { captcha_token: token, email });

        if(!data.success) {
            throw new Error(t("id_control.signup.error.validate_error"))
        }

        return data
    }, ...options})
}