<template>
  <ion-page id="HomePage" class="tracking-normal">
    <ion-content v-if="!isSkeletonLoading" :fullscreen="true">
      <div v-if="treatedEntity === 'account'" class="account-header">
        <div class="header-app height-261">
          <ion-grid>
            <ion-row class="mt-3 mb-2">
              <ion-col size="1" class="mt-3">
                <div class="circleIconMenu">
                  <ion-icon
                    :icon="menu"
                    color="light"
                    size="large"
                    @click="gotoRouter('SuppliersRouter')"
                  ></ion-icon>
                </div>
              </ion-col>
              <ion-col class="ion-margin-horizontal mt-4">
                <h2 class="">{{ currentSupplier.fullName }}</h2>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <h4 class="text-blue-dark">€ {{ formattedBalance }}</h4>
                <span class="text-gray">
                  {{ $t('olkypay.labels.homePage.balance.available') }}</span
                >
              </ion-col>
              <ion-col class="ion-text-right">
                <h4 class="text-blue-dark">€ {{ formattedOnOperationsBalance }}</h4>
                <span class="text-gray">
                  {{ $t('olkypay.labels.homePage.balance.on_operations') }}</span
                >
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <h4 class="text-blue-dark">{{ formattedIBAN }}</h4>
                <span class="text-gray">IBAN</span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="item-on-header-app text-center mt-n20">
          <ion-row class="d-flex justify-content-center align-items-center">
            <ion-col class="text-center">
              <button class="btn download-iban" @click="downloadIban">
                <ion-icon slot="start" :icon="downloadSharp"></ion-icon>
                {{ $t('olkypay.buttons.homePage.standard.downloadIban') }}
              </button>
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else class="card-header">
        <div class="header-app height-261 absolute">
          <ion-grid>
            <ion-row class="mt-3 mb-2">
              <ion-col size="1" class="mt-3">
                <div class="circleIconMenu">
                  <ion-icon
                    :icon="menu"
                    color="light"
                    size="large"
                    @click="gotoRouter('SuppliersRouter')"
                  ></ion-icon>
                </div>
              </ion-col>
              <ion-col class="ion-margin-horizontal mt-4">
                <h2>{{ currentSupplier.fullName }}</h2>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="ion-text-right">
                <h3 class="text-dark">€ {{ formattedBalance }}</h3>
                <span class="text-gray">
                  {{ $t('olkypay.labels.homePage.balance.available') }}</span
                >
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="text-center">
          <ion-row class="d-flex justify-content-center align-items-center">
            <ion-col class="text-center mt-7">
              <img :src="particularCard" class="item-on-header-app mt-60" />
            </ion-col>
          </ion-row>
        </div>
      </div>
      <transactions-list></transactions-list>
    </ion-content>
    <full-skeleton v-else></full-skeleton>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import LanguageSwitcher from '../../components/LanguageSwitcher.vue'
import Transaction from '../../components/transactions/transaction.vue'
import HeaderTabs from '../../components/HeaderTabs.vue'
import FullSkeleton from '../../components/skeleton/FullSkeleton.vue'
import TransactionsList from '../../components/transactions/transactionsList.vue'
import { mapActions, mapGetters } from 'vuex'
import { saveAs } from 'file-saver'
import businessCard from '../../../../../public/images/olkypay/cards/business-card.png'
import particularCard from '../../../../../public/images/olkypay/cards/particular-card.png'
import InfoModal from '../../components/InfoModal.vue'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSearchbar,
  IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'
import {
  arrowBack,
  create,
  ellipsisHorizontal,
  ellipsisVertical,
  helpCircle,
  search,
  personCircle,
  logOut,
  calendar,
  arrowUp,
  arrowDownSharp,
  downloadSharp,
  menu
} from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import write_blob from 'capacitor-blob-writer'
export default {
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLoading,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonSearchbar,
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonBackButton,
    IonButtons,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    LanguageSwitcher,
    Transaction,
    HeaderTabs,
    FullSkeleton,
    TransactionsList,
    InfoModal
  },
  data() {
    return {
      arrowBack,
      create,
      ellipsisHorizontal,
      ellipsisVertical,
      helpCircle,
      search,
      personCircle,
      logOut,
      calendar,
      arrowUp,
      arrowDownSharp,
      downloadSharp,
      menu,
      businessCard,
      particularCard,
      paginationOptions: {
        page: 1,
        search: ''
      },
      displayedTransactions: []
    }
  },
  methods: {
    ...mapActions('loader', [
      'playSkeletonLoader',
      'stopSkeletonLoader',
      'playLoader',
      'stopLoader'
    ]),
    ...mapActions('transaction', ['loadTransactions', 'initTransactions']),
    ...mapActions('supplier', ['downloadPdfIban']),
    async downloadIban() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.downloadIban.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      const file = await this.downloadPdfIban({ supplier_id: this.currentSupplier.id })
      const randomNumber = Math.floor(Math.random() * 10)
      const filename = `download_${randomNumber}.pdf`
      let download_result = true
      let dialogContent = this.$i18n.t('olkypay.notifications.success.ribPage.downloadIban.message')
      if (Capacitor.isNativePlatform()) {
        const document_path = Directory.Documents + '/' + filename
        download_result = await this.saveDownloadedFileAsPdf(file, filename)
        dialogContent +=
          '\n' +
          this.$i18n.t('olkypay.notifications.success.ribPage.downloadIban.sub_message') +
          '\n' +
          document_path
      } else {
        saveAs(file, filename)
      }
      this.stopLoader()
      if (download_result) {
        const successDialog = {
          type: 'success',
          title: this.$i18n.t('olkypay.notifications.success.ribPage.downloadIban.header'),
          content: dialogContent,
          button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
        }
        if (await this.$refs.infoModal.openModal(successDialog)) {
          // open file
        }
      } else {
        const failedDialog = {
          type: 'warning',
          title: this.$i18n.t('olkypay.notifications.error.ribPage.downloadIban.header'),
          content: this.$i18n.t('olkypay.notifications.error.ribPage.downloadIban.message'),
          button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
        }
        await this.$refs.infoModal.openModal(failedDialog)
      }
    },
    convertBlobToBase64(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = () => resolve(reader.result as string)
        reader.readAsDataURL(blob)
      })
    },
    async saveDownloadedFileAsPdf(blobData, fileName) {
      try {
        const base64Data = await this.convertBlobToBase64(blobData)
        const path = fileName
        await Filesystem.writeFile({
          path,
          data: base64Data,
          directory: Directory.Documents,
          recursive: true
        })
        return true // You can return the file path if needed
      } catch (error) {
        const failedDialog = {
          type: 'warning',
          title: this.$i18n.t('olkypay.notifications.error.ribPage.downloadIban.header'),
          content: this.$i18n.t('olkypay.notifications.error.ribPage.downloadIban.message'),
          button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
        }
        await this.$refs.infoModal.openModal(failedDialog)

        return false
      }
    },
    gotoRouter(router_name) {
      this.$router.push({ name: router_name })
    }
  },
  computed: {
    ...mapGetters('user', ['username','user_settings']),
    ...mapGetters('loader', ['isSkeletonLoading']),
    ...mapGetters('supplier', ['currentSupplier', 'treatedEntity']),
    ...mapGetters('transaction', ['transactions']),
    formattedBalance() {
      // balance is in centimes
      const balance = this.currentSupplier.balance / 100
      return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(balance)
    },
    formattedOnOperationsBalance() {
      // balanceOperation is in centimes
      const balanceOperation = this.currentSupplier.balanceOperation / 100
      return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(balanceOperation)
    },
    formattedIBAN() {
      let formattedIBAN = ''
      if (this.currentSupplier.iban) {
        formattedIBAN = this.currentSupplier.iban.match(/.{1,4}/g).join(' ')
      }

      return formattedIBAN
    }
  }
}
</script>

<style scoped></style>
