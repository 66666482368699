import Image from '@/atoms-react/image/Image.react'
import { Base, SectionTitle } from '@/atoms-react/text/Text.react'
import { useTranslation, Trans } from 'react-i18next'
import { Button } from '@/atoms-react/button/Button.react'

type IdentificationSuccessfulProps = {
    onValidated?: () => void
}

export const IdentificationSuccessful = ({ onValidated = () => { } }: IdentificationSuccessfulProps) => {
    const { t } = useTranslation(['atoms'])
    return (
        <>
            <div className="max-w-4xl py-4 mx-auto text-center px-4">
                <Image className="h-auto md:w-80 w-60 mx-auto mb-4" alt={t('molecules.modal_kyc_identity.step3_image_alt_success')} src={'/images/olkypass/validationKycSuccess.svg'} />
                <div className="mx-auto text-center px-4">
                    <SectionTitle className="text-base mb-4">{t('molecules.modal_kyc_identity.step3_title_success')}</SectionTitle>
                    <Base className="text-gray-400 font-medium">
                        <Trans i18nKey={'molecules.modal_kyc_identity.step3_content_success'} ns={'atoms'} components={{ span: <span className="text-blue-500 font-semibold" /> }} />
                    </Base>
                </div>
            </div>
            <div className={'flex justify-end'}>
                    <Button className={'w-fit h-10 font-medium'} onClick={onValidated}>
                        {t('molecules.modal_kyc_identity.continue')}
                    </Button>
            </div>
        </>
    )
}