import { Button } from '@/atoms-react/button/Button.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WalletNumber } from '../wallet-number/wallet-number.react'

type ModalBankToCryptoProps = {
  wallet: string | null
  onSetWallet?: () => void
  onValidated?: (amount: number) => void
} & ModalProps

export const ModalBankToCrypto = ({
  wallet,
  onValidated = () => {},
  onSetWallet = () => {},
  ...props
}: ModalBankToCryptoProps) => {
  const [amountString, setAmountString] = useState<string>('')
  const [amount, setAmount] = useState<number>(0)

  const { t } = useTranslation(['wallet'])
  return (
    <Modal className="sm:w-full w-full max-w-lg" {...props}>
      <ModalContent title={t('kypay.modal_deposit_funds.label.title')}>
        <div className={'mt-4 opacity-1'}>
          <div className={'w-full mt-4 mb-6'}>
            <InputLabel
              value={amountString}
              onChange={(value: string) => {
                setAmountString(value)
                const parsed = parseFloat(value)
                if (!isNaN(parsed)) {
                  setAmount(parsed)
                }
              }}
              suffixIcon={() => <span>€</span>}
              type="number"
              min="0.00"
              max="10000.00"
              step="0.01"
              label={t('kypay.modal_transfer_fund.input.amount_label')}
              placeholder={t('kypay.modal_transfer_fund.input.amount_placeholder')}
            />
          </div>
          <div className={'w-full mt-4 mb-8'}>
            <WalletNumber
              wallet={wallet}
              label={t('kypay.modal_transfer_fund.label.destination_wallet')}
              onSetWallet={onSetWallet}
            />
          </div>

          <Button
            disabled={!wallet || !amount || amount <= 0}
            shortcut="enter"
            className="shrink-0"
            onClick={() => {
              if (amount) onValidated(amount)
            }}
            icon={ChevronRightIcon}
          >
            {t('kypay.modal_transfer_fund.button.send')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
