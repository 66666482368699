import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import RadioButton from '@/atoms-react/radio-button/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import Flag from '@/atoms/flag/Flag.react'
import { Gender } from '@/features/auth/types'
import { DeclarativePerson } from '@/features/flow/types'
import { FAVOURITE_COUNTRY_CODES, LANGUAGE_CODES } from '@/features/utils/constants'
import { useLocale } from '@/features/utils/hooks/use-locale'
import nationalitiesEN from '@/locales/nationalities-en.json'
import nationalitiesFR from '@/locales/nationalities-fr.json'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../../flow-header.react'

interface DeclarativeIdentityOneProps extends LayoutBaseProps {
  onValidated?: (output: DeclarativeIdentityOneOutput) => void
}

export type DeclarativeIdentityOneOutput = DeclarativePerson

export function isDeclarativePersonValid(data: DeclarativePerson | undefined | null) {
  if (!data) return false

  return !!data.firstnames && !!data.lastnames && !!data.gender && !!data.nationalityCodes?.length && !!data.nationalityCodes[0]
}

function DeclarativeIdentityPerson({ onValidated = () => {} }: DeclarativeIdentityOneProps) {
  const { t } = useTranslation(['pass'])
  const { locale } = useLocale()

  const [data, setDeclarativeIdentityOne] = useState<DeclarativePerson>({
    gender: null,
    firstnames: '',
    lastnames: '',
    nationalityCodes: []
  })

  function execute() {
    if (!isDeclarativePersonValid(data)) {
      toast.error(t('flow.id_control.error.must_enter_fields'))
      return
    }

    onValidated(data)
  }

  const countries = Object.entries(
    locale.language.toLowerCase() === LANGUAGE_CODES.FR ? nationalitiesFR : nationalitiesEN
  )

  return (
    <>
      <Column className="justify-between">
        <div className="mb-12">
          <FlowHeader
            title={t('flow.id_control.signup.declarative_identity.label.one_title')}
            description={t('flow.id_control.signup.declarative_identity.label.one_description')}
          />
          <Column>
            <RadioButton
              className={'mb-6'}
              choicesInRow={true}
              value={data.gender || ''}
              onChange={(output: any) => {
                const outputEnumKey: Gender = output as Gender
                setDeclarativeIdentityOne((identity) => ({
                  ...identity,
                  gender: outputEnumKey
                }))
              }}
              label={t('flow.id_control.input.gender_label')}
              listOfChoices={[
                { id: Gender.Man, title: t('flow.id_control.input.gender_man') },
                { id: Gender.Woman, title: t('flow.id_control.input.gender_woman') }
              ]}
            />

            <div className="flex flex-col md:flex-row">
              <InputLabel
                autoComplete="given-name"
                placeholder={t('flow.id_control.input.firstname_placeholder')}
                className={'mb-4 md:w-full md:mr-8'}
                value={data.firstnames ?? ''}
                onChange={(value: string) =>
                  setDeclarativeIdentityOne((identity) => ({ ...identity, firstnames: value }))
                }
                label={t('flow.id_control.input.firstname_label')}
              />
              <InputLabel
                autoComplete="family-name"
                placeholder={t('flow.id_control.input.lastname_placeholder')}
                className={'mb-4 md:w-full'}
                value={data.lastnames ?? ''}
                onChange={(value: string) =>
                  setDeclarativeIdentityOne((identity) => ({ ...identity, lastnames: value }))
                }
                label={t('flow.id_control.input.lastname_label')}
              />
            </div>

            <Dropdown
              label={t('flow.id_control.input.nationality_label')}
              placeholder={t('flow.id_control.input.nationality_placeholder')}
              items={countries.map(([countryCode, countryName]) => ({
                groupKey: FAVOURITE_COUNTRY_CODES.find(
                  (code) => code.toLowerCase() === countryCode.toLowerCase()
                )
                  ? 'default'
                  : undefined,
                key: countryCode,
                value: countryName,
                icon: <Flag countryCode={countryCode!} />,
                render: () => {
                  return (
                    <div className={'w-full flex'} key={countryCode!}>
                      <Flag countryCode={countryCode!} className="!w-6 !h-6 rounded-sm" />
                      <Base className={'ml-2'}>{countryName}</Base>
                    </div>
                  )
                }
              }))}
              value={data.nationalityCodes?.[0]}
              onChange={(value) =>
                setDeclarativeIdentityOne((identity) => ({
                  ...identity,
                  nationalityCodes: [value.key.toUpperCase()]
                }))
              }
              groups={[{ key: 'default' }]}
              search={true}
            />
          </Column>
        </div>
        <div className="shrink-0 flex flex-col items-center">
          <DotsNavigation total={4} current={0} />
          <Button
            shortcut="enter"
            onClick={() => execute()}
            icon={ChevronRightIcon}
            className="my-4"
          >
            {t('flow.id_control.input.continue')}
          </Button>
        </div>
      </Column>
    </>
  )
}

export default DeclarativeIdentityPerson
