import { TransactionService, ResponseError } from '../services/transaction.service'

const state = {
  responseData: '',
  responseErrorCode: 0,
  responseError: '',
  transactions: [],
  orders: [],
  selectedOrders: [],
  currentOrder: {}
}

const getters = {
  responseData: (state: { responseData: any }) => {
    return state.responseData
  },

  responseErrorCode: (state: { responseErrorCode: any }) => {
    return state.responseErrorCode
  },
  responseError: (state: { responseError: any }) => {
    return state.responseError
  },
  transactions: (state: { transactions: any }) => {
    return state.transactions
  },
  orders: (state: { orders: any }) => {
    return state.orders
  },
  selectedOrders: (state: { selectedOrders: any }) => {
    return state.selectedOrders
  },
  currentOrder: (state: { currentOrder: any }) => {
    return state.currentOrder
  }
}

const actions = {
  initTransactions(context: any) {
    context.commit('initTransactions')
  },
  initOrders(context: any) {
    context.commit('initOrders')
  },
  initSelectedOrders(context: any) {
    context.commit('initSelectedOrders')
  },
  async loadTransactions(context: any, payload: any) {
    context.commit('dataRequest')
    const pagination_options = payload.pagination_options
    try {
      const resp = await TransactionService.getTransactions(pagination_options)
      context.commit('successLoadTransactions', resp)
      return resp
    } catch (e: any) {
      if (e instanceof ResponseError) {
        context.commit('dataError', {
          errorMessage: e.errorMessage,
          responseErrorCode: e.errorCode
        })
      }
      return e.message
    }
  },
  async loadOrders(context: any, payload: any) {
    context.commit('dataRequest')
    const pagination_options = payload.pagination_options
    try {
      const resp = await TransactionService.getOrders(pagination_options)
      context.commit('successLoadOrders', resp)
      return resp
    } catch (e: any) {
      if (e instanceof ResponseError) {
        context.commit('dataError', {
          errorMessage: e.errorMessage,
          responseErrorCode: e.errorCode
        })
      }
      return e.message
    }
  },
  manipulateSelectedOrders(context: any, data: any) {
    context.commit('manipulateSelectedOrders', data)
  },
  setCurrentOrder(context: any, order: any) {
    context.commit('setCurrentOrder', order)
  }
}

const mutations = {
  dataRequest(state: { responseError: string; responseErrorCode: number }) {
    state.responseError = ''
    state.responseErrorCode = 0
  },
  dataSuccess(state: { responseData: string }, payload: any) {
    state.responseData = payload
  },
  successLoadTransactions(state: { transactions: any }, payload: any) {
    // transactions are paginated per 10 in page
    state.transactions = state.transactions.concat(payload)
  },
  successLoadOrders(state: { orders: any }, payload: any) {
    // transactions are paginated per 10 in page
    state.orders = state.orders.concat(payload)
  },
  initTransactions(state: { transactions: any }) {
    // transactions are paginated per 10 in page
    state.transactions = []
  },
  initOrders(state: { orders: any }) {
    // orders are paginated per 10 in page
    state.orders = []
  },
  initSelectedOrders(state: { selectedOrders: any }) {
    // orders are paginated per 10 in page
    state.selectedOrders = []
  },
  dataError(
    state: {
      responseError: any
      responseErrorCode: any
    },
    { errorCode, errorMessage }: any
  ) {
    state.responseError = errorMessage
    state.responseErrorCode = errorCode
  },
  manipulateSelectedOrders(state: { selectedOrders: any }, data: any) {
    if (data.operation === 'add') {
      state.selectedOrders.push({ id: data.id })
    }
    if (data.operation === 'remove') {
      state.selectedOrders = state.selectedOrders.filter((item: any) => item.id !== data.id)
    }
  },
  setCurrentOrder(state: { currentOrder: any }, order: any) {
    state.currentOrder = order
  }
}

export const transaction = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
