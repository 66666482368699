import { Alert, AlertType } from '@/atoms-react/alert/index.react'
import { Button } from '@/atoms-react/button/Button.react'
import { Modal, ModalContent } from '@/atoms-react/modal/index.react'
import { Themes } from '@/types/theme'
import { TrashIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ModalDeleteAliasProps = {
    isOpen: boolean
    title?: string
    onClose: () => void
    onValidated: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const ModalDeleteAlias = ({ title = "", isOpen, onClose, onValidated, children }: ModalDeleteAliasProps) => {
    const { t } = useTranslation(["pass"])
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            className="sm:w-full w-full max-w-2xl"
        >
            <ModalContent title={title}>
                <div className="flex flex-col mt-4 gap-4">
                    <Alert type={AlertType.Danger}>
                        {children}
                        <div className="flex justify-end gap-4 mt-4">
                            <Button onClick={onClose} theme={Themes.white} className="w-max">{t('olkypass.general.cancel')}</Button>
                            <Button icon={TrashIcon} onClick={onValidated} className="w-max" theme={Themes.danger}>{t('olkypass.general.confirm')}</Button>
                        </div>
                    </Alert>

                </div>
            </ModalContent>
        </Modal>
    )
}
