import Column from '@/atoms-react/column/Column.react'
import InputLabel from '@/atoms-react/input/Input.react'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

export type AddMobileFormProps = {
  onChange: (iban: string, valid: boolean) => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>

export const FormAddIban = ({
  onChange: _onChange = () => { },
  ...props
}: AddMobileFormProps) => {
  const { t } = useTranslation(['atoms'])

  const [iban, setIban] = useState<string>("")

  const onChange = (iban: string) => {
    setIban(iban)
    _onChange(iban, true)
  }

  return (
    <Column
      className={twMerge('w-full', props.className)}
      {..._.omit(props, 'onChange', 'className')}
    >
      <InputLabel
        autoFocus={true}
        value={iban}
        onChange={onChange}
        label={t('molecules.add_iban_form.iban_label')}
        placeholder={t('molecules.add_iban_form.iban_label')}
        required={true}
      />
    </Column>
  )
}