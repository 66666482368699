import store from '../store'
const NotificationsMixins = {
    methods: {
        displayNotification(type, message) {
            const notification = {
                type: type,
                message: message
            };
            store.dispatch("addNotification", notification);
        }
    }
}
export default NotificationsMixins;