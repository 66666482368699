<template>
  <v-overlay
      :model-value="loading"
      class="align-center justify-center"
  >
    <v-progress-circular
        color="primary"
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from '../store'
export default {
  name: "Loader",
  data: () => ({}),
  methods: {
  },
  computed: {
    ...mapGetters(["loading"])
  },

}
</script>

<style scoped>

</style>