<template>
    <v-card class="mx-auto dashboardBox olkyKycoinCard" height="320px" width="auto" @click="ionRouter.navigate(Routes.kycoin, 'root')">
      <v-card-item class="cardTopTitle">
        <template #title>
          {{ $t('olkypass.accountPage.cardActions.label.kycoin') }}
        </template>
      </v-card-item>
      <v-card-text v-if="!loading" class="cardContentContainer">
        <v-row>
          <v-col>
            <v-img class="mx-auto" height="100px" width="77px" :src="olkyKycoinLogoPath"></v-img>
          </v-col>
        </v-row>
        <v-row class="available">
          <v-col>
            <h1>{{ kycoin.balance }}</h1>
            <h2>{{ kycoin.balanceEuro }}</h2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!loading">
        <!--      :to="{ name: 'support_route' }"-->
        <a class="no-ui databoxDetailsButton">{{ $t('olkypass.mainPage.olkypass.details') }}</a>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from '../../store'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { Routes } from '@/features/router'
import { useIonRouter } from '@ionic/vue'

export default {
  name: 'DashboardKycoinDataBox',
  mixins: [ToolsMixins],
  data: () => ({
    olkyKycoinLogoPath: '/images/olkypass/kycoin-logo.svg',
    ionRouter: useIonRouter(),
    Routes: Routes
  }),
  computed: {
    ...mapGetters(['loading', 'kycoin'])
  }
}
</script>
