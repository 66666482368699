import { Base } from '@/atoms-react/text/Text.react'
import { AliasMobile } from '@/features/user/types'
import { DevicePhoneMobileIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { AliasCard } from '../alias-card/index.react'
import { useTranslation } from 'react-i18next'
import Flag from '@/atoms/flag/Flag.react'
import { PHONE_COUNTRY_CODES } from '@/features/utils/constants'
import { getAsYouType } from 'awesome-phonenumber'

type PhoneCardProps = {
  phone: AliasMobile
  isLoading?: boolean
  onClick?: () => void
  onPublicClick?: () => void
  onDelete?: () => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'>

export const PhoneCard = ({
  phone,
  onPublicClick,
  ...props
}: PhoneCardProps) => {
  const { t } = useTranslation(["pass"])
  const [phoneNumber, setPhoneNumber] = useState<string>(phone.MobileNumber)

  useEffect(() => {
    const country = _.invert(PHONE_COUNTRY_CODES)[phone.MobileIndicatif]
    const asYouType = getAsYouType(country)
    asYouType.reset(phone.MobileNumber)
    const asYouTypePhone = asYouType.getPhoneNumber()
    if (asYouTypePhone.number) {
      setPhoneNumber(asYouTypePhone.number.input)
    }
  }, [phone])

  return (
    <AliasCard tooltipDesignation={t('olkypass.aliasPublicPage.this_phone_number')} isPublic={phone.Public} icon={DevicePhoneMobileIcon} onPublicClick={onPublicClick} {...props}>
      <div className="flex gap-2 items-center">
        <div className={'flex gap-2'}>
          <Flag
            countryCode={_.invert(PHONE_COUNTRY_CODES)[phone.MobileIndicatif]}
            className="!w-6 !h-6 rounded-sm"
          />
          <Base title={`${phone?.MobileIndicatif}`}>
            {phone?.MobileIndicatif}
          </Base>
        </div>
        <Base
          className={twMerge(
            'font-semibold text-ellipsis overflow-hidden break-normal whitespace-nowrap'
          )}
          title={`${phoneNumber}`}
        >
          {`${phoneNumber}`}
        </Base>
      </div>
    </AliasCard >
  )
}
