import { Button } from '@/atoms-react/button/Button.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { useUser } from '@/features/user/hooks/use-user'
import { AliasMobile, MobileCreate } from '@/features/user/types'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormAddMobile } from '../form-add-mobile/index.react'

type ModalAddAddressProps = {
  mobile?: AliasMobile
  onValidated?: (address: MobileCreate) => void
} & ModalProps

export const ModalAddMobile = ({
  mobile,
  onValidated = () => { },
  ...props
}: ModalAddAddressProps) => {
  const { t } = useTranslation(['atoms'], { useSuspense: false })
  const { useUserProfile } = useUser()
  const { data: user, isLoading: isLoadingUser } = useUserProfile()
  const [newMobile, setNewMobile] = useState<MobileCreate>({
    MobileIndicatif: "",
    MobileNumber: ""
  })

  useEffect(() => {
    if (isLoadingUser) return

    setNewMobile((data) => ({
      ...data,
      UUID: user?.naturalPerson?.UUID,
    }))
  }, [user])

  const [mobileValid, setMobileValid] = useState(false)

  return (
    <Modal {...props} maxWidthClassName='max-w-2xl'>
      <ModalContent
        title={
          mobile
            ? t('molecules.modal_add_mobile.title_modify')
            : t('molecules.modal_add_mobile.title')
        }
      >
        <div className={'mt-4 opacity-1 items-end flex flex-col'}>
          <div className={'w-full mt-4 mb-6'}>
            <FormAddMobile
              autoFocus
              onChange={(phone, valid) => {
                setNewMobile((mobile) => ({ ...mobile, MobileIndicatif: phone.countryPrefix, MobileNumber: phone.phone || "" }))
                setMobileValid(valid)
              }}
            />
          </div>

          <Button
            disabled={!mobileValid}
            shortcut="enter"
            className="shrink-0 w-max"
            onClick={() => {
              onValidated(newMobile)
            }}
            icon={ChevronRightIcon}
          >
            {t('molecules.modal_add_address.confirm')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
