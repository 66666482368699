import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import _ from 'lodash'
import { computed } from 'vue'
import { defaultInputClassName, errorInputClassName } from '.'

type SelectProps = {
  hasError?: boolean
  disabled?: boolean
  multiline?: boolean
  size?: 'sm' | 'md' | 'lg'
  onChange?: (e: any) => void
  class?: string
} & Partial<Omit<Omit<HTMLSelectElement, 'size'>, 'onChange'>> &
  VModelProps<string>

export const Select = defineJsxComponent<SelectProps>(
  (props, { slots, emit, model }) => {
    const computedInputClassName = computed(() => {
      let className = props.hasError ? errorInputClassName() : defaultInputClassName()

      className += props.disabled ? ' opacity-75 pointer-events-none ' : ''

      if (!props.multiline) {
        if (props.size === 'lg') className += ' h-10 pl-4 pr-8'
        else if (props.size === 'sm') className += ' h-7 pl-3 pr-8'
        else className += ' h-9 pl-3 pr-8'
      }

      return `${className} ${props.class || ''} cursor-pointer`
    })

    return () => (
      <select
        value={model()}
        onNext={(e: any) => emit('update:modelValue', e.target.value)}
        onChange={(e: any) => {
          props.onChange ? props.onChange(e) : emit('update:modelValue', e.target.value)
        }}
        class={computedInputClassName.value}
        {...(_.omit(props, 'class', 'form', 'onChange') as any)}
      >
        {slots.default?.()}
      </select>
    )
  },
  {
    inheritAttrs: false
  }
)

export const Option = defineJsxComponent<Partial<HTMLOptionElement>>((props, { slots }) => () => (
  <option {...(props as any)}>{slots.default?.()}</option>
))
