import { AnimatedHeight } from '@/atoms-react/animated-height/AnimatedHeight.react'
import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { DeclarativeOccupation } from '@/features/flow/types'
import { useUser } from '@/features/user/hooks/use-user'
import { CSPCodesNoActivity } from '@/features/utils/constants'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../../flow-header.react'
interface DeclarativeIdentityActivityProps extends LayoutBaseProps {
  onValidated?: (output: DeclarativeIdentityActivityOutput) => void
}

export type DeclarativeIdentityActivityOutput = DeclarativeOccupation

export function isCSPCodeNoOccupation(CSPcode: number) {
  return CSPCodesNoActivity.find((csp) => csp.Code === CSPcode)
}

// Activity can be null only if CSP code allows for it. CSP code must be enterred
export function isDeclarativeOccupationValid(data: DeclarativeOccupation | undefined | null) {
  if (!data) return false

  const noOccupation = isCSPCodeNoOccupation(data.CSPcode!)
  return (noOccupation || data.activity) && data.CSPcode
}

function DeclarativeIdentityOccupation({
  onValidated = () => {}
}: DeclarativeIdentityActivityProps) {
  const { t } = useTranslation(['pass'])
  const { locale } = useLocale()
  const { useCSP, useActivities } = useUser()
  const { data: CSPCodes } = useCSP(locale.language)
  const { data: activities } = useActivities(locale.language)

  const [data, setData] = useState<DeclarativeOccupation>({
    activity: null,
    CSPcode: null
  })

  function execute() {
    if (!isDeclarativeOccupationValid(data)) {
      toast.error(t('flow.id_control.error.must_enter_fields'))
      return
    }

    onValidated(data)
  }

  useEffect(() => {
    if (data.CSPcode && isCSPCodeNoOccupation(data.CSPcode)) {
      setData((data) => ({ ...data, activity: null }))
    }
  }, [data.CSPcode])

  return (
    <>
      <Column>
        <div className="grow mb-12">
          <FlowHeader
            title={t('flow.id_control.signup.declarative_identity.label.four_title')}
            description={t('flow.id_control.signup.declarative_identity.label.four_description')}
          />
          <AnimatedHeight>
            <Dropdown
              value={data.CSPcode?.toString() ?? null}
              label={t('flow.id_control.input.csp_label')}
              placeholder={t('flow.id_control.input.csp_placeholder')}
              items={(CSPCodes?.cspCodes ?? []).map((code) => ({
                key: code.Code.toString(),
                value: code.defaultName
              }))}
              search={true}
              className={'mb-6'}
              onChange={({ key }) => {
                setData((data) => {
                  return { ...data, CSPcode: !_.isNaN(parseInt(key)) ? parseInt(key) : null }
                })
              }}
            />
            {!!data.CSPcode && !isCSPCodeNoOccupation(data.CSPcode) && (
              <Dropdown
                value={data.activity ?? null}
                label={t('flow.id_control.input.activity_label')}
                search={true}
                placeholder={t('flow.id_control.input.activity_placeholder')}
                items={(activities?.activities ?? []).map((activity) => ({
                  key: activity.Code.toString(),
                  value: activity.defaultName
                }))}
                onChange={({ key }) => setData((data) => ({ ...data, activity: key }))}
              />
            )}
          </AnimatedHeight>
        </div>
        <div className="shrink-0 flex flex-col items-center">
          <DotsNavigation total={4} current={3} />
          <Button
            shortcut="enter"
            onClick={() => execute()}
            icon={ChevronRightIcon}
            className="mt-4"
          >
            {t('flow.id_control.input.continue')}
          </Button>
        </div>
      </Column>
    </>
  )
}

export default DeclarativeIdentityOccupation
