import { StorageService } from '@/features/utils/storage'
const state = {
  currentLanguage: StorageService.getItem('currentLanguage') ?? 'fr'
}

const getters = {
  currentLanguage: (state: { currentLanguage: any }) => {
    return state.currentLanguage
  }
}

const actions = {
  async setCurrentLanguage(context: any) {
    context.commit('setCurrentLanguage')
  }
}

const mutations = {
  setCurrentLanguage(
    state: {
      currentLanguage: string
    },
    language: any
  ) {
    state.currentLanguage = language
  }
}

export const home = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
