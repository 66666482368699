import { twMerge } from 'tailwind-merge'
import './index.css'

export const DynamicBlobAnimation = ({ className, src }: { className?: string; src: string }) => {
  return (
    <div className={twMerge('dynamic-blob', className)}>
      <div className="dynamic-blob-mask">
        <svg
          width="100%"
          height="100%"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="40 10 180 170"
          preserveAspectRatio="xMidYMid meet"
          fill="url(#fill-blob)"
        >
          <path
            id="blobpath"
            d="M 199.03 34.8 C 217.23 48.5 228.15 66.9 231.01 85.4 C 233.74 103.9 228.28 122.6 215.8 136.9 C 203.19 151.3 183.43 161.3 161.33 168.5 C 139.1 175.8 114.53 180.2 92.95 175.4 C 71.5 170.6 52.91 156.5 39.91 139.7 C 26.78 122.9 18.98 103.4 24.05 86.4 C 29.25 69.4 47.06 54.9 66.82 41.3 C 86.58 27.8 108.29 15.3 131.82 13.6 C 155.35 11.9 180.83 21 199.03 34.8 Z"
          ></path>
          <defs>
            <pattern
              id="fill-blob"
              patternUnits="userSpaceOnUse"
              patternContentUnits="userSpaceOnUse"
              width="240"
              height="180"
              x="0px"
              y="0px"
            >
              <image
                preserveAspectRatio="xMidYMid slice"
                id="imgpattern"
                href={src}
                width="240"
                height="180"
              ></image>
            </pattern>
          </defs>
        </svg>
      </div>
    </div>
  )
}
