import { Subtitle, Title } from '@/atoms/text/Text.tsx'
import { useI18n } from 'vue-i18n'
import { defineVueComponent } from '@/jsx-utils'
import { FooterView } from './components/FooterView'
import { FormForgetPasswordView } from './components/FormForgetPasswordView'
import { LayoutView } from './components/LayoutView'

export const ForgetPasswordView = defineVueComponent(() => {
  const { t } = useI18n()
  return () => (
    <LayoutView>
      <Title>{t('login.forgetPasswordPage.label.header_card')}</Title>
      <Subtitle class="mt-3">{t('login.forgetPasswordPage.label.email')}</Subtitle>
      <FormForgetPasswordView />
      <FooterView />
    </LayoutView>
  )
})
