<template>
  <v-container fluid style="padding: 0 !important; ">
    <v-row>
      <v-col>
        <v-card id="accountInfoTabContent" class="cardBox">
          <v-card-text>
            <AccountInfoChild ref="accountInfoChild" :kyc-level="kycLevel" :columns="3"></AccountInfoChild>
          </v-card-text>
          <v-card-actions v-if="hasUpdatePermission">
            <v-row>
              <v-col class="d-flex justify-end align-center">
                <v-btn
                  variant="flat"
                  color="primary"
                  height="auto"
                  class="text-none validationBtn"
                  :disabled="checkDisabled"
                  size="medium"
                  @click="openConfirmDialog"
                >
                  {{ $t('olkypass.accountPage.basicInfo.button.updateInfo') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <DialogConfirm ref="dialogConfirm"></DialogConfirm>
  </v-container>
</template>

<script>
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import DialogConfirm from '../DialogConfirm.vue'
import AccountInfoChild from '../Common/AccountInfoChild.vue'

export default {
  name: 'AccountInfoTab',
  components: {
    DialogConfirm,
    AccountInfoChild
  },
  props: { kycLevel: { type: Number, default: 0 } },
  mixins: [NotificationsMixins, ToolsMixins],
  data: () => ({
    uris: {
      _post: {
        profile: '/user/profile/edit'
      }
    },
    panels: {
      basicInfo: {
        box_fields: [
          'FirstName',
          'LastName',
          'OtherFirstNames',
          'MaidenName',
          'DateOfBirth.date',
          'CountryOfCitizenship',
          'countryOfBirth',
          'StateOfBirth',
          'ActivityCode',
          'CSPCode'
        ]
      }
    }
  }),
  methods: {
    async updateBasicInfo() {
      this.dialog = false
      await store.dispatch('playLoader')
      await fetchServer(this.uris._post.profile, { method: 'PUT', body: this.naturalPerson })
        .then((response) => {
          if (response) {
            this.displayNotification(
              'success',
              this.$i18n.t('olkypass.accountPage.notifications.success.profile.update')
            )
          }
        })
        .catch((error) => {
          if (error.response.data.message !== undefined) {
            this.displayNotification('error', error.response.data.message)
          } else {
            this.displayNotification(
              'error',
              this.$i18n.t('olkypass.accountPage.notifications.error.profile.load')
            )
          }
        })
        .finally(async () => {
          await store.dispatch('stopLoader')
          await store.dispatch('setCurrentNaturalPerson')
        })
    },
    async openConfirmDialog() {
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.update.infos.header',
          'accountPage.dialog.confirm.update.infos.body'
        )
      ) {
        await this.updateBasicInfo()
      }
    }
  },
  computed: {
    ...mapGetters([
      'kycState',
      'loading',
      'naturalPerson',
      'inMemoryNP',
      'countries',
      'cspCodes',
      'activities',
      'countryLabel',
      'cspLabel',
      'activityLabel',
      'wantToLeavePage',
      'currentUUID',
      'currentLanguage',
      'hasUpdatePermission'
    ]),
    checkDisabled() {
      return this.checkCompletedBasicInfo(this.panels.basicInfo.box_fields, this.naturalPerson)
    }
  }
}
</script>

<style scoped></style>
