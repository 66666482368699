import React, { useState } from 'react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@/atoms-react/input-checkbox/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Button } from '@/atoms-react/button/Button.react'
import toast from 'react-hot-toast'

type ModalAddWalletProps = {
  onValidated: (wallet: string) => void
} & ModalProps

export const ModalAddWallet = ({ onValidated = () => {}, ...props }: ModalAddWalletProps) => {
  const { t } = useTranslation(['wallet'])
  const [_wallet, _setWallet] = useState<string | null>('')
  const [agrees, setAgrees] = useState<boolean>(false)

  const execute = () => {
    if (!_wallet) {
      toast.error(t('kypay.modal_add_wallet.error.must_enter_wallet'))
      return
    }
    if (!agrees) {
      toast.error(t('kypay.modal_add_wallet.error.must_accept_condition'))
      return
    }

    onValidated(_wallet)
  }

  return (
    <Modal className="sm:w-full w-full max-w-lg" {...props}>
      <ModalContent title={t('kypay.modal_add_wallet.label.title')}>
        <div className={'mt-4 opacity-1'}>
          <div className={'w-full mt-4 mb-6'}>
            <InputLabel
              autoFocus={true}
              label={t('kypay.modal_add_wallet.label.new_wallet')}
              value={_wallet ?? ''}
              onChange={(value) => _setWallet(value)}
            />
          </div>

          <Checkbox value={agrees} onChange={(value) => setAgrees(value)}>
            <Base className="mb-6 cursor-pointer">
              {t('kypay.modal_add_wallet.label.checkbox_agree_correct_wallet')}
            </Base>
          </Checkbox>

          <Button onClick={() => execute()}>{t('button.continue')}</Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
