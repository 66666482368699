import { DateTime } from 'luxon'
import { Kycoin, KycoinDTOServer, KycoinTransaction, KycoinTransactionDTOServer } from './types'

export const KycoinConverters = {
  DTOServer_entity: (dto: KycoinDTOServer): Kycoin => {
    return {
      ...dto,
      transaction: dto.transaction.map((trans) =>
        KycoinConverters.transactionDTOServer_transactionEntity(trans)
      )
    }
  },
  transactionDTOServer_transactionEntity: (dto: KycoinTransactionDTOServer): KycoinTransaction => {
    return { ...dto, Date: DateTime.fromISO(dto.Date).toJSDate() }
  }
}
