<template>
  <div>
    <confirm-modal ref="confirmModal"></confirm-modal>
    <info-modal ref="infoModal"></info-modal>
  </div>
</template>

<script lang="ts">
import {Capacitor} from "@capacitor/core";
import {Device} from "@capacitor/device";
import {UserService} from "@/app/olkypay/services/user.service";
import ConfirmModal from "../ConfirmModal.vue";
import InfoModal from "../InfoModal.vue";
import {mapActions, mapGetters} from "vuex";
import {toastController} from "@ionic/vue";
import AuthService from "@/app/olkypay/services/auth.service";
import {NativeBiometric} from "capacitor-native-biometric";
export default {
  name: "biometricVerificationProcess",
  components: {
    ConfirmModal,
    InfoModal
  },
  data(){
    return {
      biometricServer: "Olkypay.eu",
      challenge: "",
    }
  },
  methods: {
    ...mapActions('auth', ['signIn', 'signInViaFingerPrint']),
    ...mapActions("user", ["setSecurityInfos", "setAuthenticationMethod"]),
    generateRandomString() {
      const length = 16;
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    },
    async generateSecret(device_uuid, username) {
      const randomString = this.generateRandomString();
      const passphrase = device_uuid + "#" + username + "#" + randomString;
      const encoder = new TextEncoder();
      const data = encoder.encode(passphrase);
      const hash = await crypto.subtle.digest("SHA-256", data);
      const hashArray = Array.from(new Uint8Array(hash));
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
      return hashHex
    },
    async handleLoginViaFingerPrint() {
      const result = await NativeBiometric.isAvailable();
      if (!result.isAvailable) return false;
      await NativeBiometric.verifyIdentity({
        reason: "For easy log in",
        title: this.$i18n.t('olkypay.labels.loginPage.reconnect_to_olky'),
        subtitle: "",
        description: "",
      })
          .then(async () => {
            await NativeBiometric.getCredentials({
              server: this.biometricServer,
            }).then(async (credentials) => {
              const secret = credentials.password;
              AuthService.signInViaFingerPrint({fingerPrint: secret})
                  .then(async (res) => {
                    if (res) {
                      const success_authentication =  this.$i18n.t('olkypay.notifications.success.loginPage.header');
                      const toast = await toastController.create({
                        message: success_authentication,
                        duration: 5000,
                        position: 'bottom',
                        color: 'success'
                      })
                      await toast.present();
                      this.$router.push({name: 'SuppliersRouter'})
                    }
                  })
                  .catch(async (err: any) => {
                    const failedDialog = {
                      type: 'warning',
                      title: this.$i18n.t('olkypay.notifications.error.loginPage.header'),
                      content: this.$i18n.t('olkypay.notifications.error.loginPage.message'),
                      button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
                    }
                    await this.$refs.infoModal.openModal(failedDialog)
                  })
                  .finally(() => {
                  })
            }).catch(async () => {
              const toast = await toastController.create({
                message: this.$i18n.t('olkypay.notifications.error.loginPage.message'),
                duration: 3000,
                position: 'bottom',
                color: 'danger'
              })
              await toast.present()

            })
          })
          .catch(async () => {
            const toast = await toastController.create({
              message: this.$i18n.t('olkypay.notifications.error.loginPage.message'),
              duration: 3000,
              position: 'bottom',
              color: 'danger'
            })
            await toast.present()
          });
    },
    async tryToSaveFingerPrint() {
      if (Capacitor.isNativePlatform()) {
        await NativeBiometric.getCredentials({
          server: this.biometricServer,
      }).then(async (credentials) => {})
        .catch(async () => {
          const result = await NativeBiometric.isAvailable();
          if (!result.isAvailable) {
            const toast = await toastController.create({
              message: "Biometric not available",
              duration: 3000,
              position: 'bottom',
              color: 'danger'
            })
            await toast.present()
          } else {
            const confirmDialog = {
              type: 'info',
              title: this.$i18n.t('olkypay.labels.suppliersPage.confirm_dialog.header'),
              content: this.$i18n.t('olkypay.labels.suppliersPage.confirm_dialog.content'),
              button: {
                cancel: this.$i18n.t('olkypay.buttons.suppliersPage.confirm_dialog.cancel'),
                confirm: this.$i18n.t('olkypay.buttons.suppliersPage.confirm_dialog.confirm')
              }
            }
            const response = await this.$refs.confirmModal.openModal(confirmDialog);
            if (response) {
              await this.saveFingerPrint()
            }
          }
        })
      }
      return null;
    },
    async saveFingerPrint() {
      const device_uuid = (await Device.getId()).identifier
      const secret_code = await this.generateSecret(device_uuid, this.username);
      // registerBiometricSecret
      const verified = await NativeBiometric.verifyIdentity({
        reason: "For easy log in",
        title: this.$i18n.t('olkypay.labels.loginPage.reconnect_to_olky'),
        subtitle: "",
        description: "",
      })
          .then(async () => {
            const inputData = {fingerPrint: secret_code}
            await UserService.saveFingerPrint(inputData)
                .then(async () => {
                  NativeBiometric.setCredentials({
                    username: this.username,
                    password: secret_code,
                    server: this.biometricServer,
                  }).then();
                  this.setAuthenticationMethod('finger_print')
                  await this.setSecurityInfos()
                  const successDialog = {
                    type: 'success',
                    title: this.$i18n.t(
                        'olkypay.notifications.success.suppliersPage.save_fingerprint.header'
                    ),
                    content: this.$i18n.t(
                        'olkypay.notifications.success.suppliersPage.save_fingerprint.message'
                    ),
                    button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
                  }
                  await this.$refs.infoModal.openModal(successDialog)
                })
                .catch(async (error) => {
                  const failedDialog = {
                    type: 'warning',
                    title: this.$i18n.t(
                        'olkypay.notifications.error.suppliersPage.save_fingerprint.header'
                    ),
                    content: this.$i18n.t(
                        'olkypay.notifications.error.suppliersPage.save_fingerprint.message'
                    ) + error,
                    button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
                  }
                  await this.$refs.infoModal.openModal(failedDialog)
                })

          }).catch( async () => {})

      /*this.$fingerprintAIO.registerBiometricSecret({
        secret: secret_code,
      })
          .then(async (result: any) => {
            const inputData = {fingerPrint: secret_code}
            await UserService.saveFingerPrint(inputData)
                .then(async () => {
                  this.setAuthenticationMethod('finger_print')
                  await this.setSecurityInfos()
                  const successDialog = {
                    type: 'success',
                    title: this.$i18n.t(
                        'olkypay.notifications.success.suppliersPage.save_fingerprint.header'
                    ),
                    content: this.$i18n.t(
                        'olkypay.notifications.success.suppliersPage.save_fingerprint.message'
                    ),
                    button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
                  }
                  await this.$refs.infoModal.openModal(successDialog)
                })
                .catch(async (error) => {
                  const failedDialog = {
                    type: 'warning',
                    title: this.$i18n.t(
                        'olkypay.notifications.error.suppliersPage.save_fingerprint.header'
                    ),
                    content: this.$i18n.t(
                        'olkypay.notifications.error.suppliersPage.save_fingerprint.message'
                    ) + error,
                    button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
                  }
                  await this.$refs.infoModal.openModal(failedDialog)
                })
          })
          .catch(async (error: any) => {
            const toast = await toastController.create({
              message: error,
              duration: 3000,
              position: 'bottom',
              color: 'danger'
            })
            await toast.present()
          });*/
    },
  },
  computed: {
    ...mapGetters('user', ['user_settings', 'username'])
  }
}
</script>

<style scoped>

</style>