import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import i18n from '@/features/utils/i18n.ts'
const uris = {
  _post: {
    list_identity_documents: '/document/list',
    new_kyc_session: '/document/new_kyc_session'
  },
  _get: {
    documents_type: 'document/types',
    documents_type_categ: 'document/typesByCateg'
  }
}
const state = {
  documents: [],
  documentsType: [],
  newDocument: {},
  kycDocument: {}
}

const getters = {
  documents: (state) => state.documents,
  documentsType: (state) => state.documentsType,
  newDocument: (state) => state.newDocument,
  kycDocument: (state) => state.kycDocument
}

const mutations = {
  async LOAD_IDENTITY_DOCUMENTS(state, response) {
    state.documents = response.data
  },
  async LOAD_DOCUMENTS_TYPE(state, response) {
    state.documentsType = response.data
  },
  async LOAD_FILTERS_DOCUMENTS_TYPE(state, response) {
    state.documentsType = response.data
  },
  SET_NEW_DOCUMENT(state, data) {
    state.newDocument = data
  }
}

const actions = {
  async loadIdentityDocuments({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.list_identity_documents, { body: data })
      commit('LOAD_IDENTITY_DOCUMENTS', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async loadDocumentsType({ commit }) {
    try {
      const response = await fetchServer(uris._get.documents_type)
      response.data = response.data.filter(
        (item) =>
          [
            1, // "Organigramme"
            2, // "Carte d\u0027identit\u00e9"
            3, // "Passeport"
            4, // "Permis de conduire"
            5, // "Selfie"
            6, // "Titre de s\u00e9jour"
            7, // "Autre pi\u00e8ce d\u0027identit\u00e9"
            16, // "Contrat de bail”
            20, // "RCS \/ KBIS"
            21, // "Registre actions"
            22, // "Autre Extrait RCS"
            24, // "Contrat"
            25, // "Facture"
            42, // "Acte"
            43, // "Statut"
            47, // "Avis d\u0027imposition"
            48, // "Utility bill"
            49, // "Autre justificatif de domicile"
            50, // "Attestation h\u00e9bergeur"
            51, // "Quittance de loyer"
            52, // "Compte annuel”
            27 // "Autre"
          ].indexOf(item.id) >= 0
      )
      commit('LOAD_DOCUMENTS_TYPE', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async loadFiltersDocumentsType({ commit }, data) {
    try {
      const response = await fetchServer(uris._get.documents_type_categ + '/' + data.category)
      commit('LOAD_FILTERS_DOCUMENTS_TYPE', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  setNewDocument({ commit }, data) {
    commit('SET_NEW_DOCUMENT', data)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
