import { A } from '@/atoms-react/a/A.react'
import { Button } from '@/atoms-react/button/Button.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import Image from '@/atoms-react/image/Image.react'
import { Modal } from '@/atoms-react/modal/index.react'
import { RadioCard } from '@/atoms-react/radio-card/index.react'
import { Info, Base, CardTitle, Title } from '@/atoms-react/text/Text.react'
import Flag from '@/atoms/flag/Flag.react'
import { AnimatedEnvelopePass } from '@/components/animations/envelope-olkypass/index.react'
import config from '@/config'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { useBankAccount } from '@/features/user/bank-account/hooks/use-bank-account'
import { useUser } from '@/features/user/hooks/use-user'
import { getWebBankingUrl } from '@/features/utils/web-banking-url'
import { Themes } from '@/types/theme'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import confetti from 'canvas-confetti'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

enum ModalIbanAccountStep {
  Start,
  Country,
  Loading,
  Success,
  Error
}

type ModalIbanAccountProps = {
  isOpen: boolean
  onClose: () => void
  openKYCModal: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const ModalIbanAccount = ({ isOpen, onClose, openKYCModal }: ModalIbanAccountProps) => {
  const { t } = useTranslation(['pass'])
  const { status } = useKYCContext()
  const [step, setStep] = useState<ModalIbanAccountStep>(ModalIbanAccountStep.Start)
  const { useUserProfile } = useUser()
  const { data: user, refetch: refetchUser } = useUserProfile()
  const { id } = useCompanyContext()
  const [country, setCountry] = useState('')
  const [iban, setIban] = useState<string>()
  const [bic, setBic] = useState<string>()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (step === ModalIbanAccountStep.Loading) {
      setProgress(0)
      const interval = 100
      const totalDuration = 3000
      let elapsed = 0

      timer = setInterval(() => {
        elapsed += interval
        setProgress((elapsed / totalDuration) * 100)
        if (elapsed >= totalDuration) {
          const onLoadingEnd = async () => {
            clearInterval(timer)
            try {
              await onCreateBankAccount()
              confettiExplosion()
              setStep(ModalIbanAccountStep.Success)
            } catch (e) {
              setStep(ModalIbanAccountStep.Error)
            }
          }
          onLoadingEnd()
        }
      }, interval)
    }
    return () => clearInterval(timer)
  }, [step, setStep])

  useEffect(() => {
    // Reset the step when the modal is reopened
    if (isOpen) {
      setStep(ModalIbanAccountStep.Start)
    }
  }, [isOpen])

  const { useCreateBankAccountMutation } = useBankAccount()
  const { mutateAsync: createBankAccount } = useCreateBankAccountMutation()

  const nothasIban =
    typeof user?.naturalPerson.olkyAccount?.iban !== 'string' ||
    user?.naturalPerson.olkyAccount?.iban === null

  const confettiExplosion = () => {
    const duration = 2 * 750
    const animationEnd = Date.now() + duration
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 100 }

    const randomInRange = (min: number, max: number) => Math.random() * (max - min) + min

    const interval = window.setInterval(() => {
      const timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      const particleCount = 250 * (timeLeft / duration)

      confetti({
        ...defaults,
        particleCount: Math.floor(particleCount),
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      })
      confetti({
        ...defaults,
        particleCount: Math.floor(particleCount),
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      })
    }, 150)
  }

  const onCreateBankAccount = async () => {
    if (country !== 'FR' && country !== 'LU') {
      throw new Error('Error when creating olky account')
    }

    const data = await createBankAccount(country)

    if (!data.success) {
      throw new Error('Error when creating olky account')
    }

    setIban(data.iban)
    setBic(data.bic)
    await refetchUser()
  }

  const backgroundStartStep =
    status === KYCStatusType.DECLARATIVE
      ? 'bg-slate-50'
      : " bg-cover bg-[url('/images/olkypass/olkyPayConso.png')]"

  return (
    <Modal
      open={isOpen}
      closable={step === ModalIbanAccountStep.Start || step === ModalIbanAccountStep.Country}
      onClose={onClose}
      className="sm:w-full w-full max-w-lg"
    >
      <div className="flex flex-col h-[500px]">
        {step === ModalIbanAccountStep.Start && (
          <>
            <div className="h-2/5 md:h-1/2 w-full rounded-sm mb-6 flex items-center justify-center overflow-hidden">
              <div
                className={twMerge(
                  'h-full w-full rounded-sm flex items-center justify-center',
                  backgroundStartStep
                )}
              >
                {status === KYCStatusType.DECLARATIVE ? (
                  <AnimatedEnvelopePass />
                ) : (
                  <div className="bg-blue-800 bg-opacity-30 h-full w-full flex flex-col items-center justify-center gap-y-3 p-6">
                    <Image
                      src="/images/olkypass/olkypay-logo.svg"
                      className="w-24"
                      alt="Olky Logo"
                    />
                    <CardTitle className="text-white text-center">
                      {t('flow.id_control.olky_intro.title.olkyPayNP')}
                    </CardTitle>
                  </div>
                )}
              </div>
            </div>
            {status === KYCStatusType.DECLARATIVE && nothasIban && (
              <>
                <CardTitle className="text-center mb-4">
                  {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_declarative')}
                </CardTitle>
                <Base className="text-center text-slate-400 dark:text-slate-400">
                  {t(
                    'olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_description_declarative'
                  )}
                </Base>
                <Button onClick={openKYCModal} className="mt-auto">
                  {t('olkypass.mainPage.button.completePass')}
                </Button>
              </>
            )}
            {status === KYCStatusType.NEUTRAL && nothasIban && (
              <>
                <CardTitle className="text-center mb-4">
                  {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_neutral')}
                </CardTitle>
                <Base className="text-center text-slate-400 dark:text-slate-400">
                  {t(
                    'olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_neutral_description'
                  )}
                </Base>
                <Button onClick={onClose} className="mt-auto">
                  {t('olkypass.general.understand')}
                </Button>
              </>
            )}
            {(status === KYCStatusType.VERIFIED || status === KYCStatusType.AUTHENTICATED) &&
              nothasIban && (
                <>
                  <CardTitle className="text-center mb-4">
                    {id
                      ? t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_pro')
                      : t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_conso')}
                  </CardTitle>
                  <Base className="text-center text-slate-400 dark:text-slate-400">
                    {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_description')}
                  </Base>
                  <div className="flex flex-col items-center mt-auto">
                    <DotsNavigation total={2} current={0} className="mb-4" />
                    <Button
                      onClick={() => {
                        setStep(ModalIbanAccountStep.Country)
                      }}
                    >
                      {t('olkypass.general.continue')}
                    </Button>
                  </div>
                </>
              )}
          </>
        )}
        {step === ModalIbanAccountStep.Country && (
          <div className="flex flex-col items-center justify-center h-full">
            <CardTitle className="text-center mb-4">
              {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.choose_country')}
            </CardTitle>

            <Base>{t('olkypass.mainPage.olkypass.modalOpenOlkyPay.choose_country_text')}</Base>

            <div className="space-y-4 mt-4 w-full mt-auto">
              {['FR', 'LU'].map((c) => (
                <RadioCard active={country === c} onClick={() => setCountry(c)}>
                  <Flag countryCode={c} className="mb-4" />
                  <Base>IBAN {c}</Base>
                  <Info>{c}XX XXXX XXXX XXXX XXX</Info>
                </RadioCard>
              ))}
            </div>

            <div className="flex flex-col items-center mt-auto">
              <DotsNavigation total={2} current={1} className="mb-4" />
              <Button
                disabled={!country}
                onClick={() => {
                  setStep(ModalIbanAccountStep.Loading)
                }}
              >
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.button_create_my_account')}
              </Button>
            </div>
          </div>
        )}
        {step === ModalIbanAccountStep.Loading && (
          <div className="flex flex-col items-center justify-center h-full">
            <CardTitle className="text-center mb-4">
              {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.creating_your_account')}
            </CardTitle>
            <div className="w-full h-2 bg-gray-200 rounded-full">
              <div
                className="h-2 bg-blue-500 rounded-full"
                style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
              />
            </div>
          </div>
        )}
        {step === ModalIbanAccountStep.Success && (
          <>
            <div className="h-2/5 md:h-1/2 w-full rounded-sm mb-6 flex items-center justify-center overflow-hidden">
              <div className="w-full h-full rounded-sm border-2 border-slate-50 p-4 relative overflow-hidden flex flex-col">
                <div className="h-40 w-40 absolute opacity-20 right-0 bottom-0">
                  <Image src="images/olkypass/leaves.png" alt="leaves" />
                </div>

                {/* User First and Last Name */}
                <div className="flex justify-between items-center mb-6">
                  <CardTitle className="font-semibold">
                    {user?.naturalPerson.FirstName} {user?.naturalPerson.LastName}
                  </CardTitle>
                  <Image src="images/olkypass/olkyPayLogo.svg" alt="OlkyPay logo" />
                </div>

                {/* IBAN and BIC Container */}
                <div className="flex grow flex-col justify-center">
                  {/* IBAN */}
                  <div className="mb-3">
                    <Base className="text-slate-500">IBAN</Base>
                    <Base className="font-semibold">{iban?.replace(/(.{4})/g, '$1 ').trim()}</Base>
                  </div>
                  {/* BIC */}
                  <div>
                    <Base className="text-slate-500">BIC</Base>
                    <Base className="font-semibold">{bic}</Base>
                  </div>
                </div>
              </div>
            </div>
            <>
              <CardTitle className="text-center mb-4">
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.congratulations')}
              </CardTitle>
              <Base className="text-center text-slate-400 dark:text-slate-400">
                {id
                  ? t('olkypass.mainPage.olkypass.modalOpenOlkyPay.congratulations_pro_description')
                  : t('olkypass.mainPage.olkypass.modalOpenOlkyPay.congratulations_description')}
              </Base>
              <div className="flex flex-col items-center mt-auto">
                {/*<DotsNavigation total={3} current={2} className="mb-4" />*/}
                <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
                  <Button
                    theme={Themes.white}
                    onClick={() => {
                      onClose()
                    }}
                  >
                    {t('olkypass.general.close')}
                  </Button>
                  <A
                    href={getWebBankingUrl(
                      user?.naturalPerson.AliasEmail?.find((a) => a?.Actif)?.Mail
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button onClick={() => {}}>
                      {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.open_webbanking_button')}
                    </Button>
                  </A>
                </div>
              </div>
            </>
          </>
        )}
        {step === ModalIbanAccountStep.Error && (
          <>
            <div className="h-2/5 md:h-1/2 w-full rounded-sm mb-6 flex items-center justify-center overflow-hidden">
              <ExclamationTriangleIcon className="text-red-500 h-full w-auto" />
            </div>
            <>
              <CardTitle className="text-center mb-4">
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.error')}
              </CardTitle>
              <Base className="text-center text-slate-400 dark:text-slate-400">
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.error_description')}
              </Base>
              <div className="flex flex-col items-center mt-auto">
                {/*<DotsNavigation total={3} current={2} className="mb-4" />*/}
                <Button
                  onClick={() => {
                    onClose()
                  }}
                >
                  {t('olkypass.accountPage.dialog.newItems.button.finish')}
                </Button>
              </div>
            </>
          </>
        )}
      </div>
    </Modal>
  )
}
