import _ from 'lodash'
import React, { ReactNode } from 'react'
import { Link as L } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export type CustomLinkProps = {
  href?: string
  to?: string | false
  onClick?: () => void
  children?: ReactNode | ReactNode[]
  noColor?: boolean
  disabled?: boolean
  className?: string
} & Omit<
  Omit<Omit<Omit<React.ComponentPropsWithoutRef<'a'>, 'onClick'>, 'className'>, 'disabled'>,
  'href'
>

export const A = ({
  href,
  to,
  onClick,
  children,
  noColor,
  disabled,
  className,
  ...props
}: CustomLinkProps) => {
  const colors = twMerge(
    noColor ? '' : 'hover:underline text-blue-500 cursor-pointer font-semibold outline-none',
    disabled ? 'pointer-events-none opacity-75' : ''
  )

  if (onClick) {
    return (
      <span
        className={twMerge(colors, `${className || ''}`)}
        onClick={() => onClick()}
        {..._.omit(props, 'children', 'className', 'noColor', 'to')}
      >
        {children}
      </span>
    )
  }

  if ((href || '')?.startsWith('http') || (href || '')?.startsWith('mailto:')) {
    return (
      <a
        href={href}
        className={twMerge(colors, `${className || ''}`)}
        {..._.omit(props, 'children', 'className', 'noColor')}
      >
        {children}
      </a>
    )
  }

  return (
    <L
      to={(!onClick && (href || to)) || '#'}
      className={twMerge(colors, className || '')}
      {..._.omit(props, 'children', 'className', 'noColor', 'to')}
    >
      {children}
    </L>
  )
}
