import { Button } from '@/atoms/buttons/Button'
import { Notice } from '@/atoms/notice'
import { StoreButtons } from '@/atoms/stores-buttons'
import { Base, Info, SectionTitle } from '@/atoms/text/Text.tsx'
import { useSecurity } from '@/features/security/hooks/use-security'
import { defineJsxComponent } from '@/jsx-utils'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toast-notification'
import VueQrCode from 'vue-qrcode'
import { InputCopiable } from '@/atoms/inputs/Copiable'
import { InputOTP } from '@/atoms/inputs/Otp'

const VueQrCodeAny = VueQrCode as any

export const Security2faConfig = defineJsxComponent<{
  onComplete: () => void
}>((props) => {
  const toast = useToast()
  const loading = ref(false)
  const otp = ref('')
  const qrContent = ref('')
  const secret = ref('')
  const { activate2fa, setup2fa } = useSecurity()
  const t = useI18n().t
  const step = ref(0)

  const validateOtp = async (code: string) => {
    loading.value = true
    try {
      if (await activate2fa(code, secret.value)) {
        step.value += 1
        toast.success(
          t('olkypass.securityPage.passwordSection.2fa.modal.notifications.otp.success')
        )
      } else {
        throw new Error('Invalid OTP')
      }
    } catch (e) {
      toast.error(t('olkypass.securityPage.passwordSection.2fa.modal.notifications.otp.error'))
    }
    loading.value = false
  }

  return () => (
    <div>
      {step.value === 0 && (
        <div>
          <Notice type="warning">
            {t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.important')}
            <Info class="block mt-2">
              {t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.message1')}
            </Info>
            <Info class="block mt-2">
              {t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.message2')}
            </Info>
          </Notice>

          <Base class="block mt-4">Google Authenticator</Base>
          <StoreButtons
            class="mt-2 mr-2"
            theme="google-btn"
            src="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          />
          <StoreButtons
            class="mt-2 mr-2"
            theme="apple-btn"
            src="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
          />
          <Info class="block mt-2">
            {t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.message3')}
          </Info>

          <Button
            loading={loading.value}
            class="mt-4 float-right"
            onClick={async () => {
              loading.value = true
              try {
                const res = await setup2fa()
                secret.value = res?.secret || ''
                qrContent.value = res?.qrContent || ''
                if (secret.value) step.value += 1
              } catch (e) {
                toast.error('An error occurred')
              }
              loading.value = false
            }}
          >
            {t('olkypass.general.next')}
          </Button>
        </div>
      )}
      {step.value === 1 && (
        <div>
          <SectionTitle>
            {t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.step1_title')}
          </SectionTitle>

          <Info>{t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.step1')}</Info>

          <div class="text-center">
            <div class="inline-block my-2 bg-white border border-slate-200 rounded-sm mx-auto overflow-hidden">
              <VueQrCodeAny
                value={qrContent.value}
                type="image/png"
                class="inline-block w-32 h-32"
              />
            </div>
          </div>

          <Info class="mb-2">
            {t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.step1b')}
          </Info>

          <InputCopiable value={secret.value} type="password" />

          <br />

          <SectionTitle>
            {t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.step2_title')}
          </SectionTitle>

          <Info>{t('olkypass.securityPage.passwordSection.2fa.modal.help.activation.step2')}</Info>

          <InputOTP
            v-model={otp.value}
            size="md"
            length={6}
            separator={3}
            prefix=""
            onComplete={(code: any) => validateOtp(code)}
          />

          <Button
            loading={loading.value}
            disabled={otp.value.length < 6}
            class="mt-8 float-right"
            onClick={async () => {
              validateOtp(otp.value)
            }}
          >
            {t('olkypass.securityPage.passwordSection.2fa.modal.button.enable')}
          </Button>
          <Button class="mt-8 float-left" theme="outlined" onClick={() => (step.value -= 1)}>
            {t('olkypass.general.previous')}
          </Button>
        </div>
      )}
      {step.value >= 2 && (
        <div>
          <Info class="block">
            {t('olkypass.securityPage.passwordSection.2fa.modal.notifications.otp.success')}
          </Info>
          <Button class="mt-4 float-right" onClick={() => props.onComplete()}>
            {t('olkypass.general.close')}
          </Button>
        </div>
      )}
    </div>
  )
})
