import { fetchServer } from '@/features/utils/fetch'
import type {
  AliasDelete,
  FullCountry,
  StaticActivity,
  StaticCSP,
  StaticData,
  StaticLegalStatus,
  StaticNace,
  UserType
} from '../types'
import { NaturalEntityModify } from '@/features/utils/types'
import { ConfirmationResponse } from '@/features/register/types'

export class AuthApiClient {
  static async profile(): Promise<UserType> {
    const profile = await fetchServer('/user/profile')
    const applications = await fetchServer('/user/applications')
    const data = { ...(await profile.json()), applications: await applications.json() }
    return data
  }

  static async editProfile(data: NaturalEntityModify): Promise<ConfirmationResponse> {
    // @ts-expect-error Output
    return fetchServer('/user/profile/edit', { method: 'PUT', body: data, reloadOn401: false })
  }

  static async putPartialProfile(data: NaturalEntityModify) {
    return fetchServer('/user/profile/edit', { method: 'PUT', body: data })
  }

  static async getStaticData(locale: string): Promise<StaticData> {
    const response = await fetchServer('/public/static_data/' + locale.toLowerCase(), {
      reloadOn401: false
    })
    const data = await response.json()
    return data
  }

  static async getStaticCountries(locale: string): Promise<{ countries: FullCountry[] }> {
    const response = await fetchServer('/public/countries/' + locale.toLowerCase(), {
      reloadOn401: false
    })
    const data = await response.json()
    return data
  }

  static async getStaticCSP(locale: string): Promise<{ cspCodes: StaticCSP[] }> {
    const response = await fetchServer('/public/csp/' + locale.toLowerCase(), {
      reloadOn401: false
    })
    const data = await response.json()
    return data
  }

  static async getStaticActivities(locale: string): Promise<{ activities: StaticActivity[] }> {
    const response = await fetchServer('/public/activities/' + locale.toLowerCase(), {
      reloadOn401: false
    })
    const data = await response.json()
    return data
  }

  static async getStaticLegalStatus(locale: string): Promise<{ legalStatus: StaticLegalStatus[] }> {
    const response = await fetchServer('/public/legal_status/' + locale.toLowerCase(), {
      reloadOn401: false
    })
    const data = await response.json()
    return data
  }

  static async getStaticNace(locale: string): Promise<{ naceCodes: StaticNace[] }> {
    const response = await fetchServer('/public/nas/' + locale.toLowerCase(), {
      reloadOn401: false
    })
    const data = await response.json()
    return data
  }

  static async deleteAlias(data: AliasDelete): Promise<ConfirmationResponse> {
    const response = await fetchServer('/user/profile/alias/delete', { method: 'PUT', body: data, reloadOn401: false })
    const success = response.status === 200
    if (response.status !== 200) {
      throw new Error("Cannot be deleted");
    }
    return { success, message: "" }
  }
}
