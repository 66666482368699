import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { Notice } from '@/atoms/notice'
import { CollaboratorType } from '@/features/company/types'
import { defineJsxComponent } from '@/jsx-utils'
import { CollaboratorItem } from '@/app/olkypass/views/account/company-details/components/collaborator-item'
import { useCollaborator } from '@/features/company/hooks/use-collaborator'

export const CollaboratorList = defineJsxComponent<any>((props) => {
  const t = useI18n().t
  const { collaboratorStore } = useCollaborator()
  const filteredCollaborators = computed(() => {
    if (collaboratorStore.collaborators) {
      return collaboratorStore.collaborators.filter((collaborator: CollaboratorType) => {
        const search = props.search
        if (null === search || undefined === search) {
          return true
        }
        const result =
          collaborator.naturalPerson.identity_uuid?.toLowerCase().includes(search.toLowerCase()) ||
          collaborator.naturalPerson.first_name?.toLowerCase().includes(search.toLowerCase()) ||
          collaborator.naturalPerson.last_name?.toLowerCase().includes(search.toLowerCase())
        return result
      })
    } else {
      return []
    }
  })

  return () => (
    <div className="my-4">
      {filteredCollaborators.value.length > 0 && (
        <>
          <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flow-root">
              <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                {filteredCollaborators.value.map((collaborator) => (
                  <CollaboratorItem
                    key={collaborator.naturalPerson.identity_uuid}
                    collaborator={collaborator}
                  />
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
      {!filteredCollaborators.value.length && (
        <>
          <Notice type="info" class="mt-4">
            {t('olkypass.companyPage.help.no_data_found')}
          </Notice>
        </>
      )}
    </div>
  )
})
