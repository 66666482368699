import {UseMutationOptions, useMutation} from '@tanstack/react-query'
import { useTranslation } from "react-i18next";
import { SecurityApiClient } from "../api-client/api-client";
import { ChangePasswordErrorCode, ChangePasswordParams, ChangePasswordResponse } from "../types";


export default function useChangePasswordMutation(options?: UseMutationOptions<ChangePasswordResponse, Error, ChangePasswordParams>) {
    const { t } = useTranslation(["pass"]);

    return useMutation<ChangePasswordResponse, Error, ChangePasswordParams>({mutationFn: async (params: ChangePasswordParams) => {
        const data = await SecurityApiClient.updatePassword(params.password);
        if (!data.success) {
            throw new Error(data.errorCode === ChangePasswordErrorCode.WrongOTP ? t('olkypass.securityPage.passwordSection.2fa.modal.notifications.otp.error') : t('flow.id_control.error.password_not_correct'))
        }
        return data
    }, ...options})
}