import { AuthApiClient } from "@/features/auth/api-client/api-client";
import { CheckLoginResponse } from "@/features/auth/types";
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

export type CheckLoginMutationProps = { 
    email: string, 
    password: string
}

export default function useCheckLoginMutation(options?: UseMutationOptions<CheckLoginResponse, Error, CheckLoginMutationProps>) {
    return useMutation<CheckLoginResponse, Error, CheckLoginMutationProps>({mutationFn: async ({email, password}) => {
        const response = await AuthApiClient.checkLogin(email, password)
        return response
    }, ...options})
}

