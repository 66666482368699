<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons v-if="goBackUrl !== ''" slot="start">
        <ion-back-button :default-href="goBackUrl"></ion-back-button>
      </ion-buttons>
      <ion-buttons slot="secondary">
        <ion-button>
          <ion-icon slot="icon-only" :icon="personCircle"></ion-icon>
        </ion-button>
        <ion-button>
          <ion-icon slot="icon-only" :icon="search"></ion-icon>
        </ion-button>
        <ion-button @click="handleLogout">
          <ion-icon slot="icon-only" :icon="logOut"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-buttons slot="primary">
        <ion-button>
          <ion-icon slot="icon-only" :ios="ellipsisHorizontal" :md="ellipsisVertical"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { personCircle, search, logOut, ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons'
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonButton,
  IonBackButton,
  alertController
} from '@ionic/vue'
import { mapActions } from 'vuex'

export default {
  name: 'HeaderTabs',
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonButton,
    IonBackButton
  },
  props: ['goBackUrl'],
  data() {
    return {
      personCircle,
      search,
      logOut,
      ellipsisHorizontal,
      ellipsisVertical
    }
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions('loader', [
      'playSkeletonLoader',
      'stopSkeletonLoader',
      'playLoader',
      'stopLoader'
    ]),
    async handleLogout() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.signOut.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      this.signOut()
        .then((res) => {
          this.$router.replace({ name: 'LoginRouter' })
        })
        .catch(async (err: any) => {
          const errorAlert = await alertController.create({
            header: 'Failed',
            subHeader: 'Sign out Failed',
            message: err,
            buttons: ['OK']
          })
          await errorAlert.present()
        })
        .finally(() => {
          this.stopLoader()
        })
    }
  }
}
</script>

<style scoped></style>
