import { getJwt } from '@/features/auth/utils'
import { ConfirmationResponse } from '@/features/register/types'
import { AuthApiClient } from '@/features/user/api-client/api-client'
import { UserType } from '@/features/user/types'
import { NaturalEntityModify } from '@/features/utils/types'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query'
import _ from 'lodash'

export const useUser = () => {
  const useUserProfile = (props?: UseQueryOptions<UserType>) =>
    useQuery<UserType>({
      queryKey: props?.queryKey ?? ['user', 'profile'],
      queryFn: () => AuthApiClient.profile(),
      enabled: props?.enabled ?? !!getJwt()?.token ?? false,
      ..._.omit(props, 'queryKey', 'enabled')
    })

  const useStaticData = (locale: string) =>
    useQuery({
      queryKey: ['user', 'profile', 'static-data'],
      queryFn: () => AuthApiClient.getStaticData(locale)
    })

  const useCountries = (locale: string) =>
    useQuery({
      queryKey: ['user', 'profile', 'static-data', 'countries', locale],
      queryFn: () => AuthApiClient.getStaticCountries(locale)
    })

  const useCSP = (locale: string) =>
    useQuery({
      queryKey: ['user', 'profile', 'static-data', 'CSP', locale],
      queryFn: () => AuthApiClient.getStaticCSP(locale)
    })

  const useActivities = (locale: string) =>
    useQuery({
      queryKey: ['user', 'profile', 'static-data', 'activities', locale],
      queryFn: () => AuthApiClient.getStaticActivities(locale)
    })

  const useLegalStatus = (locale: string) =>
    useQuery({
      queryKey: ['user', 'profile', 'static-data', 'legalStatus', locale],
      queryFn: () => AuthApiClient.getStaticLegalStatus(locale)
    })

  const useLegalNace = (locale: string) =>
    useQuery({
      queryKey: ['user', 'profile', 'static-data', 'nace', locale],
      queryFn: () => AuthApiClient.getStaticNace(locale)
    })

  const useEditProfile = (
    options?: UseMutationOptions<ConfirmationResponse, Error, NaturalEntityModify>
  ) => {
    return useMutation<ConfirmationResponse, Error, NaturalEntityModify>({
      mutationFn: async (naturalEntity) => {
        return AuthApiClient.editProfile(naturalEntity)
      },
      ...options
    })
  }

  return {
    useUserProfile,
    useStaticData,
    useCSP,
    useActivities,
    useLegalStatus,
    useLegalNace,
    useEditProfile,
    useCountries
  }
}
