import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import Authenticate, { AuthenticateOutput } from './authenticate/Authenticate.react'
import TwoFaAuthenticate, {
  TwoFaAuthenticateOutput
} from './two-fa-authenticate/TwoFaAuthenticate.react'
import { useState } from 'react'
import { JwtType } from '@/features/auth/types'
import { animatedNext } from '../../layout/AnimatedForm.react'
import { useParams } from 'react-router-dom'
import { RecoverPassword } from './recover-password/index.react'
import { ResetPassword } from './reset-password/index.react'

export type LoginInput = {
  email?: string
  password?: string
  is2faActivated?: boolean
}

export type LoginOutput = JwtType

interface LoginRootProps extends LayoutBaseProps {
  input: LoginInput
  onValidated?: (data: LoginOutput) => void
}

function LoginRoot({ input, onValidated = () => {} }: LoginRootProps) {
  const [authenticationState, setAuthenticationState] = useState<AuthenticateOutput>()
  const { step } = useParams()

  if (step === 'recover') {
    return <RecoverPassword />
  }

  if (step === 'reset') {
    return <ResetPassword />
  }

  if (authenticationState && authenticationState.is2FARequired) {
    return (
      <TwoFaAuthenticate
        input={{ email: input.email ?? '', password: authenticationState.password ?? '' }}
        onValidated={(data: TwoFaAuthenticateOutput) => onValidated(data)}
      />
    )
  }

  return (
    <Authenticate
      email={input.email ?? ''}
      is2faActivated={input.is2faActivated ?? false}
      onValidated={(data) => {
        if (!data.is2FARequired) {
          onValidated({ token: data.token, refresh: data.token })
        } else {
          animatedNext('forward', async () => {
            setAuthenticationState(data)
          })
        }
      }}
    />
  )
}

export default LoginRoot
