import { PasswordInput } from "@/atoms-react/input/password-input.react"
import { Button } from "@/atoms-react/button/Button.react"
import Column from "@/atoms-react/column/Column.react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import useChangePasswordMutation from "@/features/security/hooks/use-change-password"

enum ChangePasswordStep {
    Email,
    PasswordAndOTP,
    Success
}

type ChangePasswordProps = {
    onValidated?: () => void
}

export const ChangePassword = ({ onValidated = () => {} }: ChangePasswordProps) => {
    const { t } = useTranslation(['pass'])
    const [password, setPassword] = useState<string>()
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
    const [step, setStep] = useState<ChangePasswordStep>(ChangePasswordStep.PasswordAndOTP)
    const [error, setError] = useState<string | null>(null)
    const { mutateAsync: changePassword, isPending: isChangingPassword } = useChangePasswordMutation()

    const onSubmitChangePassword = async () => {
        try {
            if (!password /* || !cypher || !code */) {
                throw new Error(t('olkypass.forgetPasswordPage.notifications.error.reset'))
            }
            await changePassword({ password })
            onValidated()
        } catch (e: any) {
            setError(e.message)
        }
    }

    return (
        <div>
            {step === ChangePasswordStep.PasswordAndOTP && (
                <Column>
                    <div className="grow mb-12 flex flex-col">
                        <PasswordInput
                            onChange={({ password, valid }) => {
                                setPassword(password)
                                setIsPasswordValid(valid)
                            }}
                            label={t('olkypass.securityPage.passwordSection.input.new_pwd')}
                        />
                    </div>

                    <div className="shrink-0">
                        <Button
                            shortcut="enter"
                            onClick={onSubmitChangePassword}
                            disabled={!isPasswordValid/*  || !isCodeValid */}
                            loading={isChangingPassword}
                        >
                            {t('flow.id_control.input.save')}
                        </Button>
                    </div>
                </Column>
            )}
        </div>
    )
}
