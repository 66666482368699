import _ from 'lodash'
import { twMerge } from 'tailwind-merge'

type DotsNavigationProps = {
  total: number
  current?: number
} & React.ComponentPropsWithoutRef<'div'>

export default function DotsNavigation({ total, current, ...props }: DotsNavigationProps) {
  return (
    <>
      <div className={twMerge('flex space-x-3', props.className)} {..._.omit(props, 'className')}>
        {Array.from({ length: total }, (_, index) => (
          <div
            key={index}
            className={twMerge(
              current !== undefined && index <= current
                ? 'bg-blue-500'
                : 'bg-blue-100 dark:bg-blue-800',
              'rounded-lg h-2 w-2'
            )}
          ></div>
        ))}
      </div>
    </>
  )
}
