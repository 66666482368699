import { useToast } from 'vue-toast-notification'
import { SecurityApiClient } from '../api-client/api-client'
import { useSecurityStore } from '../store'
import { useI18n } from 'vue-i18n'
import { WebAuthnRegisterApi } from '@/features/auth/api-client/webauthn-register-api-client'
import { WebAuthnApi } from '@/features/auth/api-client/webauthn-api-client'
import { StorageService } from '@/features/utils/storage'

export const useSecurity = () => {
  const { t } = useI18n()
  const toast = useToast()
  const store = useSecurityStore()

  const refresh = async () => {
    try {
      const username = StorageService.getItem('username')
      const res = await SecurityApiClient.is2faActive()
      const webauthnRes = (await WebAuthnRegisterApi.checkActivation())?.isWebAuthnActivated
      store.is2faActive = res
      let webauthnDevices: any[] = []
      try {
        webauthnDevices = await WebAuthnApi.retrieveWebAuthnAllCredentials(username)
      } catch (e: any) {
        console.error(e)
      }
      store.isWebAuthnActive = webauthnRes
      store.initialized = true
      store.webAuthnUserDevices = webauthnDevices
    } catch (e: any) {
      console.error(e)
      toast.error('We were unable to load your security settings: ' + e.message)
    }
    store.loading = false
  }

  const setup2fa = async () => {
    store.loading = true
    try {
      return await SecurityApiClient.setup2fa()
    } catch (e) {
      toast.error('We were unable to setup 2FA. Please try again later.')
    }
    store.loading = false
  }

  const reset2Fa = async () => {
    store.loading = true
    try {
      const res = await SecurityApiClient.reset2Fa()
      if (!res) throw new Error('Invalid reset2fa')
      await refresh()
      return true
    } catch (e) {
      toast.error('We were unable to reset 2FA. Please try again later.')
    }
    store.loading = false
  }

  const activate2fa = async (otp: string, secret: string) => {
    store.loading = true
    const res = await SecurityApiClient.activate2fa(otp, secret)
    if (!res) throw new Error('Invalid activate2fa')
    store.is2faActive = res
    store.loading = false
    return true
    store.loading = false
    return false
  }

  const updatePassword = async (password: string) => {
    store.loading = true
    try {
      const res = await SecurityApiClient.updatePassword(password)
      if (!res) {
        throw new Error('Invalid updatePassword')
      } else {
        toast.success(t('security.passwordPage.modal.success'))
      }

      store.loading = false
      return res
    } catch (e) {
      toast.error('We were unable to update your password. Please try again later.')
    }
    store.loading = false
    return false
  }
  /**
   *
   * @param email
   * @param language
   */
  const preUpdatePwdSendEmailCode = async (email: string, language: string) => {
    try {
      const { success, data } = await SecurityApiClient.sendEmailCode(email, language)
      if (success && data) {
        return data
      }
      return null
    } catch (e) {
      return null
    }
  }
  const validateEmailCode = async (encryptedData?: string, code?: string) => {
    try {
      const { success, data } = await SecurityApiClient.checkEmailCode(encryptedData!, code!)
      if (success && data) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  if (!store.initialized) {
    refresh()
  }

  return {
    store,
    refresh,
    setup2fa,
    reset2Fa,
    activate2fa,
    updatePassword,
    preUpdatePwdSendEmailCode,
    validateEmailCode
  }
}
