import { Routes } from '@/features/router'
import { Button } from '@/atoms/buttons/Button'
import { InputOTP } from '@/atoms/inputs/Otp'
import { Link } from '@/atoms/link/Link2'
import { BaseSmall, Info } from '@/atoms/text/Text.tsx'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import { ref } from 'vue'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'
import { useRegister } from '@/features/register/hooks/use-register'
import type { RegisterCredentialType } from '@/features/register/types'
import { useRouter } from 'vue-router'

export const FormValidationCodeView = defineVueComponent(() => {
  const { t } = useI18n()
  const router = useRouter()
  const { loader, validationCode, registerStore } = useRegister()
  const code = ref('')
  const credentials = ref(registerStore.credentials)
  const encryptedData = ref(registerStore.encryptedData)

  if (!credentials.value) router.push(Routes.login)

  return () => (
    <div class="mt-6 text-left">
      <LabelDecoration class="my-4">
        {{
          input: (input: any) => (
            <InputOTP
              v-model={code.value}
              class={input.class}
              size="lg"
              length={8}
              separator={4}
              prefix=""
            />
          )
        }}
      </LabelDecoration>

      <Info class="mt-4 text-center w-full block">
        {t('login.emailCodePage.info', {
          email: credentials.value?.email
        })}
      </Info>

      <div class="flex flex-row items-center w-full mt-6">
        <BaseSmall>
          <Link to={Routes.login}>{t('login.emailCodePage.button.cancel')}</Link>
        </BaseSmall>

        <div class="grow" />

        <Button
          theme="primary"
          size="lg"
          disabled={!code.value}
          loading={loader.loading}
          onClick={() =>
            validationCode(
              false,
              encryptedData.value,
              code.value,
              credentials.value as RegisterCredentialType
            )
          }
        >
          {t('login.emailCodePage.button.next')}
        </Button>
      </div>
    </div>
  )
})
