<template>
<!--  <ion-modal id="infoModal" :is-open="isLoading">
    <div class="wrapper">
      <div class="text-center mt-4">
        <ion-icon slot="start" :icon="alertCircle" color="medium" size="large"></ion-icon>
      </div>
      <h1 class="text-center m-inline-40">
        {{ loaderModalDetails.title }}
      </h1>
      <ion-grid class="mt-4">
        <ion-row class="ion-justify-content-center">
          <ion-col size="auto">
            <p>{{ loaderModalDetails.content }}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="text-center">
            <ion-spinner name="bubbles"></ion-spinner>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-modal>-->

  <ion-loading
    :is-open="isLoading"
    :message="$t('olkypay.labels.homePage.loading_data')"
    class="custom-loading"
    spinner="circles"
  ></ion-loading>
</template>

<script lang="ts">
import {
  alertController,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRouterOutlet,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  IonModal,
  IonSpinner
} from '@ionic/vue'
import { mapGetters } from 'vuex'
import { checkmarkCircleOutline, alertCircle, handRight } from 'ionicons/icons'
export default {
  name: 'Loader',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonRadio,
    IonRadioGroup,
    IonInput,
    IonImg,
    IonButton,
    IonRouterOutlet,
    alertController,
    IonLoading,
    IonModal,
    IonSpinner
  },
  data() {
    return {
      alertCircle,
      handRight
    }
  },
  computed: {
    ...mapGetters('loader', ['isLoading', 'loaderModalDetails'])
  }
}
</script>

<style scoped></style>
