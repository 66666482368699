<template>
  <ion-page>
    <ion-content :fullscreen="true" style="background-color: #f0f2f5!important;">
      <ion-list-header class="mt-4">
        <ion-skeleton-text :animated="true" style="width: 80px"></ion-skeleton-text>
      </ion-list-header>
      <ion-list-header>
        <ion-skeleton-text :animated="true" style="width: 50%" class="text-end"></ion-skeleton-text>
      </ion-list-header>
      <ion-skeleton-text :animated="true" style="width: 80px" class="text-end mx-3"></ion-skeleton-text>
      <div class="card card-plain bg-gray-100 no-elevation mx-3 mt-3">
        <div class="bg-gray-100">
          <ion-list class="bg-gray-100">
            <div lines="none" class="bg-gray-200 height-178">
            </div>
          </ion-list>
        </div>
      </div>
      <ion-list>
        <ion-list-header>
          <ion-skeleton-text :animated="true" style="width: 80px"></ion-skeleton-text>
        </ion-list-header>
        <ion-item>
          <ion-thumbnail slot="start">
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
          </ion-thumbnail>
          <ion-label>
            <h3>
              <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text :animated="true" style="width: 60%;"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text :animated="true" style="width: 30%;"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-list>
        <ion-list-header>
          <ion-skeleton-text :animated="true" style="width: 80px"></ion-skeleton-text>
        </ion-list-header>
        <ion-item>
          <ion-thumbnail slot="start">
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
          </ion-thumbnail>
          <ion-label>
            <h3>
              <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text :animated="true" style="width: 60%;"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text :animated="true" style="width: 30%;"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list-header>
          <ion-skeleton-text :animated="true" style="width: 80px"></ion-skeleton-text>
        </ion-list-header>
        <ion-item>
          <ion-thumbnail slot="start">
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
          </ion-thumbnail>
          <ion-label>
            <h3>
              <ion-skeleton-text :animated="true" style="width: 80%;"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text :animated="true" style="width: 60%;"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text :animated="true" style="width: 30%;"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-row>
        <ion-col>
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-col>
        <ion-col>
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-col>
        <ion-col>
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-col>
        <ion-col>
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-col>
        <ion-col>
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-col>

      </ion-row>

    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import {
  IonRow, IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonTabBar,
  IonTabs,
  IonContent,
  IonPage,
  IonFooter,
  IonCard,
  IonCardContent,
} from '@ionic/vue';

export default {
  name: "FullSkeleton",
  components: {
    IonRow, IonCol,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonSkeletonText,
    IonThumbnail,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonTabBar,
    IonTabs,
    IonContent,
    IonPage,
    IonFooter,
    IonCard,
    IonCardContent,
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
ion-skeleton-text {
  --border-radius: 9999px;
  --background: rgba(188, 0, 255, 0.065);
  --background-rgb: 188, 0, 255;
}
</style>