<template>
  <v-container fluid>
    <!-- Loading Box -->
    <v-row v-if="internalLoader">
      <v-col class="text-subtitle-1 text-center pt-12 dark:text-white">
        {{ $t('olkypass.companyPage.help.loading_data') }}
      </v-col>
    </v-row>
    <v-row v-if="internalLoader">
      <v-col>
        <v-progress-linear
          color="secondary"
          :indeterminate="internalLoader"
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <!-- Header Box : Search + Btn -->
    <v-row v-if="!internalLoader" class="headerDocuments">
      <v-col cols="12" sm="12" md="6" lg="3">
        <v-btn
          variant="text"
          color="primary"
          height="auto"
          class="text-none textBtn"
          width="auto"
          prepend-icon="mdi-cloud-upload"
          size="medium"
          @click="openDocumentTypeDialog"
        >
          <template #prepend>
            <v-icon class="mx-1 cercle" size="20"></v-icon>
          </template>
          {{ $t('olkypass.accountPage.documentsSection.button.upload') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="9">
        <v-text-field
          v-model="searchTerm"
          hide-details
          single-line
          density="comfortable"
          variant="plain"
          clearable
          :placeholder="$t('olkypass.companyPage.collaborators_section.toolbar.search')"
          class="searchButton"
        >
          <template #append-inner>
            <v-icon class="mdi mdi-magnify searchIcon"></v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- Alert No data -->
    <v-row v-if="!internalLoader && filteredDocuments.length === 0 && !loading">
      <v-col>
        <v-alert
          class="bg-secondary-500 text-secondary-500"
          type="info"
          :text="$t('olkypass.accountPage.documentsSection.help.no_data_found')"
          variant="tonal"
        ></v-alert>
      </v-col>
    </v-row>
    <!-- Content Data -->
    <v-row v-if="!internalLoader && filteredDocuments.length > 0">
      <template v-for="document in filteredDocuments">
        <DocumentItem :document="document"></DocumentItem>
      </template>
    </v-row>
    <!-- Pagination -->
    <v-row v-if="!internalLoader && hasUpdatePermission && documents.length > pagination.perPage">
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <v-pagination
            v-model="page"
            :length="pagination.pageNumbers"
            :total-visible="5"
            rounded="circle"
          ></v-pagination>
        </v-sheet>
      </v-col>
    </v-row>
    <DocumentTypeDialog ref="documentTypeDialog"></DocumentTypeDialog>
  </v-container>
</template>

<script>
import { mapGetters } from '../../store'
import DocumentItem from '../Document/DocumentItem.vue'
import DocumentTypeDialog from '../Document/DocumentTypeDialog.vue'

export default {
  name: 'IdentityDocumentsTab',
  components: {
    DocumentItem,
    DocumentTypeDialog
  },
  data: () => ({
    searchTerm: '',
    page: 1,
    pagination: {
      perPage: 6,
      pageNumbers: 0
    }
  }),
  methods: {
    openDocumentTypeDialog() {
      this.$refs.documentTypeDialog.open()
    }
  },
  computed: {
    ...mapGetters(['documents', 'internalLoader', 'loading', 'hasUpdatePermission']),
    filteredDocuments() {
      const filteredItems = this.documents.filter((document) => {
        if (this.searchTerm === null) {
          return true
        }
        const result =
          document.Number?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          document.Validity?.date?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          document.typePerLanguage?.fr?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          document.typePerLanguage?.en?.toLowerCase().includes(this.searchTerm.toLowerCase())
        return result
      })
      this.pagination.pageNumbers = Math.ceil(filteredItems.length / this.pagination.perPage)

      const startIndex = (this.page - 1) * this.pagination.perPage
      const endIndex = startIndex + this.pagination.perPage
      return filteredItems.slice(startIndex, endIndex)
    }
  }
}
</script>

<style scoped></style>
