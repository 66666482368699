import { Gender } from '../auth/types'

export enum SessionGateway {
  declarative_identity = 'declarative_info',
  kyc_identity = 'kyc_identity',
  cryptocurrency_identity = 'cryptocurrency_identity'
}

export enum SessionExternalScope {
  email = 'email',
  documents = 'documents'
}

export enum Currency {
  kypay = 'kypay',
  euros = 'euros'
}

export type GetSessionByIDResponse = {
  id: string
  scope: SessionExternalScope[]
  gateways: SessionGateway[]
  order: Order
}

export type Order = {
  id: string
  amount: number
  currency: Currency
}

export type DeclarativeIdentity = {
  welcome?: boolean
  person?: DeclarativePerson
  addressBirth?: DeclarativeBirth
  addressResidence?: DeclarativeResidence
  occupation?: DeclarativeOccupation
}

export type DeclarativePerson = {
  gender?: Gender | null
  firstnames?: string | null
  lastnames?: string | null
  nationalityCodes?: string[] | null
}

export type DeclarativeBirth = {
  birth?: Date | null
  cityOfBirth?: string | null
  zip?: string | null
  countryCode?: string | null
}

export type DeclarativeResidence = {
  addressLine?: string | null
  zip?: string | null
  city?: string | null
  countryCode?: string | null
}

export type DeclarativeOccupation = {
  activity?: string | null
  CSPcode?: number | null
}

export enum GatewayVote {
  Inactive = 'Inactive',
  Pending = 'Pending',
  Continue = 'Continue',
  Block = 'Block'
}

export enum FromURL {
  OlkyPayNP,
  OlkyPayLE,
  Wallet,
  None
}
