import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export const RadioCard = ({
  children,
  onClick,
  className = "",
  active
}: {
  children?: ReactNode | ReactNode[]
  active: boolean
  className?: string
  onClick: () => void
}) => {
  return (
    <div
      className={twMerge(
        'w-full border-2 border-slate-50 dark:border-slate-700 rounded-sm flex flex-row p-4 space-x-3 cursor-pointer',
        active && 'border-blue-500', className
      )}
      onClick={onClick}
    >
      <div
        className={twMerge(
          'w-6 h-6 min-w-6 border-2 border-slate-50 dark:border-slate-700 rounded-full flex items-center justify-center',
          active && 'border-blue-500'
        )}
      >
        <div className={twMerge('w-3 h-3 rounded-full', active && 'bg-blue-500')}></div>
      </div>
      <div>{children}</div>
    </div>
  )
}
