import { ButtonIcon } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import Loader from '@/atoms-react/loader/Loader.react'
import { Base } from '@/atoms-react/text/Text.react'
import { Tooltip } from '@/atoms-react/tooltip/Tooptip.react'
import { Themes } from '@/types/theme'
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

type AliasCardProps = {
  isLoading?: boolean
  onClick?: () => void
  onPublicClick?: () => void
  isPublic: boolean
  onDelete?: () => void
  tooltipDesignation?: string
  icon: React.ComponentType<React.ComponentPropsWithoutRef<'svg'>>
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'>

export const AliasCard = ({
  isLoading,
  onClick = () => { },
  isPublic,
  onPublicClick,
  onDelete,
  icon,
  tooltipDesignation = "", 
  children,
  ...props
}: AliasCardProps) => {
  const { t } = useTranslation(['pass'])
  const mainClass = 'w-full h-full rounded-sm py-3 md:py-4'

  const editable = !!onClick
  const editableClass = 'transition-all'
  const buttonIconClassName =
    'flex items-center justify-center border-2 border-slate-50 rounded-sm h-12 w-12 hover:bg-slate-50 cursor-pointer'

  if (isLoading)
    return (
      <Column className={twMerge(mainClass, props.className)} {..._.omit(props, 'className')}>
        <Loader />
      </Column>
    )
  const Icon = icon;

  return (
    <Column
      className={twMerge(mainClass, editable && editableClass, props.className)}
      {..._.omit(props, 'className')}
    >

      <div className="flex items-center justify-between gap-x-4">
        <div className="flex items-center flex-1">
          {Icon && <Icon className="text-black h-6 w-6 mr-4 hidden md:block" />}
          <div className="flex flex-col">
            {children}

            <div
              className={'flex items-center w-fit'}
            >
              <Base className={twMerge('text-sm text-slate-500 mr-1', editable && editableClass)}>
                {t('atoms.card.visibility')} -
              </Base>
              <div onClick={onPublicClick} className={twMerge("flex items-center", onPublicClick ? "cursor-pointer" : "")}>
                <Base
                  className={twMerge(
                    'text-sm w-max underline mr-1',
                    'text-slate-500',
                    editable && editableClass,
                    `${isPublic ? 'text-blue-500' : ''}`
                  )}
                >
                  {isPublic ? t('atoms.card.public') : t('atoms.card.private')}
                </Base>
                <Tooltip />
                <div data-tooltip={isPublic ? t('olkypass.aliasPublicPage.public', {name: tooltipDesignation}) : t('olkypass.aliasPublicPage.private', {name: tooltipDesignation})} data-position="bottom">
                  <InformationCircleIcon
                    className={`h-4 w-4 ${isPublic ? 'text-blue-500' : ''}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          {onDelete && (
            <div className={buttonIconClassName} onClick={onDelete}>
              <ButtonIcon
                size="md"
                theme={Themes.white}
                element={TrashIcon}
                className="text-red-600"
              />
            </div>
          )}
        </div>
      </div>
    </Column >
  )
}
