type Options = {
  rounded?: 'none' | 'sm' | 'md' | 'lg' | 'full'
  focus?: 'focus' | 'focus-within'
  disableErrorUi?: boolean
}

export function defaultInputClassName(options?: Options) {
  const focus = options?.focus ?? 'focus'
  return (
    `rounded-${options?.rounded || 'sm'} ` +
    (`block w-full text-sm border transition-all ${focus}:transition-none ` +
      `ring-blue-100 hover:ring-4 ${focus}:ring-4 ${focus}:ring-blue-200 dark:ring-slate-700 dark:${focus}:ring-slate-600 ` +
      `${focus}:border-blue-500 dark:${focus}:border-slate-400 border-slate-200 dark:border-slate-700 ` +
      `text-slate-900 bg-slate-50 ${focus}:bg-white dark:${focus}:bg-slate-900 dark:bg-slate-950 dark:text-white ` +
      (options?.disableErrorUi
        ? ''
        : `invalid:bg-red-50 invalid:border-red-300 dark:invalid:bg-red-900 dark:invalid:border-red-800`))
  )
}

export function errorInputClassName(options?: Options) {
  return (
    defaultInputClassName(options) + ' bg-red-50 border-red-300 dark:bg-red-900 dark:border-red-800'
  )
}
