import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import { computed, ref, watch } from 'vue'
import { defaultInputClassName, errorInputClassName } from '.'

type InputOTPProps = {
  prefix?: string
  separator?: number
  length?: number
  loading?: boolean
  disabled?: boolean
  onComplete?: (code: string) => void
  hasError?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  class?: string
} & VModelProps<boolean>

let idCounter = 0

export const InputOTP = defineJsxComponent<InputOTPProps>(
  (props, { emit }) => {
    const length = props.length || 8
    const separator = props.separator || length
    const size = props.size || 'xl'

    const id = idCounter++
    const code = ref('')

    const setCode = (_code: string) => {
      code.value = _code.replace(/[^0-9]/g, '')
    }

    watch(code, (newCode) => {
      newCode = newCode.replace(/[^0-9]/g, '').slice(0, length)
      if (newCode.length === length) {
        Array.from(Array(length)).forEach((_: any, i: number) => {
          const ref = document.getElementById('otp-' + id + '-' + i)
          if (ref) ref.blur()
        })
        props.onComplete?.(newCode)
        emit('update:modelValue', newCode)
      } else {
        document.getElementById('otp-' + id + '-' + newCode.length)?.focus()
      }
    })

    const computedInputClassName = computed(() => {
      let className = props.hasError
        ? errorInputClassName({ rounded: 'none' })
        : defaultInputClassName({ rounded: 'none' })

      className += props.disabled ? ' opacity-75 pointer-events-none ' : ''

      if (size === 'xl') className += ' h-12'
      else if (size === 'lg') className += ' h-10'
      else if (size === 'sm') className += ' h-7'
      else className += ' h-9'

      return `${className} ${props.class || ''}`
    })

    return () => (
      <div
        class={
          'w-full text-center flex justify-center ' +
          (props.loading ? ' pointer-events-none animate-pulse ' : '')
        }
        onClick={() => document.getElementById('otp-' + id + '-' + code.value.length)?.focus()}
      >
        <div class="flex -space-x-px rounded-sm" style={{ maxWidth: 300 }}>
          {props.prefix && (
            <div class="font-medium w-auto pr-2 justify-center flex items-center whitespace-nowrap">
              {props.prefix}
            </div>
          )}
          {Array.from(Array(length))
            .map((_, i) => i)
            .map((i) => (
              <>
                {i > 0 && i % separator! === 0 && (
                  <div class="font-medium w-auto px-2 justify-center flex items-center">-</div>
                )}
                <input
                  key={i}
                  id={'otp-' + id + '-' + i}
                  type="number"
                  style={{ fontSize: '1.2rem' }}
                  value={code.value[i] || ''}
                  onKeyDown={(e: any) => {
                    //If backspace, remove last character
                    if (e.key === 'Backspace') {
                      setCode(code.value.slice(0, -1))
                    }
                  }}
                  onInput={(e: any) => {
                    setCode(
                      code.value.slice(0, i) +
                        e.target.value +
                        code.value.slice(i + 1, code.value.length)
                    )
                  }}
                  onFocus={(e: any) => {
                    e.target.select()
                  }}
                  class={
                    computedInputClassName.value +
                    ' hover:z-10 focus:z-10 grow min-w-0 rounded-none shadow-none text-center ' +
                    (i + 1 === length || (i + 1) % separator! === 0 ? 'rounded-r-sm' : '') +
                    (i % separator! === 0 ? 'rounded-l-sm' : '')
                  }
                  placeholder="•"
                />
              </>
            ))}
        </div>
      </div>
    )
  },
  {
    inheritAttrs: false
  }
)
