import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import { useKYC } from '@/features/kyc/hooks/use-kyc'
import { KYCDocumentType } from '@/features/kyc/types'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

type DocumentTypeSelectorProps = {
  onValidated: (documentType: KYCDocumentType) => void
} & React.ComponentPropsWithoutRef<'div'>

export const DocumentTypeSelector = ({ onValidated, ...props }: DocumentTypeSelectorProps) => {
  const { KYCDocumentTypesQuery } = useKYC()
  const { data: KYCDocuments, isLoading: isLoadingKYCDocuments } = KYCDocumentTypesQuery()
  const [selectedDocumentTypeID, setSelectedDocumentTypeID] = useState<number | null>(null)

  function execute() {
    if (!selectedDocumentTypeID) return

    const document = KYCDocuments?.find((doc) => doc.id === selectedDocumentTypeID)
    if (!document) return

    onValidated(document)
  }
  const { t } = useTranslation(['pass'])

  return (
    <Column {...props}>
      <div className="grow flex flex-col justify-between">
        <div className={'mb-1'}>
          {!isLoadingKYCDocuments && (
            <Dropdown
              className={'mb-6'}
              value={selectedDocumentTypeID ? selectedDocumentTypeID.toString() : null}
              onChange={(selected) => setSelectedDocumentTypeID(parseInt(selected.key))}
              items={
                KYCDocuments?.map((document) => ({
                  value: document.type_fr,
                  key: document.id.toString()
                })) ?? []
              }
            />
          )}
        </div>

        <Button
          shortcut="enter"
          className="shrink-0"
          onClick={() => execute()}
          icon={ChevronRightIcon}
          loading={isLoadingKYCDocuments}
          disabled={isLoadingKYCDocuments || !selectedDocumentTypeID}
        >
          {t('flow.id_control.input.continue')}
        </Button>
      </div>
    </Column>
  )
}
