import ApiService from './api.service'
import AuthenticationError from './authentication.error'
import { type AxiosRequestConfig } from 'axios'
import {getJwt, setJwt, setTwoFaAuthenticating} from '@/features/auth/utils'

class ResponseError extends Error {
  errorCode: any
  errorMessage: any

  constructor(errorCode: any, message: string | undefined) {
    super(message)
    this.name = this.constructor.name
    if (message != null) {
      this.message = message
    }
    this.errorCode = errorCode
  }
}

const AuthService = {
  signIn: async function (signInData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: '/_oauth_login_check',
      data: {
        grant_type: 'password',
        username: signInData.username,
        password: signInData.password
      }
    }
    try {
      const response = await ApiService.customRequest(requestData)
      // means 2fa not activated yet
      if (response.data.token) {
        setJwt({
          token: response.data.token,
          refresh: response.data.token
        })
        // ApiService.mount401Interceptor();
        return response.data.token
      } else {
        // return null just to open two fa form
        return null
      }
    } catch (error) {
      this.catchError(error)
    }
  },
  signInViaFingerPrint: async function (signInData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: '/_oauth_login_check',
      data: {
        fingerPrint: signInData.fingerPrint
      }
    }
    try {
      const response = await ApiService.customRequest(requestData)
      // means 2fa not activated yet
      if (response.data.token) {
        setJwt({
          token: response.data.token,
          refresh: response.data.token
        })
        return response.data.token
      } else {
        // return null just to open two fa form
        return null
      }
    } catch (error) {
      this.catchError(error)
    }
  },
  twoFaCodeCheck: async function (twoFaData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: '/_oauth_two_fa_check',
      data: {
        username: twoFaData.username,
        challenge: twoFaData.challenge
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)
      setJwt({
        token: response.data.token,
        refresh: response.data.token
      })
      return response.data.token
    } catch (error) {
      this.catchError(error)
    }
  },
  refreshToken: async function () {
    const refreshToken = getJwt().refresh

    const requestData: AxiosRequestConfig = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      url: '/refresh',
      data: {
        grant_type: 'refresh_token',
        tokenRefresh: refreshToken
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)
      setJwt({
        token: response.data.token,
        refresh: response.data.token
      })

      return response.data.token
    } catch (error: any) {
      throw new AuthenticationError(error.response.status, error.response.data.error_description)
    }
  },
  signOut() {
    setJwt({
      token: null,
      refresh: null
    })
    setTwoFaAuthenticating(false)
    // ApiService.unmount401Interceptor();
  },
  catchError: function (error: any) {
    let status
    let description

    if (error.response === undefined) {
      status = error.message
      description = error.message
    } else {
      status = error.response.status
      description = error.response.data.error_description
    }

    throw new AuthenticationError(status, description)
  },
  verifyIdentityCredentials: async function (userData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: '/_oauth_login_check',
      data: {
        grant_type: 'password',
        username: userData.username,
        password: userData.password
      }
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response.data
    } catch (error) {
      return null
    }
  },
  verifyIdentityTwoFa: async function (twoFaData: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: '/_oauth_two_fa_check',
      data: {
        username: twoFaData.username,
        challenge: twoFaData.challenge,
        password: twoFaData.password,
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)
      return response.data.token
    } catch (error) {
      return null
    }
  },
  verifyIdentityViaCodePin: async function (code_pin: any) {
    const requestData: AxiosRequestConfig = {
      method: 'POST',
      url: '/_oauth_code_pin_check',
      data: {
        code_pin: code_pin
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.message)
    }
  }
}
export default AuthService
