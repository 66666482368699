import { defineJsxComponent } from '@/jsx-utils'
import { Section } from '@/components/layout/Section'
import { useCompany } from '@/features/company/hooks/use-company'
import { useI18n } from 'vue-i18n'
import { Button } from '@/atoms/buttons/Button'
import { Link } from '@/atoms/link/Link2'
import { Info, SectionTitle, SectionTitleSmall, Subtitle } from '@/atoms/text/Text.tsx'
import { computed } from 'vue'
import { InputCopiable } from '@/atoms/inputs/Copiable'

export const KYCBanner = defineJsxComponent((_) => {
  const t = useI18n().t
  const { companyStore } = useCompany()
  const kycState = companyStore.currentCompanyKYC
  const currentCompany = companyStore.currentCompany
  const kycImagePath = computed(() => {
    const level = kycState?.level
    return `/images/olkypass/OlkyPass${level}.svg`
  })

  // @TODO corriger ce border !!! Click sur entreprise déjà crée

  return () => (
    <div>
      <Section class="mb-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <SectionTitleSmall class="mt-6">
              {t('olkypass.accountPage.profileSection.label.verify_identity')}
            </SectionTitleSmall>
            <Button size="lg" theme="outlined" color="#D4D700" class="identifyIcon"></Button>
            {50 > (kycState?.level ?? 0) && (
              <>
                <Link class="lanchVerification">
                  {t('olkypass.accountPage.profileSection.label.launch_verification')}
                </Link>
              </>
            )}
            {50 < (kycState?.level ?? 0) && (
              <>
                <Link class="alreadyVerified">
                  {t('olkypass.accountPage.profileSection.label.already_verification')}
                </Link>
              </>
            )}
            {50 == kycState?.level && (
              <>
                <Link class="alreadyVerified">
                  {t('olkypass.accountPage.profileSection.label.encours_verification')}
                </Link>
              </>
            )}
          </div>
          <div>
            <img className="mx-auto progressIcon" src={kycImagePath.value} alt="Progress Icon" />
          </div>
          <div>
            <SectionTitle> {currentCompany?.legal_name ?? 'Error'}</SectionTitle>
            <Subtitle class="block mt-3">
              {t('olkypass.accountPage.profileSection.label.kyc_identifier')}
            </Subtitle>
            <Info class="block mt-2">
              <InputCopiable value={currentCompany?.uuid ?? 'Error'}></InputCopiable>
            </Info>
          </div>
        </div>
      </Section>
    </div>
  )
})
