import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { ModalKYCIdentity } from '@/molecules/modal-kyc-identity/index.react'
import { useEffect, useState } from 'react'

export const ForcedKYCIdentityModal = () => {
    const { status, algoregKYCStatus } = useKYCContext()
    const [open, setOpen] = useState<boolean>();
    useEffect(() => {
        if (open === undefined && status !== KYCStatusType.LOADING ) {
            setOpen(status === KYCStatusType.DECLARATIVE && algoregKYCStatus === null)
        }
    }, [status, algoregKYCStatus])
    return <ModalKYCIdentity open={open} onClose={() => setOpen(false)} />

}
