import { Subtitle, Title } from '@/atoms/text/Text.tsx'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'
import { FormValidationCodeView } from './components/FormValidationCodeView'
import { LayoutView } from './components/LayoutView'

export const ValidationCodeView = defineVueComponent(() => {
  const { t } = useI18n()
  return () => (
    <LayoutView>
      <Title>{t('login.emailCodePage.label.header_card')}</Title>
      <Subtitle class="mt-3">{t('login.emailCodePage.label.code_mail')}</Subtitle>
      <FormValidationCodeView />
    </LayoutView>
  )
})
