'use client'
import { A } from '@/atoms-react/a/A.react'
import { Button } from '@/atoms-react/button/Button.react'
import { KypayIcon } from '@/atoms-react/crypto-icon/KypayIcon.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import {
  addKypayToWallet,
  selectPolygonNetwork,
  isMetamaskActive
} from '@/features/kypay/functions'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { CoinLayout } from '@/molecules/coin-layout/index.react'
import { KYCAlert } from '@/molecules/kyc-alert/index.react'
import { Themes } from '@/types/theme'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { BalanceCardKypay } from '../balance-card/balance-card-kypay.react'
import { TransactionsList } from '../transaction-list/transaction-list.react'
import { CardStack } from '../card-stack/card-stack.react'

export const KypayRoot = () => {
  const { kycSessionStatus, status } = useKYCContext()
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')
  const { kypayQuery, userWalletQuery } = useKypay()

  const { data: kypay, isLoading: isKypayLoading } = kypayQuery()
  const { isLoading: isWalletLoading } = userWalletQuery()

  const transactions = (kypay?.transaction ?? []).filter(
    (trans) => (trans.Counterparty ?? '').includes(search) || trans.formatedAmount.includes(search)
  )

  const {
    user: { isLoading: isUserLoading }
  } = useAuth()

  const allowedToKypay =
    kycSessionStatus?.level === 100 &&
    kycSessionStatus?.ValidForPayout &&
    kycSessionStatus?.ValidForPayIn

  return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle>{t('kypay.label.main_title')}</LayoutTitle>
            {isMetamaskActive() && (
              <Button
                size="md"
                theme={Themes.white}
                onClick={async () => {
                  await selectPolygonNetwork()
                  await addKypayToWallet()
                }}
                icon={KypayIcon}
                iconPosition="before"
                className="hidden md:block md:overflow-visible md:w-max mr-4"
              >
                {t('kypay.button.add_kypay_token')}
              </Button>
            )}
            <A noColor target="_blank" href="https://www.olky.eu/fr/universe/olkypay/kypay/">
              <Button
                size="md"
                theme={Themes.white}
                onClick={() => null}
                icon={QuestionMarkCircleIcon}
                iconPosition="before"
                className="hidden md:block md:overflow-visible md:w-max"
              >
                {t('kypay.button.need_help')}
              </Button>
            </A>
          </>
        )}
        sectionTitle={t('kypay.label.section_title')}
        header={() =>
          !allowedToKypay && !isWalletLoading && !isKypayLoading && !isUserLoading && <KYCAlert status={status} />
        }
        body={() => (
          <>
            <BalanceCardKypay allowedToKypay={allowedToKypay} balanceType="euros" className="max-w-lg" withButton={true} />
            <BalanceCardKypay allowedToKypay={allowedToKypay} balanceType="onChain" className="max-w-lg" withButton={true} />
            <CardStackKypay />
          </>
        )}
        transactions={{
          title: t('kypay.section_title_transactions'),
          body: () => (
            <TransactionsList
              transactions={
                transactions.map((trans) => ({
                  title: trans.Counterparty,
                  amount: trans.NumberInt / 100,
                  type: trans.Type,
                  date: trans.Date
                })) ?? []
              }
              search={search}
            />
          )
        }}
      />
    </>
  )
}

export function CardStackKypay() {
  const { t } = useTranslation(['pass'])
  const CARDS = [
    {
      id: 0,
      title: t('olkypass.KypayPage.cardsExplanationsKypay.title.1'),
      content: t('olkypass.KypayPage.cardsExplanationsKypay.content.1')
    },
    {
      id: 1,
      title: t('olkypass.KypayPage.cardsExplanationsKypay.title.2'),
      content: t('olkypass.KypayPage.cardsExplanationsKypay.content.2')
    },
    {
      id: 2,
      title: t('olkypass.KypayPage.cardsExplanationsKypay.title.3'),
      content: t('olkypass.KypayPage.cardsExplanationsKypay.content.3')
    }
  ]

  return (
    <div className="flex items-center w-full max-w-lg">
      <CardStack items={CARDS} />
    </div>
  )
}
