import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import { CheckIcon, ClipboardIcon } from '@heroicons/vue/24/solid'
import { ref } from 'vue'
import { useToast } from 'vue-toast-notification'
import { Input } from './Input'
import IconDecoration from './decorations/IconDecoration.vue'

type InputCopiableProps = {
  value: string
} & Partial<HTMLInputElement> &
  VModelProps<string>

let copiedTimeout: any = null

export const InputCopiable = defineJsxComponent<InputCopiableProps>(
  ({ value, ...props }) => {
    const toast = useToast()
    const inputRef = ref<HTMLInputElement>()
    const copied = ref(false)

    const copyToClipboard = () => {
      copied.value = false
      const textField = document.createElement('textarea')
      textField.innerText = value
      document.body.appendChild(textField)
      textField.select()
      if (!navigator.clipboard) {
        document.execCommand('copy')
      } else {
        navigator.clipboard.writeText(textField.value)
      }
      textField.remove()
      copied.value = true
      clearTimeout(copiedTimeout)

      //Focus all text on input
      inputRef.value!.focus()
      inputRef.value!.select()

      toast.success('Copied to clipboard')
      copiedTimeout = setTimeout(() => {
        copied.value = true
        setTimeout(() => (copied.value = false), 1000)
      }, 500)
    }

    return () => (
      <IconDecoration
        onClick={copyToClipboard}
        suffix={(p: any) => (
          <>
            {!!copied.value && <CheckIcon {...p} />}
            {!copied.value && <ClipboardIcon {...p} />}
          </>
        )}
      >
        {(p: any) => (
          <Input
            onClick={copyToClipboard}
            inputRef={inputRef}
            {...p}
            {...props}
            value={value}
            readOnly
          />
        )}
      </IconDecoration>
    )
  },
  {
    inheritAttrs: false
  }
)
