import { fetchServer } from "@/features/utils/fetch"
import { EmailCodeResponse } from "../../types"

export class EmailApiClient {
    static async sendEmailCode(email: string, language: string) {
        const response = await fetchServer('/aliasMail/verify_mail', {
            method: 'POST',
            reloadOn401: false,
            body: {
                email,
                language
            }
        })
        if (response.status !== 200) {
            throw new Error("Code cannot be send")
        }
        const res = await response.json()
        return res as EmailCodeResponse
    }

    /**
     *
     * @param encryptedData
     * @param code
     */
    static async createEmail(encryptedData: string, code: string, UUID: string, email: string) {
        const response = await fetchServer('/aliasMail/create_new', {
            method: 'POST',
            reloadOn401: false,
            body: {
                encryptedData,
                code,
                email,
                UUID
            }
        })
        if (response.status !== 200) {
            throw new Error("Cannot create new email.")
        }
        return { success: true, message: "" }
    }
}